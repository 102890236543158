import { Heading, Stack, Table, FormControl, FormLabel, HStack, Button, Switch, Box, TableContainer, Tbody, Td, Th, Thead, Tr, Link } from '@chakra-ui/react'
import { useEffect, useState, ChangeEvent } from 'react'
import { GetUsersParams, User } from '~/types/users'
import { getUsers as getUsersApi } from '~/api/users'
import dateFormat from 'dateformat'
import { Path } from '~/types/router'
import { Link as ReactLink } from 'react-router-dom'
import Loader from '~/components/Loader'

export default function Users() {
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const [isDisplayStaff, setIsDisplayStaff] = useState(true)
  const [isDisplayBanned, setIsDisplayBanned] = useState(false)

  const getUsers = async (params: GetUsersParams) => {
    setIsLoading(true)
    const { results } = await getUsersApi(params)
    setUsers(results)
    setIsLoading(false)
  }

  useEffect(() => {
    getUsers({ groups: isDisplayStaff ? '1' : undefined, is_banned: isDisplayBanned ? undefined : false })
  }, [isDisplayBanned, isDisplayStaff])

  return (
    <Stack spacing={6}>
      <Heading as="h2" size="lg">
        Пользователи
      </Heading>
      <HStack justify="space-between">
        <HStack gap={3}>
          <FormControl display="flex" alignItems="center" width="auto">
            <FormLabel htmlFor="display-staff" mb="0">
              Сотрудники
            </FormLabel>
            <Switch id="display-staff" isChecked={isDisplayStaff} onChange={(event: ChangeEvent<HTMLInputElement>) => setIsDisplayStaff(event.target.checked)} />
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="display-banned" mb="0">
              Отображать заблокированных
            </FormLabel>
            <Switch id="display-banned" isChecked={isDisplayBanned} onChange={(event: ChangeEvent<HTMLInputElement>) => setIsDisplayBanned(event.target.checked)} />
          </FormControl>
        </HStack>

        <Button colorScheme="blue">
          <ReactLink to={Path.CREATE_USER}>Создать</ReactLink>
        </Button>
      </HStack>
      <Box rounded="full" position="relative" minH="70vh">
        {isLoading && <Loader />}
        <TableContainer>
          <Table variant="simple" colorScheme="blackAlpha">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Имя</Th>
                <Th>Фамилия</Th>
                <Th>TG-ID</Th>
                <Th>TG-Username</Th>
                <Th>Дата присоединения</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.id}>
                  <Td>
                    <Link as={ReactLink} color="blue.400" fontWeight="bold" textDecoration="underline" to={`${user.id}`} _hover={{ color: 'blue.700' }}>
                      {user.id}
                    </Link>
                  </Td>
                  <Td>{user.name}</Td>
                  <Td>{user.last_name}</Td>
                  <Td>{user.tg_id ?? '-'}</Td>
                  <Td>{user.tg_username ?? '-'}</Td>
                  <Td>{dateFormat(user.date_joined, 'mmmm dd, yyyy, h:MM TT')}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  )
}

import { GetOrgsResponse, GetOrgsParams, ShortOrg, Org } from '~/types/organizations'
import { get, post, patch, deleteApi } from './base'

export const getOrgs = (params: GetOrgsParams) => get<GetOrgsResponse>(`organizations/`, { params })
export const getOrg = (orgId: number) => get<Org>(`organizations/${orgId}`)
export const createOrg = (org: ShortOrg) => post<Org>(`organizations/`, org)
export const updateOrg = (orgId: number, org: Org) => patch<Org>(`organizations/${orgId}`, org)
export const deleteOrg = (orgId: number) => deleteApi(`organizations/${orgId}`)

export const getInfoByIIN = async (query: string) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${process.env.REACT_APP_DADATA_TOKEN}`
    },
    mode: 'cors' as RequestMode,
    body: JSON.stringify({ query })
  }

  const req = await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party', options)
  const res = await req.json()
  return res.suggestions
}

enum Path {
  HOME = '/',
  LOGIN = '/login',
  USERS = '/users',
  CREATE_USER = '/users/create',
  USER = '/users/:id',
  DOCS = '/documents/:category',
  DOC = '/document/:id',
  ORGANIZATION = '/organization/:id',
  REF_ORG = '/references/organizations',
  REF_CATEGORIES = '/references/categories',
  REF_UNITS = '/references/units',
  REF_GROUPS = '/references/groups',
  REF_CATEGORY_LIMITS = '/references/category-limits/:id',
  REP_MECHANICS = '/reports/mechanics',
  REP_OBJECTS = '/reports/objects',
  RECORD_SET = '/reports/record-set',
  NOT_FOUND = '*'
}

export { Path }

import { GetLimitsParams, GetLimitsResponse, Limit, LimitItemToSend, ShortLimit } from '~/types/limits'
import { get, post, patch, deleteApi } from './base'

export const getLimits = (params: GetLimitsParams) => get<GetLimitsResponse>(`records-limits/`, { params })
export const getLimitsItem = (id: number) => get<Limit>(`records-limits/${id}`)
export const createLimit = (body: ShortLimit) => post<{ id: number }>(`records-limits/`, body)
export const updateLimit = (id: number, body: ShortLimit) => patch<ShortLimit>(`records-limits/${id}/`, body)
export const deleteLimit = (id: number) => deleteApi(`records-limits/${id}/`)

export const createLimitItem = (body: LimitItemToSend) => post<{ id: number }>(`records-limits-items/`, body)
export const updateLimitItem = (id: number, body: LimitItemToSend) => patch<ShortLimit>(`records-limits-items/${id}/`, body)
export const deleteLimitItems = (ids: number[]) => post(`records-limits-items/bulk-destroy/`, { ids })
export const deleteLimitItem = (id: number) => deleteApi(`records-limits-items/${id}/`)

// export const getRecordsGroups = (params: GetRecordsGroupsParams) => get<GetRecordsGroupsResponse>(`records-groups/`, { params: { ...params } })
// export const createRecordGroup = (body: ShortRecordGroup) => post<ShortRecordGroup>(`records-groups/`, body)
// export const updateRecordGroup = (id: number, body: ShortRecordGroup) => patch<ShortRecordGroup>(`records-groups/${id}`, body)
// export const deleteRecordGroup = (id: number) => deleteApi(`records-groups/${id}`)

// export const getRecordsUnits = (params: { limit?: number; offset?: number }) => get<GetRecordsUnitsResponse>(`records-units/`, { params })
// export const createRecordUnit = (body: ShortRecordUnit) => post<ShortRecordUnit>(`records-units/`, body)
// export const updateRecordUnit = (id: number, body: ShortRecordUnit) => patch<ShortRecordUnit>(`records-units/${id}`, body)
// export const deleteRecordUnit = (id: number) => deleteApi(`records-units/${id}`)

// export const createRecord = (record: NewRecord) => post<NewRecord>(`records/`, record)
// export const deleteRecord = (recordId: number) => deleteApi<NewRecord>(`records/${recordId}`)
// export const updateRecord = (recordId: number, body: NewRecord) => patch<NewRecord>(`records/${recordId}`, body)

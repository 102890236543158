import { createContext } from 'react'
import { DocsCategoryResponse } from '~/types/docs'

export const { Provider, Consumer } = createContext<Record<number, any>>({})

export const CategoriesContext = createContext<{ categories: DocsCategoryResponse[]; setCategories: any }>({
  categories: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCategories: () => {}
})

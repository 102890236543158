import { useParams } from 'react-router-dom'
import { getOrg } from '~/api/organizations'
import { useEffect, useState } from 'react'
import { Box, Heading, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Button } from '@chakra-ui/react'
import Loader from '~/components/Loader'
import { Org } from '~/types/organizations'
import OrgEdit from '~/components/organization/OrgEdit'
import PriceRecords from '~/components/organization/PriceRecords'
import PriceRecordsArchive from '~/components/organization/PriceRecordsArchive'

export default function Organization() {
  const [isLoading, setIsLoading] = useState(false)
  const [org, setOrg] = useState<Org | null>(null)
  const [activeTab, setActiveTab] = useState(0)
  const id = +useParams().id!

  const getOrganization = async () => {
    setIsLoading(true)
    const res = await getOrg(id)
    setOrg(res)
    setIsLoading(false)
  }

  const onChangeTab = (idx: number) => {
    setActiveTab(idx)
  }

  useEffect(() => {
    getOrganization()
  }, [])

  if (isLoading || !org) {
    return <Loader />
  }

  return (
    <Stack spacing={6}>
      <Heading as="h2" size="lg">
        {org?.name}
      </Heading>

      <Box>
        <Tabs colorScheme="red" isLazy lazyBehavior="keepMounted" onChange={onChangeTab}>
          <TabList>
            <Tab>Информация</Tab>
            <Tab>Прайс-лист</Tab>
            <Tab>Архив прайса</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <OrgEdit orgData={org} />
            </TabPanel>
            <TabPanel>
              <PriceRecords id={org.id} activeTab={activeTab} />
            </TabPanel>
            <TabPanel>
              <PriceRecordsArchive id={org.id} activeTab={activeTab} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Stack>
  )
}

import { Alert, AlertIcon, Box, Button, FormControl, FormLabel, Heading, Input, Stack, useColorModeValue } from '@chakra-ui/react'
import { useState, ChangeEvent, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { PasswordField } from '~/components/auth/PasswordField'
import { AuthError, UserDataTelegram } from '~/types/auth'
import { getTgToken, signIn as signInApi } from '~/api/auth'
import { localStorageSetValue } from '~/utils/localStorage'

interface StringByString {
  [key: string]: string
}

type UserDataParams = UserDataTelegram & StringByString

export default function Login() {
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(false)
  const [tgId, setTgid] = useState('')
  const [password, setPassword] = useState('')
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(false)
  const [error, setError] = useState('')

  const telegramButton = useRef<HTMLDivElement | null>(null)

  const signIn = async (authData = '') => {
    setLoading(true)
    try {
      const { access, refresh } = authData ? await getTgToken({ data: authData, source: 'widget' }) : await signInApi({ tgid: +tgId, password })

      localStorageSetValue('token', access)
      localStorageSetValue('refresh', refresh)
      navigate('/')
    } catch (e) {
      const typedError = e as AuthError
      const authError = typedError.response.data.non_field_errors[0]
      setError(authError)
    } finally {
      setLoading(false)
    }
  }

  const onTelegramAuth = (data: UserDataTelegram) => {
    const dataString = new URLSearchParams(data as UserDataParams).toString()
    signIn(dataString)
  }

  useEffect(() => {
    if (!telegramButton.current) return

    const botUsername = process.env.REACT_APP_BOT_USERNAME || ''
    const script = document.createElement('script')

    script.src = 'https://telegram.org/js/telegram-widget.js?22'
    script.async = true

    script.setAttribute('data-telegram-login', botUsername)
    script.setAttribute('data-size', 'large')
    script.setAttribute('data-userpic', 'false')
    script.setAttribute('data-request-access', 'write')
    script.setAttribute('data-onauth', 'window.onTelegramAuth(user)')
    window.onTelegramAuth = onTelegramAuth

    telegramButton.current.appendChild(script)

    return () => {
      if (!telegramButton.current) return

      telegramButton.current.removeChild(script)
    }
  }, [])

  return (
    <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
      {isLoginFormVisible && (
        <Box>
          <Stack align="center" mb={4}>
            <Heading fontSize="4xl">Войти в аккаунт</Heading>
          </Stack>
          <Box rounded="lg" bg="white" boxShadow="lg" p={8}>
            <Stack spacing={4}>
              {error && (
                <Alert status="error">
                  <AlertIcon />
                  {error}
                </Alert>
              )}
              <FormControl isRequired id="email">
                <FormLabel>Логин</FormLabel>
                <Input value={tgId} onInput={(e: ChangeEvent<HTMLInputElement>) => setTgid(e.target.value)} />
              </FormControl>
              <PasswordField initialValue={password} setInputPassword={setPassword} required />

              <Stack spacing={10}>
                <Button
                  isLoading={isLoading}
                  bg="blue.400"
                  color="white"
                  _hover={{
                    bg: 'blue.500'
                  }}
                  onClick={() => signIn()}
                >
                  Войти
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      )}
      <Stack align="center" mb={4}>
        <div ref={telegramButton} />

        {!isLoginFormVisible && (
          <Button variant="link" color="gray.800" fontWeight="bold" textDecoration="underline" _hover={{ color: 'blue.700' }} onClick={() => setIsLoginFormVisible(true)}>
            Войти с помощью логина и пароля
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

import {
  useDisclosure,
  IconButton,
  Stack,
  Heading,
  Box,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Link,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Flex,
  Button,
  Checkbox,
  FormControl
} from '@chakra-ui/react'
import dateFormat from 'dateformat'
import { useEffect, useMemo, useState } from 'react'
import { FiFilter, FiEye, FiEyeOff, FiEdit } from 'react-icons/fi'
import { FaUndo } from 'react-icons/fa'
import { FcGenericSortingDesc, FcGenericSortingAsc, FcNumericalSorting12, FcNumericalSorting21 } from 'react-icons/fc'
import { Link as ReactLink, useParams, useSearchParams } from 'react-router-dom'
import Loader from '~/components/Loader'
import { DocsCategoryResponse, Doc, GetDocsParams, FilterValues, FilterRadioValue, SortParam, ProofOfWorkFilterValues } from '~/types/docs'
import { getDocsCategoryInfo, getDocs as getDocsApi, getDocumentsCountTotal } from '~/api/docs'
import Pagination from '~/components/Pagination'
import FiltersSidebar from '~/components/doc/FiltersSidebar'
import { StringSignature } from '~/types/common'
import { localStorageGetValue, localStorageSetValue, PaginationObject } from '~/utils/localStorage'
import { useFiltersTags } from '~/hooks/useFiltersTags'
import { PRODUCT_DOCUMENTS_CATEGORY_SLUG, PROOF_OF_WORK_CATEGORY_SLUG, TRANSFER_CATEGORY_NAME, TRANSPORT_LISTS_CATEGORY_SLUG } from '~/utils/constants'
import ProofOfWorkFiltersSiedbar from '~/components/doc/ProofOfWorkFiltersSidebar'
import { addOneDay, getNumberWithSpaces } from '~/utils/helpers'
import { ActionOption, useExtendedDocs } from '~/hooks/docs/useExtendedDocs'
import { ChakraStylesConfig, Select } from 'chakra-react-select'
import MergeDocsModal from '~/components/doc/MergeDocsModal'
import DeleteDocsModal from '~/components/doc/DeleteDocsModal'
import UpdateDocsModal from '~/components/doc/UpdateDocsModal'
import DocsTotalSum from '~/components/docs/DocsTotalSum'
import DocsRecordsPreview from '~/components/docs/DocsRecordsPreview'
import { LimitsFilters } from '~/types/limits'

const selectStyles: ChakraStylesConfig<ActionOption> = {
  placeholder: (provided) => ({
    ...provided,
    color: 'gray.900'
  }),
  container: (provided) => ({
    ...provided,
    backgroundColor: 'gray.100',
    border: '1px solid transparent',
    borderRadius: '6px'
  })
}

export const DEFAULT_DOC_FILTER_VALUES: FilterValues = {
  date_gte: null,
  date_lte: null,
  created_at_gte: null,
  created_at_lte: null,
  date_diff_min: null,
  client: null,
  supplier: null,
  author: null,
  is_original: 'not_defined',
  is_closing: 'not_defined',
  is_processed: 'not_defined',
  o: null
}

export const DEFAULT_DOC_PROOF_OF_WORK_FILTER_VALUES: ProofOfWorkFilterValues = {
  date_gte: null,
  date_lte: null,
  created_at: null,
  executor: null,
  record_category: null,
  author: null,
  is_processed: 'not_defined',
  o: null
}

type FiltersToSendType = StringSignature<number | number[] | boolean | string | FilterRadioValue | null>

export default function Docs() {
  const { isOpen: isFilterOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [categoryInfo, setCategoryInfo] = useState<DocsCategoryResponse>()
  const [totalSum, setTotalSum] = useState('')

  const isProofOfWorkCategory = useMemo(() => {
    return categoryInfo?.slug === PROOF_OF_WORK_CATEGORY_SLUG
  }, [categoryInfo])

  const [docs, setDocs] = useState<Doc[]>([])
  const [docsCount, setDocsCount] = useState(0)

  const [filterValues, setFilterValues] = useState<FilterValues>(DEFAULT_DOC_FILTER_VALUES)
  const [proofOfWorkFilterValues, setProofOfWorkFilterValues] = useState<ProofOfWorkFilterValues>(DEFAULT_DOC_PROOF_OF_WORK_FILTER_VALUES)

  const [filtersToSend, setFiltersToSend] = useState<FiltersToSendType>({} as FiltersToSendType)
  const [proofOfWorkFiltersToSend, setProofOfWorkFiltersToSend] = useState<FiltersToSendType>({} as FiltersToSendType)

  const [isLocalStorageChecked, setIsLocalStorageChecked] = useState(false)
  const [isLocalStorageCheckedProof, setIsLocalStorageCheckedProof] = useState(false)

  const category = useParams().category!
  const [searchParams] = useSearchParams()
  const isAllDocuments = !searchParams.get('type')
  const reportId = searchParams.get('reportId')

  const isExtendedView = useMemo(() => {
    return !isAllDocuments || categoryInfo?.slug === PRODUCT_DOCUMENTS_CATEGORY_SLUG || categoryInfo?.slug === TRANSPORT_LISTS_CATEGORY_SLUG
  }, [categoryInfo])

  const [getTagLabel, removeFilter, resetFilters, tags] = useFiltersTags<FilterValues, FiltersToSendType>(filterValues, filtersToSend, setFilterValues)
  const [getTagLabelProof, removeFilterProof, resetFiltersProof, tagsProof] = useFiltersTags<ProofOfWorkFilterValues, FiltersToSendType>(
    proofOfWorkFilterValues,
    proofOfWorkFiltersToSend,
    setProofOfWorkFilterValues
  )

  const getCategoryInfo = async () => {
    setIsLoading(true)
    const info = await getDocsCategoryInfo(+category)
    setCategoryInfo(info)
    setIsLoading(false)
  }

  const getDocs = async (params: GetDocsParams = {}) => {
    const addParams: GetDocsParams = isAllDocuments ? { category } : { id: category }

    if (reportId) {
      addParams.report_id = reportId
      addParams.id = undefined
    }

    const offsetFromStorage = localStorageGetValue<PaginationObject>('pagination') || null

    if (!('offset' in params) && offsetFromStorage && offsetFromStorage[category]) {
      addParams.offset = offsetFromStorage[category].offset
    }

    const filters = isProofOfWorkCategory ? proofOfWorkFiltersToSend : filtersToSend

    const { results, count } = await getDocsApi({ ...params, ...addParams, ...filters, limit: 20 })
    setDocs(results)
    setDocsCount(count)

    setTotalPages(Math.ceil(count / 20))

    if (Object.keys(filters).length > 0) {
      const additional = isAllDocuments ? {} : { id: category }
      const { total_price: totalPrice } = await getDocumentsCountTotal({ ...additional, ...filters, category })
      setTotalSum(getNumberWithSpaces(totalPrice || '0', true).toString())
    } else {
      setTotalSum('')
    }
  }

  const handlePageClick = (offset: number) => {
    if (isLocalStorageChecked || isLocalStorageCheckedProof) {
      getDocs({ offset })
    }
  }

  const receivers = (doc: Doc) => {
    if (doc.records && doc.records.length > 0) {
      return [...new Set(doc.records.map((record) => record.client.name))].slice(0, 3).join(', ')
    }

    return '-'
  }

  const getValueRadio = (value: FilterRadioValue) => {
    if (value === 'not_defined') return null
    if (value === 'received') return true
    return false
  }

  const getValueData = (value: Date | null) => {
    if (!value) return null
    return dateFormat(value, 'yyyy-mm-dd')
  }

  const onSort = (key: 'date' | 'id') => {
    const filters = isProofOfWorkCategory ? proofOfWorkFilterValues : filterValues
    const param: SortParam = filters.o?.includes(key) ? `-${key}` : `${key}`

    if (isProofOfWorkCategory) {
      setProofOfWorkFilterValues({ ...proofOfWorkFilterValues, o: [param] })
    } else {
      setFilterValues({ ...filterValues, o: [param] })
    }
  }

  useEffect(() => {
    if (isAllDocuments) {
      getCategoryInfo()
    }

    if (isLocalStorageChecked || isLocalStorageCheckedProof) {
      getDocs()
    }
  }, [category, isProofOfWorkCategory])

  useEffect(() => {
    if (isLocalStorageChecked) {
      localStorageSetValue('filters', filterValues)
      getDocs()
    }
  }, [filtersToSend, isProofOfWorkCategory])

  useEffect(() => {
    if (isLocalStorageCheckedProof) {
      localStorageSetValue('proof-of-work-filters', proofOfWorkFilterValues)
      getDocs()
    }
  }, [proofOfWorkFiltersToSend, isProofOfWorkCategory])

  useEffect(() => {
    if (!filterValues || !isLocalStorageChecked) return

    const transformedFilters: FiltersToSendType = {
      date_gte: getValueData(filterValues.date_gte),
      date_lte: getValueData(filterValues.date_lte),
      created_at_gte: getValueData(filterValues.created_at_gte),
      created_at_lte: getValueData(addOneDay(filterValues.created_at_lte)),
      date_diff_min: filterValues.date_diff_min,
      client: filterValues.client?.id || null,
      supplier: filterValues.supplier?.id || null,
      author: filterValues.author?.map((user) => user.id).join(',') || null,
      is_original: getValueRadio(filterValues.is_original),
      is_closing: getValueRadio(filterValues.is_closing),
      is_processed: getValueRadio(filterValues.is_processed),
      o: filterValues.o?.join(', ') || null
    }

    Object.keys(transformedFilters).forEach((key) => {
      if (transformedFilters[key] === null) {
        delete transformedFilters[key]
      }
    })

    setFiltersToSend(transformedFilters)
  }, [filterValues, isLocalStorageChecked])

  useEffect(() => {
    if (!proofOfWorkFilterValues || !isLocalStorageCheckedProof) return

    const transformedFilters: FiltersToSendType = {
      date_gte: getValueData(proofOfWorkFilterValues.date_gte),
      date_lte: getValueData(proofOfWorkFilterValues.date_lte),
      created_at: getValueData(proofOfWorkFilterValues.created_at),
      author: proofOfWorkFilterValues.author?.map((user) => user.id).join(',') || null,
      executor: proofOfWorkFilterValues.executor?.id || null,
      record_category: proofOfWorkFilterValues.record_category?.id || null,
      is_processed: getValueRadio(proofOfWorkFilterValues.is_processed),
      o: proofOfWorkFilterValues.o?.join(', ') || null
    }

    Object.keys(transformedFilters).forEach((key) => {
      if (transformedFilters[key] === null) {
        delete transformedFilters[key]
      }
    })

    setProofOfWorkFiltersToSend(transformedFilters)
  }, [proofOfWorkFilterValues, isLocalStorageCheckedProof])

  useEffect(() => {
    if (isProofOfWorkCategory) {
      const filtersFromStorage = localStorageGetValue<ProofOfWorkFilterValues>('proof-of-work-filters')
      if (filtersFromStorage) {
        setProofOfWorkFilterValues(filtersFromStorage)
        setIsLocalStorageCheckedProof(true)
      } else {
        setIsLocalStorageCheckedProof(true)
        setProofOfWorkFilterValues(DEFAULT_DOC_PROOF_OF_WORK_FILTER_VALUES)
      }
    } else {
      const filtersFromStorage = localStorageGetValue<FilterValues>('filters')
      if (filtersFromStorage) {
        setFilterValues(filtersFromStorage)
        setIsLocalStorageChecked(true)
      } else {
        setIsLocalStorageChecked(true)
        setFilterValues(DEFAULT_DOC_FILTER_VALUES)
      }
    }
  }, [isProofOfWorkCategory])

  const {
    selectExtendedDoc,
    selectedExtendedDocsIds,
    selectAllExtendedDoc,
    ACTIONS,
    isActionOptionDisabled,
    toggleRecordsPreview,
    onActionOptionClick,
    openedRecordsPreview,
    docRecords,
    isMergeDocsModalOpen,
    onMergeDocsModalClose,
    isDeleteDocsModalOpen,
    onDeleteDocsModalClose,
    isUpdateDocsModalOpen,
    onUpdateDocsModalClose
  } = useExtendedDocs(docs)

  const totalSumSelectedDocs = useMemo(() => {
    const selectedDocs = docs.filter((d) => selectedExtendedDocsIds.includes(d.id))
    const sum = selectedDocs.reduce((acc, cur) => acc + cur.total_price, 0)
    return getNumberWithSpaces(sum || '0', true).toString()
  }, [docs, selectedExtendedDocsIds])

  const onCloseActionModal = async () => {
    setIsLoading(true)
    onMergeDocsModalClose()
    onDeleteDocsModalClose()
    onUpdateDocsModalClose()
    selectAllExtendedDoc(false)
    await getDocs()
    setIsLoading(false)
  }

  return (
    <Stack spacing={6}>
      <HStack justifyContent="space-between">
        <Heading as="h2" size="lg">
          Документы {isAllDocuments && categoryInfo ? `- ${categoryInfo?.name}` : null}
        </Heading>
        <HStack gap={2}>
          <Button onClick={() => onSort('date')} aria-label="Sort by date">
            По дате
            <Box ml={3}>{filterValues.o?.includes('date') ? <FcGenericSortingAsc /> : <FcGenericSortingDesc />}</Box>
          </Button>
          <Button onClick={() => onSort('id')} aria-label="Sort by id">
            По номеру
            <Box ml={3}>{filterValues.o?.includes('id') ? <FcNumericalSorting12 /> : <FcNumericalSorting21 />}</Box>
          </Button>
          {isExtendedView && (
            <Box sx={{ width: '200px' }}>
              <Select
                placeholder="Действия"
                onChange={(action) => onActionOptionClick(action as ActionOption)}
                isDisabled={selectedExtendedDocsIds.length === 0}
                options={ACTIONS}
                isOptionDisabled={isActionOptionDisabled}
                chakraStyles={selectStyles}
              />
            </Box>
          )}

          <IconButton onClick={onOpen} aria-label="Open filters" icon={<FiFilter />} />
        </HStack>
      </HStack>
      <Flex wrap="wrap" gap="2">
        {isProofOfWorkCategory ? (
          <>
            {tagsProof.map((filterKey) => {
              return (
                <Tag size="sm" key={filterKey} borderRadius="full" variant="solid" colorScheme="blue">
                  <TagLabel>{getTagLabelProof(filterKey)}</TagLabel>
                  <TagCloseButton onClick={() => removeFilterProof(filterKey)} />
                </Tag>
              )
            })}
            {tagsProof.length ? (
              <Button onClick={() => resetFiltersProof(DEFAULT_DOC_PROOF_OF_WORK_FILTER_VALUES)} colorScheme="red" size="xs">
                <FaUndo /> <Box ml={1}>Сбросить</Box>
              </Button>
            ) : null}
          </>
        ) : (
          <>
            {tags.map((filterKey) => {
              return (
                <Tag size="sm" key={filterKey} borderRadius="full" variant="solid" colorScheme="blue">
                  <TagLabel>{getTagLabel(filterKey)}</TagLabel>
                  <TagCloseButton onClick={() => removeFilter(filterKey)} />
                </Tag>
              )
            })}
            {tags.length ? (
              <Button onClick={() => resetFilters(DEFAULT_DOC_FILTER_VALUES)} colorScheme="red" size="xs">
                <FaUndo /> <Box ml={1}>Сбросить</Box>
              </Button>
            ) : null}
          </>
        )}
      </Flex>
      <DocsTotalSum
        totalSum={totalSum}
        selectedDocAmount={selectedExtendedDocsIds.length}
        totalSumSelectedDocs={totalSumSelectedDocs}
        docsCount={docsCount}
        isDocAmountVisible={docsCount > 0 && (tagsProof.length > 0 || tags.length > 0)}
      />
      <Box rounded="full" position="relative" minH="70vh">
        {isLoading && <Loader />}
        {categoryInfo?.name === TRANSFER_CATEGORY_NAME ? (
          <TableContainer>
            <Table variant="simple" colorScheme="blackAlpha">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Дата</Th>
                  <Th>Автор</Th>
                  <Th>Объект (откуда)</Th>
                  <Th>Объект (куда)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {docs.map((doc) => (
                  <Tr key={doc.id}>
                    <Td>
                      <Link as={ReactLink} color="blue.400" fontWeight="bold" textDecoration="underline" to={`/document/${doc.id}`} _hover={{ color: 'blue.700' }}>
                        {doc.id}
                      </Link>
                    </Td>
                    <Td>{doc.date ? dateFormat(doc.date, 'mmmm dd, yyyy') : '-'}</Td>
                    <Td>
                      {doc.author.name} {doc.author.last_name}
                    </Td>
                    <Td>{doc.transfer?.from_record_category.name ?? '-'}</Td>
                    <Td>{doc.transfer?.to_record_category.name ?? '-'}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : isProofOfWorkCategory ? (
          <TableContainer>
            <Table variant="simple" colorScheme="blackAlpha">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Дата</Th>
                  <Th>Автор</Th>
                  <Th>Объект</Th>
                </Tr>
              </Thead>
              <Tbody>
                {docs.map((doc) => (
                  <Tr key={doc.id}>
                    <Td>
                      <Link as={ReactLink} color="blue.400" fontWeight="bold" textDecoration="underline" to={`/document/${doc.id}`} _hover={{ color: 'blue.700' }}>
                        {doc.id}
                      </Link>
                    </Td>
                    <Td>{doc.date ? dateFormat(doc.date, 'mmmm dd, yyyy') : '-'}</Td>
                    <Td>
                      {doc.author.name} {doc.author.last_name}
                    </Td>
                    <Td>{doc.record_category?.name ?? '-'}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : isExtendedView ? (
          <TableContainer>
            <Table variant="simple" colorScheme="blackAlpha">
              <Thead>
                <Tr>
                  <Th>
                    <FormControl display="flex" gap={3}>
                      <Checkbox isChecked={selectedExtendedDocsIds.length === docs.length} onChange={(event) => selectAllExtendedDoc(event.target.checked)} />
                      <div>#</div>
                    </FormControl>
                  </Th>
                  <Th>Дата</Th>
                  <Th>Автор</Th>
                  <Th>Получатель</Th>
                  <Th>Поставщик</Th>
                  <Th>Сумма</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {docs.map((doc) => (
                  <>
                    <Tr key={doc.id}>
                      <Td>
                        <FormControl display="flex" gap={3}>
                          <Checkbox isChecked={selectedExtendedDocsIds.includes(doc.id)} onChange={(event) => selectExtendedDoc(event.target.checked, doc.id)} />
                          <Link as={ReactLink} color="blue.400" fontWeight="bold" textDecoration="underline" to={`/document/${doc.id}`} _hover={{ color: 'blue.700' }}>
                            {doc.id}
                          </Link>
                        </FormControl>
                      </Td>
                      <Td>{doc.date ? dateFormat(doc.date, 'mmmm dd, yyyy') : '-'}</Td>
                      <Td>
                        {doc.author.name} {doc.author.last_name}
                      </Td>
                      <Td>{receivers(doc)}</Td>
                      <Td>{doc.supplier?.name ?? '-'}</Td>
                      <Td>{getNumberWithSpaces(doc.total_price, true)}</Td>
                      <Td>
                        <Box display="flex" gap={2}>
                          <Link as={ReactLink} to={`/document/${doc.id}`} _hover={{ color: 'blue.700' }}>
                            <IconButton aria-label="Edit" icon={<FiEdit />} />
                          </Link>
                          <IconButton
                            disabled={doc.records.length === 0}
                            onClick={() => toggleRecordsPreview(doc.id)}
                            aria-label="Toggle Preview"
                            icon={openedRecordsPreview[doc.id] ? <FiEyeOff /> : <FiEye />}
                          />
                        </Box>
                      </Td>
                    </Tr>
                    <DocsRecordsPreview isOpen={!!openedRecordsPreview[doc.id]} records={docRecords[doc.id]} />
                  </>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer>
            <Table variant="simple" colorScheme="blackAlpha">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Дата</Th>
                  <Th>Автор</Th>
                  <Th>Получатель</Th>
                  <Th>Поставщик</Th>
                  <Th>Сумма</Th>
                </Tr>
              </Thead>
              <Tbody>
                {docs.map((doc) => (
                  <Tr key={doc.id}>
                    <Td>
                      <Link as={ReactLink} color="blue.400" fontWeight="bold" textDecoration="underline" to={`/document/${doc.id}`} _hover={{ color: 'blue.700' }}>
                        {doc.id}
                      </Link>
                    </Td>
                    <Td>{doc.date ? dateFormat(doc.date, 'mmmm dd, yyyy') : '-'}</Td>
                    <Td>
                      {doc.author.name} {doc.author.last_name}
                    </Td>
                    <Td>{receivers(doc)}</Td>
                    <Td>{doc.supplier?.name ?? '-'}</Td>
                    <Td>{getNumberWithSpaces(doc.total_price, true)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Box>

      <Pagination totalPages={totalPages} handlePageClick={handlePageClick} />

      {isProofOfWorkCategory ? (
        <ProofOfWorkFiltersSiedbar isFilterOpen={isFilterOpen} filterValues={proofOfWorkFilterValues} setFilterClose={onClose} setFilterValues={setProofOfWorkFilterValues} />
      ) : (
        <FiltersSidebar filterValues={filterValues} isFilterOpen={isFilterOpen} setFilterClose={onClose} setFilterValues={setFilterValues} />
      )}

      {isExtendedView && <MergeDocsModal isOpen={isMergeDocsModalOpen} onClose={onCloseActionModal} docIds={selectedExtendedDocsIds} docs={docs} />}
      {isExtendedView && <DeleteDocsModal isOpen={isDeleteDocsModalOpen} onClose={onCloseActionModal} docIds={selectedExtendedDocsIds} docs={docs} />}
      {isExtendedView && <UpdateDocsModal isOpen={isUpdateDocsModalOpen} onClose={onCloseActionModal} docIds={selectedExtendedDocsIds} />}
    </Stack>
  )
}

import { Stack, FormControl, FormLabel, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Switch } from '@chakra-ui/react'
import { AsyncSelect, Select } from 'chakra-react-select'
import { AdditionalFilters as AdditionalFiltersType, SelectOption, SelectTypeOption } from '~/types/common'
import { Group } from '~/types/groups'
import { ChangeEvent, useEffect, useState } from 'react'
import { FilterValues } from '~/types/reports'
import { useGetOrganizations } from '~/hooks/useGetOrganizations'
import { GetRecordsGroupsParams } from '~/types/records'
import DatePicker from '~/components/DatePicker'
import { getRecordsGroups } from '~/api/records'
import { GROUP_TYPES } from '~/utils/constants'
import AdditionalFilters from '~/components/AdditionalFilters'

interface Props {
  isFilterOpen: boolean
  filterValues: FilterValues
  setFilterClose: (status: boolean) => void
  setFilterValues: (filters: FilterValues) => void
}

export default function ObjectsFilters({ isFilterOpen, filterValues, setFilterClose, setFilterValues }: Props) {
  const [organizations, loadOrganizations, getOrganizatons] = useGetOrganizations()
  const [groups, setGroups] = useState<Group[]>([])

  const getGroups = async (params: GetRecordsGroupsParams = {}) => {
    const { results } = await getRecordsGroups({ ...params, limit: 1000 })

    setGroups(results)
    return results
  }

  const searchGroups = (inputValue: string, callback: (options: Group[]) => void) => {
    getGroups({ name: inputValue }).then((updated) => {
      callback(updated)
    })
  }

  useEffect(() => {
    getOrganizatons()
    getGroups()
  }, [])

  return (
    <Drawer isOpen={isFilterOpen} placement="right" onClose={() => setFilterClose(true)} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Выберите фильтр</DrawerHeader>

        <DrawerBody>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel htmlFor="group">Группа</FormLabel>

              <AsyncSelect
                placeholder="Введите..."
                id="group"
                isSearchable
                isClearable
                isMulti
                getOptionValue={(option: SelectOption) => `${option.id}`}
                getOptionLabel={(option: SelectOption) => `${option.name}`}
                value={filterValues.group}
                // @ts-expect-error dunno why erroring
                onChange={(newValue) => setFilterValues({ ...filterValues, group: newValue || null })}
                defaultOptions={groups}
                loadOptions={searchGroups}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="supplier">Поставщик</FormLabel>
              <AsyncSelect
                id="supplier"
                isClearable
                isSearchable
                isMulti
                placeholder="Введите..."
                loadOptions={loadOrganizations}
                defaultOptions={organizations}
                getOptionValue={(org: SelectOption) => `${org?.id}`}
                getOptionLabel={(org: SelectOption) => `${org?.name}`}
                value={filterValues.supplier}
                // @ts-expect-error dunno why erroring

                onChange={(newValue) => setFilterValues({ ...filterValues, supplier: newValue || null })}
                chakraStyles={{
                  control: (provided) => ({ ...provided, backgroundColor: 'white' })
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="recordClient">Получатель (заказчик)</FormLabel>
              <AsyncSelect
                isClearable
                placeholder="Введите..."
                isSearchable
                isMulti
                getOptionValue={(option: SelectOption) => `${option.id}`}
                getOptionLabel={(option: SelectOption) => `${option.name}`}
                value={filterValues.client}
                // @ts-expect-error dunno why erroring
                onChange={(newValue) => setFilterValues({ ...filterValues, client: newValue || null })}
                defaultOptions={organizations}
                loadOptions={loadOrganizations}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="date-gte">С даты</FormLabel>
              <DatePicker
                id="date-gte"
                selectedDate={filterValues.date_gte ? new Date(filterValues.date_gte) : null}
                onChange={(newDate) => setFilterValues({ ...filterValues, date_gte: newDate })}
                showPopperArrow
                isClearable
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="date">По дату</FormLabel>
              <DatePicker
                id="date-lte"
                selectedDate={filterValues.date_lte ? new Date(filterValues.date_lte) : null}
                onChange={(newDate) => setFilterValues({ ...filterValues, date_lte: newDate })}
                showPopperArrow
                isClearable
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="type">Категория группы</FormLabel>
              <Select<SelectTypeOption>
                id="type"
                isClearable
                value={filterValues.group_type}
                getOptionValue={(option: SelectTypeOption) => `${option?.id}`}
                getOptionLabel={(option: SelectTypeOption) => `${option?.name}`}
                // @ts-expect-error TODO
                onChange={(newValue) => setFilterValues({ ...filterValues, group_type: newValue || null })}
                placeholder="Выберите..."
                options={GROUP_TYPES}
              />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="own-entity" mb="0">
                Собственное юр.лицо
              </FormLabel>
              <Switch
                id="own-entity"
                isChecked={!!filterValues.own_entity}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setFilterValues({ ...filterValues, own_entity: event.target.checked })}
              />
            </FormControl>

            <AdditionalFilters
              filterValues={filterValues}
              setFilterValues={(additionalFilters: AdditionalFiltersType) => setFilterValues({ ...filterValues, ...additionalFilters })}
              isSplitByCategoriesVisible
              isAuthorFilterVisible
            />
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, HStack, FormControl, Stack, Checkbox, FormLabel } from '@chakra-ui/react'
import DatePicker from '~/components/DatePicker'
import { useContext, useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns/esm'
import { CloneDocOptions, CloneDocParams, DocsCategoryResponse } from '~/types/docs'
import { AsyncSelect } from 'chakra-react-select'
import { CategoriesContext } from '~/utils/context'

interface Props {
  isOpen: boolean
  onClone: (params: CloneDocParams) => void
  onClose: () => void
}

export default function CloneModal({ isOpen, onClone, onClose }: Props) {
  const { categories } = useContext(CategoriesContext)

  const [dates, setDate] = useState<(Date | null)[]>([null])
  const [filteredDates, setFilteredDates] = useState<Date[]>([])
  const [disabledDates, setDisabledDates] = useState<Date[]>([])
  const [cloneOptions, setCloneOptions] = useState<CloneDocParams['options']>([])
  const [selectedCategory, setSelectedCategory] = useState<DocsCategoryResponse | null>(null)

  const isRecordsChecked = useMemo(() => cloneOptions.includes('records'), [cloneOptions])
  const isProcessedChecked = useMemo(() => cloneOptions.includes('processed'), [cloneOptions])

  const onSubmit = async () => {
    const formatted = filteredDates.map((date) => format(date, 'yyyy-MM-dd'))
    const params: CloneDocParams = {
      dates: formatted,
      options: cloneOptions,
      category_id: selectedCategory?.id || undefined
    }
    onClone(params)
  }

  const addDate = (date: Date, index: number) => {
    const newDates = [...dates]
    newDates[index] = date
    setDate(newDates)
  }

  const checkOption = (status: boolean, name: CloneDocOptions) => {
    if (status) {
      setCloneOptions([...cloneOptions, name])
    } else {
      setCloneOptions(cloneOptions.filter((o) => o !== name))
    }
  }

  useEffect(() => {
    if (dates[dates.length - 1] !== null) {
      setDate([...dates, null])
    }

    // todo: why doesnt work without as Date[]?
    const filtered = dates.filter((date) => date) as Date[]

    setFilteredDates(filtered)

    setDisabledDates([...filtered])
  }, [dates])

  useEffect(() => {
    setDate([null])
  }, [isOpen])

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
      <ModalOverlay />
      <ModalContent as="form" noValidate>
        <ModalHeader>Клонировать Документ</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            {dates.map((date, index) => (
              <FormControl key={index}>
                <DatePicker id="date" selectedDate={date} onChange={(selectedDate) => addDate(selectedDate, index)} showPopperArrow excludeDates={disabledDates} />
              </FormControl>
            ))}

            <FormControl>
              <FormLabel htmlFor="category">Категория</FormLabel>
              <AsyncSelect<DocsCategoryResponse>
                placeholder="Введите..."
                id="category"
                getOptionValue={(option: DocsCategoryResponse) => `${option.id}`}
                getOptionLabel={(option: DocsCategoryResponse) => `${option.name}`}
                value={selectedCategory}
                onChange={(newValue) => {
                  setSelectedCategory(newValue || null)
                }}
                defaultOptions={categories}
              />
            </FormControl>

            <FormControl>
              <Checkbox isChecked={isRecordsChecked} onChange={(event) => checkOption(event.target.checked, 'records')}>
                Скопировать позиции учета
              </Checkbox>
            </FormControl>

            <FormControl>
              <Checkbox isChecked={isProcessedChecked} onChange={(event) => checkOption(event.target.checked, 'processed')}>
                Завершить обработку для клона
              </Checkbox>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack w="full" justifyContent="space-between">
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Отмена
            </Button>
            <Button colorScheme="green" onClick={onSubmit}>
              Клонировать
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { DocsCategoryResponse, GetDocsCategoriesResponse, GetDocsParams, GetDocsResponse, GetDocResponse, ShortDoc, DocDuplicate, Doc } from '~/types/docs'
import { get, post, patch, deleteApi } from './base'

export const getDocsCategories = () => get<GetDocsCategoriesResponse>(`documents-categories/`)
export const getDocsCategoryInfo = (id: number) => get<DocsCategoryResponse>(`documents-categories/${id}/`)

export const getDocs = (params: GetDocsParams) => get<GetDocsResponse>(`documents/`, { params })
export const getDoc = (id: number) => get<GetDocResponse>(`documents/${id}/`)
export const updateDoc = (id: number, body: Partial<ShortDoc>) => patch<{ id: string }>(`documents/${id}/`, body)
export const deleteDoc = (id: number) => deleteApi(`documents/${id}/`)
export const deleteDocs = (ids: number[]) => post(`documents/bulk-destroy/`, { ids })
export const updateDocs = ({ ids, data }: { ids: number[]; data: Partial<Doc> }) => post(`documents/bulk-patch/`, { ids, data })

export const deleteDocMedia = (id: number) => deleteApi(`documents-media/${id}/`)

export const addDocMedia = (media: FormData) => post(`documents-media/`, media, { headers: { 'Content-Type': 'multipart/form-data' } })

export const duplicateDoc = (duplicate: DocDuplicate) => post(`documents/duplicate/`, duplicate)

export const downloadMediaDoc = (params: { document: number }) => get<{ media_url: string }>(`documents-media/download/`, { params })

export const getDocumentsCountTotal = (params: GetDocsParams) =>
  get<{
    total_price: number | null
  }>(`documents/count-total-price/`, { params })

export const mergeDocs = (ids: number[]) => post<{ id: number }>(`documents/merge/`, { ids })
export const mergeDocsCancel = (id: number) => post<Doc>(`documents/${id}/merge-cancel/`)
export const mergeDocsConfirm = (id: number) => post<Doc>(`documents/${id}/merge-confirm/`)

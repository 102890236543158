import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, HStack, Text } from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export default function CloneAlertModal({ isOpen, onClose }: Props) {
  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
      <ModalOverlay />
      <ModalContent as="form" noValidate>
        <ModalHeader>Успешное клонирование</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="lg">Документ был разбит на несколько – по количеству получателей в блоке учет</Text>
        </ModalBody>
        <ModalFooter>
          <HStack w="full" justifyContent="flex-end">
            <Button colorScheme="green" onClick={onClose}>
              Ок
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import React from 'react'
import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { Doc } from '~/types/docs'
import { deleteDocs } from '~/api/docs'

interface Props {
  docs: Doc[]
  docIds: number[]
  isOpen: boolean
  onClose: () => void
}

export default function DeleteDocsModal({ docIds, docs, isOpen, onClose }: Props) {
  const onSubmit = async () => {
    try {
      const res = await deleteDocs(docIds)
      onClose()
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Удалить выбранные документы?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Документы с id {docIds.join(', ')} будут удалены</ModalBody>

        <ModalFooter>
          <HStack gap={2} justifyContent="flex-end" width="100%">
            <Button colorScheme="red" onClick={onClose}>
              Отмена
            </Button>
            <Button colorScheme="green" onClick={onSubmit}>
              Выполнить
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

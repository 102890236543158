import {
  Pagination as PaginationPlugin,
  PaginationContainer,
  PaginationPrevious,
  PaginationPageGroup,
  PaginationSeparator,
  PaginationPage,
  PaginationNext,
  usePagination
} from '@ajna/pagination'
import { Center } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import { CategoriesContext } from '~/utils/context'
import { useParams } from 'react-router-dom'
import { localStorageGetValue, localStorageSetValue, PaginationObject } from '~/utils/localStorage'

interface Props {
  totalPages: number
  handlePageClick: (offset: number, currentPage: number) => void
  pageSize?: number
}

export default function Pagination({ totalPages, handlePageClick, pageSize = 20 }: Props) {
  const { category } = useParams()

  const { currentPage, setCurrentPage, pagesCount, pages, offset } = usePagination({
    pagesCount: totalPages,
    limits: {
      outer: 2,
      inner: 2
    },
    initialState: { currentPage: 1, pageSize }
  })

  const { categories } = useContext(CategoriesContext)

  const [isPaginationChecked, setIsPaginationChecked] = useState(false)

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage)
  }

  const saveActivePage = () => {
    const oldPagination = localStorageGetValue<PaginationObject>('pagination') || {}
    const updatedPagination = {
      ...oldPagination,
      [category!]: {
        page: currentPage,
        offset
      }
    }
    localStorageSetValue('pagination', updatedPagination)
  }

  useEffect(() => {
    if (!isPaginationChecked) return
    handlePageClick(offset, currentPage)
  }, [currentPage])

  useEffect(() => {
    if (categories && category && isPaginationChecked) {
      saveActivePage()
    }
  }, [categories, currentPage])

  useEffect(() => {
    setTimeout(() => {
      setIsPaginationChecked(true)
    }, 1000)

    if (!category) return

    const savedPagination = localStorageGetValue<PaginationObject>('pagination') || null

    if (savedPagination) {
      if (savedPagination[category!]) {
        setCurrentPage(+savedPagination![category!].page)
      } else {
        setCurrentPage(1)
      }
    }

    return () => setIsPaginationChecked(false)
  }, [category])

  return (
    <Center>
      <PaginationPlugin pagesCount={pagesCount} currentPage={currentPage} onPageChange={handlePageChange}>
        <PaginationContainer align="center" justify="space-between" p={4}>
          <PaginationPrevious
            _hover={{
              bg: 'blue.200'
            }}
            bg="blue.100"
          >
            <FiChevronsLeft />
          </PaginationPrevious>
          <PaginationPageGroup isInline align="center" separator={<PaginationSeparator bg="blue.300" fontSize="sm" w={7} jumpSize={11} />}>
            {pages.map((page: number) => (
              <PaginationPage
                w={7}
                _current={{
                  w: 7,
                  bg: 'blue.200',
                  fontSize: 'sm',
                  _hover: {
                    bg: 'blue.300'
                  }
                }}
                key={`pagination_page_${page}-${category}`}
                page={page}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext
            _hover={{
              bg: 'blue.200'
            }}
            bg="blue.100"
          >
            <FiChevronsRight />
          </PaginationNext>
        </PaginationContainer>
      </PaginationPlugin>
    </Center>
  )
}

Pagination.defaultProps = {
  pageSize: 20
}

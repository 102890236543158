import React from 'react'
import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { Doc } from '~/types/docs'
import { updateDocs } from '~/api/docs'

interface Props {
  docIds: number[]
  isOpen: boolean
  onClose: () => void
}

export default function UpdateDocsModal({ docIds, isOpen, onClose }: Props) {
  const onSubmit = async () => {
    try {
      await updateDocs({ ids: docIds, data: { is_processed: true } })
      onClose()
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Завершть обработку выбранных документов?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Документы с id {docIds.join(', ')} будут заверешены</ModalBody>

        <ModalFooter>
          <HStack gap={2} justifyContent="flex-end" width="100%">
            <Button colorScheme="red" onClick={onClose}>
              Отмена
            </Button>
            <Button colorScheme="green" onClick={onSubmit}>
              Выполнить
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { Box, Button, HStack, IconButton, Input, InputGroup, InputRightElement, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import { getRecordsCategories } from '~/api/records'
import { getLimits as getLimitsApi, deleteLimit as deleteLimitApi } from '~/api/limits'
import EmptyResults from '~/components/EmptyResults'
import Loader from '~/components/Loader'
import { Category, StringSignature } from '~/types/common'
import { GetLimitsParams, Limit, LimitsFilters, LimitsSortParam } from '~/types/limits'
import { DEFAULT_SELECTED_LIMIT, NO_GROUPS } from '~/utils/constants'
import LimitModal from '~/components/references/LimitModal'
import { useParams } from 'react-router-dom'
import TotalCount from '~/components/TotalCount'
import { GroupTypesKey, GroupTypesSignature } from '~/types/groups'
import { getNumberWithSpaces } from '~/utils/helpers'
import { FcGenericSortingAsc, FcGenericSortingDesc, FcNumericalSorting12, FcNumericalSorting21 } from 'react-icons/fc'
import { FiFilter, FiSearch } from 'react-icons/fi'
import { localStorageGetValue, localStorageSetValue } from '~/utils/localStorage'
import LimitsFiltersSidebar from '~/components/references/LimitsFiltersSidebar'
import { getUsersFilters } from '~/api/users'
import { UsersFilters } from '~/types/users'

export const DEFAULT_LIMITS_FILTER_VALUES: LimitsFilters = {
  group_type: null,
  o: null
}

type FiltersToSendType = StringSignature<number | number[] | boolean | string | null | undefined>

export default function CategoryLimits({ category }: { category: Category }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isFiltersOpen, onOpen: onFiltersOpen, onClose: onFiltersClose } = useDisclosure()

  const [isLoading, setIsLoading] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [limits, setLimits] = useState<Limit[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [limitData, setLimitData] = useState<Limit>(DEFAULT_SELECTED_LIMIT)
  const [groupTypeTotalPrice, setGroupTypeTotalPrice] = useState<GroupTypesSignature<number>>({})
  const [totalGroupPrice, setTotalGroupPrice] = useState(0)
  const [totalGroupTakings, setTotalGroupTakings] = useState(0)
  const [filterValues, setFilterValues] = useState<LimitsFilters>(DEFAULT_LIMITS_FILTER_VALUES)
  const [isLocalStorageChecked, setIsLocalStorageChecked] = useState(false)
  const [filtersToSend, setFiltersToSend] = useState<FiltersToSendType>({} as FiltersToSendType)
  const [searchString, setSearchString] = useState<string | undefined>()
  const [userFilters, setUserFilters] = useState<UsersFilters>()

  const id = +useParams().id!

  useEffect(() => {
    if (category.takings) {
      setTotalGroupTakings(+category.takings)
    }
  }, [category])

  const getLimits = async (params: GetLimitsParams = {}) => {
    setIsLoading(true)
    const { results } = await getLimitsApi({ ...params, ...filtersToSend, group_name: searchString, category: id || null })
    setLimits(results)
    setTotalGroupPrice(results.reduce((acc, cur) => acc + +cur.total_price, 0))

    setIsLoading(false)
  }

  const getCategories = async () => {
    const { results } = await getRecordsCategories({ limit: 1000 })
    setCategories(results)
  }

  const deleteLimit = async (limitId: number) => {
    await deleteLimitApi(limitId)
    await getLimits()
    await getCategories()
    onClose()
  }

  const updateLimit = async () => {
    await getLimits()
    onClose()
  }

  const createLimit = async () => {
    await getLimits()
    await getCategories()
    onClose()
  }

  const openToCreate = async () => {
    setIsCreate(true)
    setLimitData(DEFAULT_SELECTED_LIMIT)
    onOpen()
  }

  const getLimitGroups = (groups: Limit['groups']): string => {
    if (!groups.length) {
      return NO_GROUPS
    }

    const groupsLength = groups.length - 1

    const first = groups[0]

    let res = `${first.name}`

    if (groupsLength > 0) {
      res += ` (+${groupsLength})`
    }

    if (first.is_removed) {
      res += ` (группа удалена)`
    }

    return res
  }

  useEffect(() => {
    if (!limits) return

    const groupTypesTotal: GroupTypesSignature<number> = {}

    const types = new Set<GroupTypesKey>()

    limits.forEach((l) => {
      const total = l.total_price

      if (l.groups.length) {
        const firstGroup = l.groups[0]
        if (firstGroup.type) {
          types.add(firstGroup.type)

          groupTypesTotal[firstGroup.type] = groupTypesTotal[firstGroup.type] ? groupTypesTotal[firstGroup.type]! + total : total
        } else {
          groupTypesTotal.null = groupTypesTotal.null ? groupTypesTotal.null + total : total
          types.add('null')
        }
      }
    })

    setGroupTypeTotalPrice(groupTypesTotal)
  }, [limits])

  const openToUpdate = (limit: Limit) => {
    setIsCreate(false)
    setLimitData(limit)
    onOpen()
  }

  useEffect(() => {
    const filtersFromStorage = localStorageGetValue<LimitsFilters>('limits-filters')
    if (filtersFromStorage) {
      setFilterValues(filtersFromStorage)
      setIsLocalStorageChecked(true)
    } else {
      setIsLocalStorageChecked(true)
      setFilterValues(DEFAULT_LIMITS_FILTER_VALUES)
    }
  }, [])

  const getUserPlannedTakings = async () => {
    const filters = await getUsersFilters()
    setUserFilters(filters)
  }

  useEffect(() => {
    if (isLocalStorageChecked) {
      localStorageSetValue('limits-filters', filterValues)
      getCategories()
      getLimits()
      getUserPlannedTakings()
    }
  }, [filtersToSend, isLocalStorageChecked, searchString])

  useEffect(() => {
    if (!filterValues || !isLocalStorageChecked) return

    const groupType = filterValues?.group_type === null ? undefined : filterValues?.group_type?.id === null ? 'null' : filterValues?.group_type?.id

    const transformedFilters: FiltersToSendType = {
      group_type: groupType,
      o: filterValues.o?.join(', ') || null
    }

    setFiltersToSend(transformedFilters)
  }, [filterValues, isLocalStorageChecked])

  const onSort = (key: LimitsSortParam) => {
    setFilterValues({ ...filterValues, o: [key] })
  }

  return (
    <Stack spacing={6}>
      <HStack justifyContent="space-between">
        <Button colorScheme="green" onClick={openToCreate}>
          Добавить
        </Button>

        <HStack gap={2}>
          <Button onClick={() => onSort(filterValues.o?.includes('name') ? '-name' : 'name')} aria-label="Sort by name">
            По наименованию
            <Box ml={3}>{filterValues.o?.includes('name') ? <FcGenericSortingAsc /> : <FcGenericSortingDesc />}</Box>
          </Button>
          <Button onClick={() => onSort(filterValues.o?.includes('total_price') ? '-total_price' : 'total_price')} aria-label="Sort by price">
            По сумме
            <Box ml={3}>{filterValues.o?.includes('total_price') ? <FcNumericalSorting12 /> : <FcNumericalSorting21 />}</Box>
          </Button>

          <IconButton onClick={onFiltersOpen} aria-label="Open filters" icon={<FiFilter />} />
        </HStack>
      </HStack>

      <InputGroup>
        <Input bg="white" placeholder="Введите наименование группы..." value={searchString} onInput={(e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)} />
        <InputRightElement pointerEvents="none" children={<FiSearch color="gray.300" />} />
      </InputGroup>
      <Box rounded="full" position="relative" minH="70vh">
        {isLoading ? (
          <Loader />
        ) : limits.length > 0 ? (
          <TableContainer>
            <Table variant="simple" colorScheme="blackAlpha">
              <Thead>
                <Tr>
                  <Th>Группа</Th>
                  <Th>Ед. изм</Th>
                  <Th>Кол-во</Th>
                  <Th>Стоимость</Th>
                </Tr>
              </Thead>
              <Tbody>
                {limits.map((limit) => (
                  <Tr key={limit.id}>
                    <Td>
                      <Button colorScheme="blue" fontWeight="bold" variant="link" onClick={() => openToUpdate(limit)}>
                        {limit.name || getLimitGroups(limit.groups)}
                      </Button>
                    </Td>
                    <Td>{limit.items[0]?.unit?.name || '-'}</Td>
                    <Td>{limit.items[0]?.amount || '-'}</Td>
                    <Td textAlign="right">{getNumberWithSpaces(+limit.total_price, true)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <EmptyResults />
        )}
        <TotalCount
          isGroupsTotalVisible={Object.keys(groupTypeTotalPrice).length > 0}
          totalGroupTakings={totalGroupTakings}
          totalGroupPrice={totalGroupPrice}
          groupTypeTotalPrice={groupTypeTotalPrice}
          isCustomTakingsChecked={userFilters?.references_filters?.isChecked}
          customPlannedTakings={userFilters?.references_filters?.plannedTakings}
        />
      </Box>
      <LimitModal
        onClose={onClose}
        limitData={limitData}
        isCreate={isCreate}
        isOpen={isOpen}
        onDelete={deleteLimit}
        onUpdate={updateLimit}
        onCreate={createLimit}
        categories={categories}
        categoryId={id}
      />

      <LimitsFiltersSidebar
        isFilterOpen={isFiltersOpen}
        filterValues={filterValues}
        setFilterClose={onFiltersClose}
        setFilterValues={setFilterValues}
        onRefresh={() => {
          getUserPlannedTakings()
          getLimits()
        }}
        userFilters={userFilters!}
      />
    </Stack>
  )
}

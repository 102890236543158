import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableContainer,
  Checkbox
} from '@chakra-ui/react'
import { Category } from '~/types/common'
import { useEffect, useMemo, useState } from 'react'
import { getNumberWithSpaces } from '~/utils/helpers'
import Pagination from '~/components/Pagination'

interface Props {
  isOpen: boolean
  categories: Category[]
  excludedCategories: Category[]
  onClose: () => void
  onSave: (newExludedCategories: Category[]) => void
}

const ITEMS_PER_PAGE = 10

export default function ExludedListModal({ isOpen, categories, excludedCategories, onClose, onSave }: Props) {
  const [currentPage, setCurrentPage] = useState(1)
  const [localExludedCategories, setLocalExludedCategories] = useState<Category[]>([])

  const exludedCategoriesIds = localExludedCategories.map((c) => c.id)
  const activeCategories = useMemo(() => {
    return categories.map((c) => {
      return {
        ...c,
        isExluded: exludedCategoriesIds.includes(c.id)
      }
    })
  }, [categories, exludedCategoriesIds])

  const pageCount = Math.ceil(activeCategories.length / ITEMS_PER_PAGE)

  const paginatedItems = useMemo(() => {
    const start = currentPage === 1 ? 0 : (currentPage - 1) * ITEMS_PER_PAGE
    const arr = activeCategories.slice(start, start + ITEMS_PER_PAGE)
    return arr
  }, [activeCategories, currentPage])

  const handlePageClick = (offset: number, newPage: number) => {
    setCurrentPage(newPage)
  }

  useEffect(() => {
    setLocalExludedCategories(excludedCategories)
  }, [excludedCategories])

  const onSubmit = () => {
    onSave(localExludedCategories)
  }

  const updateExluded = (cat: Category, removeFromExluded: boolean) => {
    if (removeFromExluded) {
      const filtered = localExludedCategories.filter((c) => c.id !== cat.id)
      setLocalExludedCategories(filtered)
    } else {
      setLocalExludedCategories([...localExludedCategories, cat])
    }
  }

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
      <ModalOverlay />
      <ModalContent as="form" noValidate>
        <ModalHeader>Текущие объекты</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer>
            <Table variant="simple" colorScheme="blackAlpha" size="sm">
              <Thead>
                <Tr>
                  <Th textAlign="center">#</Th>
                  <Th>Объект</Th>
                  <Th>Выручка</Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedItems.map((cat) => (
                  <Tr key={cat.id}>
                    <Td>
                      <Checkbox isChecked={!cat.isExluded} onChange={(e) => updateExluded(cat, e.target.checked)} />
                    </Td>
                    <Td maxWidth="300px" style={{ whiteSpace: 'pre-wrap' }}>
                      {cat.name}
                    </Td>
                    <Td>{getNumberWithSpaces(cat.takings!, true)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Pagination totalPages={pageCount} handlePageClick={handlePageClick} pageSize={ITEMS_PER_PAGE} />

          <HStack w="full" justifyContent="flex-end">
            <Button colorScheme="green" onClick={onSubmit}>
              Сохранить
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              Отмена
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import { createRecordCategory, deleteRecordCategory, getRecordsCategories, updateRecordCategory } from '~/api/records'
import Loader from '~/components/Loader'
import Pagination from '~/components/Pagination'
import CatModal from '~/components/references/CatModal'
import { Category } from '~/types/common'
import { GetRecordsCategoriesParams, ShortRecordCategory } from '~/types/records'
import { DEFAULT_SELECTED_CATEGORY } from '~/utils/constants'
import { Link as ReactLink } from 'react-router-dom'
import { FiSearch } from 'react-icons/fi'
import { getNumberWithSpaces } from '~/utils/helpers'

// todo: remove all logic with update delete create cat
export default function Categories() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [categories, setCategories] = useState<Category[]>([])
  const [catData, setCatData] = useState<Category>(DEFAULT_SELECTED_CATEGORY)
  const [totalPages, setTotalPages] = useState(0)
  const [searchString, setSearchString] = useState<string | undefined>()
  const [showOnlyActive, setShowOnlyActive] = useState(false)

  const getCategories = async (params: GetRecordsCategoriesParams = {}) => {
    setIsLoading(true)
    const { results, count } = await getRecordsCategories({ ...params, name: searchString, is_hidden: false, is_closed: showOnlyActive ? false : undefined, limit: 20 })

    setCategories(results)
    setTotalPages(Math.ceil(count / 20))
    setIsLoading(false)
  }

  const handlePageClick = (offset: number) => {
    getCategories({ offset })
  }

  const deleteCat = async (id: number) => {
    await deleteRecordCategory(id)
    await getCategories()
    onClose()
  }

  const updateCat = async (id: number, newCat: ShortRecordCategory) => {
    await updateRecordCategory(id, newCat)
    await getCategories()
    onClose()
  }

  const createCat = async (newCat: ShortRecordCategory) => {
    await createRecordCategory(newCat)
    await getCategories()
    onClose()
  }

  const openToCreate = async () => {
    setIsCreate(true)
    setCatData(DEFAULT_SELECTED_CATEGORY)
    onOpen()
  }

  useEffect(() => {
    getCategories()
  }, [showOnlyActive, searchString])

  return (
    <Stack spacing={6}>
      <HStack justifyContent="space-between">
        <Heading as="h2" size="lg">
          Справочники - Категории / Объекты
        </Heading>
        <Button colorScheme="green" onClick={openToCreate}>
          Добавить
        </Button>
      </HStack>
      <HStack>
        <InputGroup>
          <Input bg="white" placeholder="Введите наименование категории..." value={searchString} onInput={(e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)} />
          <InputRightElement pointerEvents="none" children={<FiSearch color="gray.300" />} />
        </InputGroup>
        <FormControl maxW="200px" display="flex" alignItems="center">
          <Checkbox isChecked={showOnlyActive} onChange={(event) => setShowOnlyActive(event.target.checked)}>
            Отображать только действующие объекты
          </Checkbox>
        </FormControl>
      </HStack>
      <Box rounded="full" position="relative" minH="70vh">
        {isLoading && <Loader />}
        <TableContainer>
          <Table variant="simple" colorScheme="blackAlpha">
            <Thead>
              <Tr>
                <Th>Наименование</Th>
                <Th>Статус</Th>
                <Th>Выручка</Th>
              </Tr>
            </Thead>
            <Tbody>
              {categories.map((cat) => (
                <Tr key={cat.id}>
                  <Td>
                    <Link as={ReactLink} color="blue.400" fontWeight="bold" textDecoration="underline" to={`/references/category-limits/${cat.id}`} _hover={{ color: 'blue.700' }}>
                      {cat.name}
                    </Link>
                  </Td>
                  <Td>{cat.is_closed ? 'Закрыт' : 'Действующий'}</Td>
                  <Td>{getNumberWithSpaces(cat.takings!, true)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Pagination totalPages={totalPages} handlePageClick={handlePageClick} />
      <CatModal onClose={onClose} isOpen={isOpen} onDelete={deleteCat} onUpdate={updateCat} onCreate={createCat} isCreate={isCreate} catData={catData} />
    </Stack>
  )
}

import { format } from 'date-fns'
import { DocExtension } from '~/types/common'

export const getSum = (quantity: number, price: string) => quantity * +price

export const getDayNum = (date: string) => +date.split('-')[2]

export const updateDateForBackend = (date: string) => {
  const updated = date.split('.')
  const newDate = new Date(+updated[2], +updated[1] - 1, +updated[0])
  return format(newDate, 'yyyy-MM-dd')
}

export const getNumberWithSpaces = (num: string | number, isFixed = false) => {
  const isOnlyZeros = +num.toString().replaceAll('.', '') === 0
  if (isOnlyZeros) return '0'
  if (!num) return 0

  const transformed = isFixed ? (+num).toFixed(2) : num

  return transformed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') || 0
}

export const getExtension = (src: string): DocExtension => {
  return src.split('.').pop() as DocExtension
}

export const addOneDay = (str: Date | string | null) => {
  if (!str) return null

  const date = new Date(str)
  return new Date(date.setDate(date.getDate() + 1))
}

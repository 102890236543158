import {
  Stack,
  Heading,
  useColorModeValue,
  Box,
  FormControl,
  HStack,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableCaption,
  IconButton,
  useDisclosure,
  useToast,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Button
} from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import { Select } from 'chakra-react-select'
import { useEffect, useMemo, useState } from 'react'
import { getReportsMechanic, getReportsMechanicByAuthor, getReportsMechanicByCategory } from '~/api/reports'
import { getUsers, getUsersFilters, updateUsersFilters } from '~/api/users'
import { User } from '~/types/users'
import ru from 'date-fns/locale/ru' // eslint-disable-line import/no-duplicates
import { format, startOfMonth, endOfMonth, getDaysInMonth } from 'date-fns' // eslint-disable-line import/no-duplicates
import { ReportMechanic } from '~/types/reports'
import EmptyResults from '~/components/EmptyResults'
import Loader from '~/components/Loader'
import DatePicker from '~/components/DatePicker'

import '~/assets/table-tiny.css'
import { FiFilter, FiSettings } from 'react-icons/fi'
import MechanicsSettings from '~/components/reports/mechanics/MechanicsSettings'
import { Mode, ReportsMechanicsFilters, ReportsMechanicsSettings } from '~/types/reports-mechanics'
import { Group } from '~/types/groups'
import { MECHANICS_MODES, OBJECT_TYPES } from '~/utils/constants'
import MechanicsFilters from '~/components/reports/mechanics/MechanicsFilters'
import { GetRecordsGroupsParams } from '~/types/records'
import { getRecordsGroups } from '~/api/records'
import { useFiltersTags } from '~/hooks/useFiltersTags'
import { FaUndo } from 'react-icons/fa'

const defaultSettings: ReportsMechanicsSettings = {
  object: OBJECT_TYPES[0],
  tehnicsMethod: 'limits',
  category: null,
  group: null
}

export default function Mechanics() {
  const defaultFilters: ReportsMechanicsFilters = {
    mode: MECHANICS_MODES[0],
    author: null,
    technics: null,
    date: null
  }

  const [isLoading, setIsLoading] = useState(false)
  const [mechanics, setMechanics] = useState<User[]>([])
  const [reports, setReports] = useState<ReportMechanic[]>([])

  const [recordGroups, setRecordGroups] = useState<Group[]>([])

  const { isOpen: isFiltersOpen, onOpen: onFiltersOpen, onClose: onFiltersClose } = useDisclosure()
  const { isOpen: isSettingsOpen, onOpen: onSettingsOpen, onClose: onSettingsClose } = useDisclosure()

  const [settingsValues, setSettingsValues] = useState<ReportsMechanicsSettings>(defaultSettings)
  const [filtersValues, setFiltersValues] = useState<ReportsMechanicsFilters>(defaultFilters)

  const [isFiltersFetched, setIsFiltersFetched] = useState(false)

  const [getTagLabel, removeFilter, resetFilters, tags] = useFiltersTags<ReportsMechanicsFilters, ReportsMechanicsFilters>(filtersValues, filtersValues, setFiltersValues, true)

  const toast = useToast()

  const getMechanics = async () => {
    const { results } = await getUsers({ is_mechanic: true, limit: 1000 })
    setMechanics(results)
  }

  const updateSettings = async (newValues: ReportsMechanicsSettings) => {
    await updateUsersFilters({ report_mechanic_filters: { settings: newValues, filters: filtersValues } })
  }

  const updateSettingsValues = (newValues: ReportsMechanicsSettings) => {
    updateSettings(newValues)
    setSettingsValues(newValues)
  }

  const updateFilters = async () => {
    await updateUsersFilters({ report_mechanic_filters: { filters: filtersValues, settings: settingsValues } })
  }

  const updateFiltersValues = (updatedValues: Partial<ReportsMechanicsFilters>) => {
    const updated = {
      ...filtersValues,
      ...updatedValues
    }
    setFiltersValues(updated)
  }

  useEffect(() => {
    if (!isFiltersFetched) return
    updateFilters()
  }, [filtersValues, isFiltersFetched])

  const getReports = async () => {
    if (!filtersValues.date) return
    try {
      setIsLoading(true)

      const startDate = format(startOfMonth(new Date(filtersValues.date)), 'yyyy-MM-dd')
      const endDate = format(endOfMonth(new Date(filtersValues.date)), 'yyyy-MM-dd')
      const fn = filtersValues.mode.key === 'mechanic' ? getReportsMechanic : filtersValues.mode.key === 'author' ? getReportsMechanicByAuthor : getReportsMechanicByCategory

      const { results } = await fn({
        group: filtersValues.technics?.id || undefined,
        author: filtersValues.author?.id || undefined,
        period_after: startDate,
        period_before: endDate
      })
      setReports(results)
    } catch (e) {
      toast({
        title: 'Произошла ошибка при получении отчетов',
        status: 'error'
      })
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const getRecordGroups = async (params: GetRecordsGroupsParams = {}) => {
    const { results } = await getRecordsGroups({ ...params, limit: 1000 })

    setRecordGroups(results)
    return results
  }

  const getFilters = async () => {
    const { report_mechanic_filters: fetchedFilters } = await getUsersFilters()

    if (isFiltersFetched) return

    console.log('report_mechanic_filters', fetchedFilters)

    if (fetchedFilters.settings) {
      setSettingsValues(fetchedFilters.settings)
    }

    if (fetchedFilters.filters) {
      setFiltersValues(fetchedFilters.filters)
    }

    // const storedSettings = localStorageGetValue<ReportsMechanicsSettings>('mechanics-settings')
    // if (storedSettings) {
    //   setSettingsValues(storedSettings)
    // }
    //
    // const storedFilters = localStorageGetValue<ReportsMechanicsFilters>('mechanics-filters')
    // if (storedFilters) {
    //   setFiltersValues(storedFilters)
    // }

    setIsFiltersFetched(true)
  }

  useEffect(() => {
    getMechanics()

    getFilters()

    return () => setIsFiltersFetched(false)
  }, [])

  useEffect(() => {
    if (filtersValues.author || filtersValues.technics) {
      getReports()
    }
  }, [filtersValues])

  const technicsOptions = useMemo(() => {
    return (settingsValues.tehnicsMethod === 'groups' ? settingsValues.group : recordGroups) || []
  }, [settingsValues.group, settingsValues.tehnicsMethod, recordGroups])

  useEffect(() => {
    if (settingsValues?.object?.key === 'technics') {
      getRecordGroups({ from_category_limits: settingsValues.category?.map((c) => c.id).join(',') })
    }
  }, [settingsValues.category])

  return (
    <Stack spacing={6}>
      <HStack justifyContent="space-between">
        <Heading as="h2" size="lg">
          Отчет - механизаторы
        </Heading>

        <HStack gap={1}>
          <IconButton onClick={onFiltersOpen} aria-label="Open filters" icon={<FiFilter />} />
          <IconButton onClick={onSettingsOpen} aria-label="Open filters" icon={<FiSettings />} />
        </HStack>
      </HStack>

      <Box position="relative" p={5} rounded="lg" bg={useColorModeValue('white', 'gray.700')} border="1px" borderColor="gray.200">
        <HStack>
          <FormControl>
            {settingsValues.object?.key === 'technics' ? (
              <Select<Group>
                placeholder="Выберите технику"
                id="technics"
                value={filtersValues.technics}
                getOptionValue={(option: Group) => `${option?.id}`}
                getOptionLabel={(option: Group) => `${option.name}`}
                onChange={(newValue) => updateFiltersValues({ technics: newValue! })}
                options={technicsOptions}
              />
            ) : (
              <Select<User>
                placeholder="Выберите cотрудника"
                id="author"
                value={filtersValues.author}
                getOptionValue={(option: User) => `${option?.id}`}
                getOptionLabel={(option: User) => `${option.name} ${option.last_name}`}
                onChange={(newValue) => updateFiltersValues({ author: newValue })}
                options={mechanics}
              />
            )}
          </FormControl>
          <FormControl>
            <DatePicker
              placeholderText="Выберите месяц"
              id="date"
              selectedDate={filtersValues.date ? new Date(filtersValues.date) : null}
              onChange={(date) => updateFiltersValues({ date })}
              showMonthYearPicker
            />
          </FormControl>
          <FormControl>
            <Select<Mode>
              id="mode"
              value={filtersValues.mode}
              placeholder="Выберите режим"
              getOptionValue={(option: Mode) => `${option.key}`}
              getOptionLabel={(option: Mode) => `${option.label}`}
              onChange={(newMode) => updateFiltersValues({ mode: newMode! })}
              options={MECHANICS_MODES}
              chakraStyles={{
                control: (provided) => ({ ...provided, backgroundColor: 'white' })
              }}
            />
          </FormControl>
        </HStack>

        <Flex wrap="wrap" gap="2" my={5}>
          {tags.map((filterKey) => {
            return (
              <Tag size="sm" key={filterKey} borderRadius="full" variant="solid" colorScheme="blue">
                <TagLabel>{getTagLabel(filterKey)}</TagLabel>
                <TagCloseButton onClick={() => removeFilter(filterKey)} />
              </Tag>
            )
          })}
          {tags.length ? (
            <Button onClick={() => resetFilters(defaultFilters)} colorScheme="red" size="xs">
              <FaUndo /> <Box ml={1}>Сбросить</Box>
            </Button>
          ) : null}
        </Flex>

        <Stack spacing="5" position="relative">
          {isLoading ? (
            <Loader />
          ) : reports.length > 0 ? (
            reports.map((report) => (
              <TableContainer key={report.unit.id}>
                <Table variant="simple" colorScheme="blackAlpha" size="sm" className="table-tiny">
                  <TableCaption placement="top" textAlign="left" fontWeight="bold" fontSize="md">
                    {report.unit.name}
                  </TableCaption>
                  <Thead>
                    <Tr>
                      <Th>{filtersValues.mode.key === 'author' ? 'Сотрудник' : filtersValues.mode.key === 'category' ? 'Объект' : 'Группа работ'}</Th>
                      {filtersValues.date &&
                        [...Array(getDaysInMonth(new Date(filtersValues.date)))].map((_: unknown, idx: number) => (
                          <Th width="20px" key={idx} className="tiny-td">
                            <div>{idx + 1}</div>
                            <div>{filtersValues.date && `${format(new Date(filtersValues.date).setDate(idx + 1), 'EEEEEE', { locale: ru })}`}</div>
                          </Th>
                        ))}
                      <Th textAlign="center">Итог</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {report.results.map((res) => {
                      const entityKey = filtersValues.mode.key === 'author' ? 'author' : filtersValues.mode.key === 'category' ? 'category' : 'group'

                      if (!(entityKey in res)) return <Loader />

                      return (
                        <Tr key={res[entityKey].id}>
                          <Td>
                            {res[entityKey].name}
                            {entityKey === 'author' && ` ${res[entityKey].last_name}`}
                          </Td>
                          {filtersValues.date &&
                            [...Array(getDaysInMonth(new Date(filtersValues.date)))].map((_: unknown, idx: number) => {
                              const currentDay = idx + 1
                              const currentGroup = res.results.find((r) => new Date(r.date).getDate() === currentDay)

                              if (currentGroup) {
                                return (
                                  <Td key={idx}>
                                    <Link
                                      as={ReactLink}
                                      color="blue.400"
                                      fontWeight="bold"
                                      textDecoration="underline"
                                      to={`/documents/${currentGroup.ids}?type=reports`}
                                      _hover={{ color: 'blue.700' }}
                                    >
                                      {currentGroup.quantity.toFixed(1)}
                                    </Link>
                                  </Td>
                                )
                              }

                              return <Td key={idx} />
                            })}

                          <Td textAlign="center" className="tiny-table-last-td">
                            {res.total_quantity.toFixed(1)}
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            ))
          ) : (
            <EmptyResults text="Отчетов по таким параметрам поиска нет" />
          )}
        </Stack>
      </Box>
      <MechanicsSettings isOpen={isSettingsOpen} values={settingsValues} onClose={onSettingsClose} setValues={updateSettingsValues} />
      <MechanicsFilters
        isOpen={isFiltersOpen}
        onClose={onFiltersClose}
        values={filtersValues}
        mechanics={mechanics}
        technicsOptions={technicsOptions}
        updateValues={updateFiltersValues}
      />
    </Stack>
  )
}

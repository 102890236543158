import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, HStack, Stack, Alert, AlertIcon, Box, Text } from '@chakra-ui/react'
import { useForm, NestedValue } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { ModalAction, ShortCategoryAndGroup } from '~/types/common'

import { getPriceRecord } from '~/api/price-records'
import { PriceRecord } from '~/types/price-record'
import { format } from 'date-fns'

interface Props {
  priceRecordData: PriceRecord
  onRestore: (id: number) => void
  onDelete: (id: number) => void
  isOpen: boolean
  error: string
  onClose: () => void
}

type Inputs = {
  category: NestedValue<ShortCategoryAndGroup> | null
  group: NestedValue<ShortCategoryAndGroup> | null
  unit: NestedValue<ShortCategoryAndGroup> | null
  quantity: number | null
  price: string | null
}

export default function PriceRecordModalArchive({ priceRecordData, onRestore, onDelete, isOpen, onClose, error }: Props) {
  const [fullPriceRecord, setFullPriceRecord] = useState<PriceRecord>()
  const [action, setAction] = useState<ModalAction>()

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<Inputs>({
    defaultValues: useMemo(() => {
      return priceRecordData
    }, [priceRecordData])
  })

  const getPriceRecordFullInfo = async () => {
    const res = await getPriceRecord(priceRecordData.id)
    setFullPriceRecord(res)
  }

  const onSubmit = async (prop?: ModalAction) => {
    setAction(prop)

    if (prop === 'update') {
      return onRestore(priceRecordData.id)
    }
    if (prop === 'delete') {
      return onDelete(priceRecordData.id)
    }
  }

  useEffect(() => {
    reset()
  }, [priceRecordData, isOpen])

  useEffect(() => {
    if (isOpen) {
      getPriceRecordFullInfo()
    }
  }, [isOpen])

  useEffect(() => {
    reset(priceRecordData)
  }, [priceRecordData, isOpen])

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
      <ModalOverlay />
      <ModalContent as="form" noValidate>
        <ModalHeader>История изменений</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}

            {fullPriceRecord?.price_history?.length && (
              <Box>
                <Text fontSize="md" fontWeight="600">
                  История изменений
                </Text>
                <Stack>
                  {fullPriceRecord?.price_history.map((price, i, len) => (
                    <Text fontSize="sm" key={i}>
                      {len.length - i}. {format(new Date(price.updated_at), 'dd.MM.yyyy')} {price.old} {'->'} {price.new}
                    </Text>
                  ))}
                </Stack>
              </Box>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack width="full">
            <Text fontSize="md" fontWeight="600">
              Варианты действий
            </Text>
            <HStack w="full" justifyContent="space-between">
              <Button colorScheme="red" mr={3} onClick={handleSubmit(() => onSubmit('delete'))} isLoading={action === 'delete' && isSubmitting}>
                Удалить
              </Button>
              <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('update'))} isLoading={action === 'update' && isSubmitting}>
                Восстановить
              </Button>
            </HStack>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

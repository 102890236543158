import { Stack, FormControl, FormLabel, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, RadioGroup, Radio } from '@chakra-ui/react'
import { ActionMeta, AsyncSelect, Select } from 'chakra-react-select'
import { Group } from '~/types/groups'
import { useEffect, useState } from 'react'
import { GetRecordsGroupsParams } from '~/types/records'
import { getRecordsCategories, getRecordsGroups } from '~/api/records'
import { ObjectType, ReportsMechanicsSettings } from '~/types/reports-mechanics'
import { OBJECT_TYPES } from '~/utils/constants'
import { Category, IdAndNameObject, SelectOption } from '~/types/common'

interface Props {
  isOpen: boolean
  values: ReportsMechanicsSettings
  onClose: () => void
  setValues: (values: ReportsMechanicsSettings) => void
}

export default function MechanicsSettings({ isOpen, values, onClose, setValues }: Props) {
  const [groups, setGroups] = useState<Group[]>([])
  const [categories, setCategories] = useState<Category[]>([])

  const getGroups = async (params: GetRecordsGroupsParams = {}) => {
    const { results } = await getRecordsGroups({ ...params, limit: 1000 })

    setGroups(results)
    return results
  }

  const searchGroups = (inputValue: string, callback: (options: Group[]) => void) => {
    getGroups({ name: inputValue }).then((updated) => {
      callback(updated)
    })
  }

  const getCategories = async () => {
    const { results } = await getRecordsCategories({ limit: 1000 })
    setCategories(results)
  }

  const changeCategory = async (selectAction: ActionMeta<SelectOption>) => {
    if (!values) return

    const category = selectAction.action

    if (category === 'remove-value') {
      const removed = selectAction.removedValue
      const updatedCategories = values.category!.filter((a) => a.id !== removed.id!)
      setValues({ ...values, category: updatedCategories.length ? updatedCategories : null })
    }

    if (category === 'select-option') {
      const newCategory = selectAction.option as IdAndNameObject

      if (newCategory) {
        const updatedCategories = values.category || []
        updatedCategories.push(newCategory)

        setValues({ ...values, category: updatedCategories })
      }
    }
  }

  useEffect(() => {
    // getOrganizatons()
    getGroups()
    getCategories()
  }, [])

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Настройки отчета</DrawerHeader>

        <DrawerBody>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel htmlFor="type">Объект отчета</FormLabel>
              <Select<ObjectType>
                id="objectType"
                isClearable
                value={values.object}
                getOptionValue={(option: ObjectType) => `${option?.key}`}
                getOptionLabel={(option: ObjectType) => `${option?.label}`}
                onChange={(newValue) => setValues({ ...values, object: newValue || null })}
                placeholder="Выберите..."
                options={OBJECT_TYPES}
              />
            </FormControl>

            {values.object?.key === 'technics' && (
              <Stack>
                <FormControl>
                  <FormLabel>Список техники</FormLabel>
                  <RadioGroup onChange={(value: ReportsMechanicsSettings['tehnicsMethod']) => setValues({ ...values, tehnicsMethod: value })} value={values.tehnicsMethod}>
                    <Stack>
                      <Radio value="limits">Задать список через лимиты выбранного объекта</Radio>
                      <Radio value="groups">Задать список вручную через выбор групп</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>

                {values.tehnicsMethod === 'limits' && (
                  <FormControl>
                    <Select
                      id="category"
                      value={values?.category || null}
                      placeholder="Выберите объект"
                      isSearchable
                      isClearable={false}
                      isMulti
                      getOptionValue={(option: SelectOption) => `${option?.id}`}
                      getOptionLabel={(option: SelectOption) => `${option?.name}`}
                      onChange={(newCoauthor, selectAction) => changeCategory(selectAction)}
                      options={categories}
                      chakraStyles={{
                        control: (provided) => ({ ...provided, backgroundColor: 'white' })
                      }}
                      selectedOptionStyle="check"
                    />
                  </FormControl>
                )}

                {values.tehnicsMethod === 'groups' && (
                  <FormControl>
                    <AsyncSelect
                      placeholder="Выберите группу"
                      id="group"
                      isSearchable
                      isClearable
                      isMulti
                      getOptionValue={(option: SelectOption) => `${option.id}`}
                      getOptionLabel={(option: SelectOption) => `${option.name}`}
                      value={values.group}
                      // @ts-expect-error dunno why erroring
                      onChange={(newValue) => setValues({ ...values, group: newValue || null })}
                      defaultOptions={groups}
                      loadOptions={searchGroups}
                    />
                  </FormControl>
                )}
              </Stack>
            )}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

import React from 'react'
import { TableCellProps, Td as ChakraTd } from '@chakra-ui/react'
import { Consumer } from '~/utils/context'

interface ITdInnerProps extends TableCellProps {
  columnKey?: number
  narrowHeaders: Record<number, any>
}

function TdInner({ narrowHeaders, columnKey = 0, children, className, ...rest }: ITdInnerProps) {
  const classes = `${className || ''} pivoted`

  return (
    <ChakraTd data-testid="td" {...rest} className={classes}>
      <div data-testid="td-before" className="tdBefore">
        {narrowHeaders[columnKey]}
      </div>
      {children ?? <div>&nbsp;</div>}
    </ChakraTd>
  )
}

TdInner.defaultProps = {
  columnKey: null
}

export type ITdProps = Omit<ITdInnerProps, 'narrowHeaders'>

export function Td(props: ITdProps) {
  return <Consumer>{(headers) => <TdInner {...props} narrowHeaders={headers} />}</Consumer>
}

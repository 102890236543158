import { FormControl, FormLabel, IconButton, Input, InputGroup, InputRightElement, useDisclosure } from '@chakra-ui/react'
import { useRef, ChangeEvent, useMemo } from 'react'
import { HiEye, HiEyeOff } from 'react-icons/hi'

interface Props {
  setInputPassword: (sym: string) => void
  required: boolean
  initialValue?: string
}

export function PasswordField({ setInputPassword, required, initialValue }: Props) {
  const { isOpen, onToggle } = useDisclosure()
  const inputRef = useRef<HTMLInputElement>(null)

  const onClickReveal = () => {
    onToggle()
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true })
    }
  }

  return (
    <FormControl isRequired={required}>
      <FormLabel htmlFor="password">Пароль</FormLabel>
      <InputGroup>
        <InputRightElement>
          <IconButton color="gray.800" variant="link" aria-label={isOpen ? 'Mask password' : 'Reveal password'} icon={isOpen ? <HiEyeOff /> : <HiEye />} onClick={onClickReveal} />
        </InputRightElement>
        <Input
          id="password"
          value={initialValue ?? ''}
          onInput={(e: ChangeEvent<HTMLInputElement>) => setInputPassword(e.target.value)}
          ref={inputRef}
          name="password"
          type={isOpen ? 'text' : 'password'}
          autoComplete="current-password"
          required={required}
        />
      </InputGroup>
    </FormControl>
  )
}

PasswordField.displayName = 'PasswordField'
PasswordField.defaultProps = {
  initialValue: ''
}

import { IconButton, Flex, HStack, Text, FlexProps, Menu, MenuButton, MenuItem, MenuList, Box } from '@chakra-ui/react'
import { useContext } from 'react'
import { FiMenu, FiUser, FiChevronDown } from 'react-icons/fi'
import { logOut } from '~/api/auth'
import { UserContext } from '~/utils/userContext'

interface MobileProps extends FlexProps {
  onOpen: () => void
}

export default function MobileNav({ onOpen, ...rest }: MobileProps) {
  const user = useContext(UserContext)

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg="gray.900"
      color="white"
      borderBottomWidth="1px"
      borderBottomColor="gray.700"
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton display={{ base: 'flex', md: 'none' }} onClick={onOpen} variant="outline" aria-label="open menu" icon={<FiMenu />} _hover={{ bg: 'grey.500' }} />

      <Text display={{ base: 'flex', md: 'none' }} fontSize="2xl" fontFamily="monospace" fontWeight="bold">
        Megagon
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems="center" gap={5}>
          <Box>
            {user?.name} {user?.last_name}
          </Box>
          <Menu autoSelect={false}>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack display={{ base: 'none', md: 'flex' }}>
                <FiUser />
                <FiChevronDown />
              </HStack>
            </MenuButton>
            <MenuList bg="gray.900" borderColor="gray.700">
              <MenuItem onClick={logOut} _hover={{ bg: 'gray.700' }}>
                Выйти
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { GetReportsObjectByGroupAndClientItem } from '~/types/reports'
import { getNumberWithSpaces } from '~/utils/helpers'
import GroupTypeTotal from '~/components/reports/GroupTypeTotal'
import { GroupType } from '~/types/groups'

interface Props {
  object: GetReportsObjectByGroupAndClientItem
  groupTypes: GroupType[]
  replaceDocFilters: (ids: number[]) => void
  isLimitExceeded: boolean
  isLimitsAndHiglightHidden: boolean
}

export default function FinancialByClientObject({ object, replaceDocFilters, groupTypes, isLimitExceeded, isLimitsAndHiglightHidden }: Props) {
  const { group } = object
  const totalGroupsPrice = object.total_price
  const priceColor = isLimitExceeded && !isLimitsAndHiglightHidden ? 'tomato' : 'black'

  return (
    <AccordionItem key={group.id}>
      <h2>
        <AccordionButton justifyContent="space-between">
          <Stack flex="1" textAlign="left">
            <Text fontSize="md" fontWeight={600}>
              {group.name}
            </Text>
            {!isLimitsAndHiglightHidden && (
              <Text fontSize="small" margin={0}>
                Лимит: {object.limit?.toLocaleString() || '-'}
              </Text>
            )}
          </Stack>
          <GroupTypeTotal groupTypes={groupTypes} objectGroupType={object.group.type} total={getNumberWithSpaces(object.total_price, true)} color={priceColor} />
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        {object.results.map((item) => {
          return (
            <Flex key={item.name} gap={2}>
              <Box>{item.name}</Box>
              <Button
                variant="link"
                color="blue.400"
                fontWeight="bold"
                textDecoration="underline"
                _hover={{ color: 'blue.700' }}
                onClick={() => replaceDocFilters(item.documents_ids)}
              >
                {getNumberWithSpaces(item.price, true)}
              </Button>
            </Flex>
          )
        })}
      </AccordionPanel>
    </AccordionItem>
  )
}

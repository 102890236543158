import { Box, Flex, Text } from '@chakra-ui/react'
import { GroupType, GroupWithType } from '~/types/groups'

interface Props {
  groupTypes: GroupType[]
  total: string | number
  color: string
  objectGroupType: GroupWithType['type']
}

export default function GroupTypeTotal({ groupTypes, objectGroupType, total, color }: Props) {
  const typeOrder = groupTypes.findIndex((v) => {
    const id = `${v.id}` === 'null' ? null : v.id
    return id === objectGroupType
  })

  return (
    <Box>
      {groupTypes.length > 0 ? (
        <Flex justifyContent="flex-end">
          {groupTypes.map((type, index) => (
            <Box key={type.id} width="100px" textAlign="center" py={2}>
              {index === typeOrder && (
                <Text fontSize="sm" marginRight={2} color={color}>
                  {total}
                </Text>
              )}
            </Box>
          ))}
        </Flex>
      ) : (
        <Text fontSize="sm" marginRight={2} color={color}>
          {total}
        </Text>
      )}
    </Box>
  )
}

import { IdAndNameObject, StringSignature } from '~/types/common'
import { FilterValues as FilterValuesReports, FiltersToSendType as FiltersToSendTypeReports } from '~/types/reports'
import { FilterValues as FilterValuesDocs, FiltersToSendType as FiltersToSendTypeDocs, ProofOfWorkFilterValues } from '~/types/docs'
import { User } from '~/types/users'
import { useCallback, useEffect, useState } from 'react'
import dateFormat from 'dateformat'
import { ReportsMechanicsFilters } from '~/types/reports-mechanics'
import { MECHANICS_MODES } from '~/utils/constants'

const FILTER_VALUE_NAME: StringSignature<keyof (FilterValuesDocs & FilterValuesReports)> = {
  date_gte: 'Дата ОТ',
  date_lte: 'Дата ДО',
  date: 'Дата',
  created_at_gte: 'Дата поступления ОТ',
  created_at_lte: 'Дата поступления ДО',
  date_diff_min: 'Дней между датой документа и датой поступления',
  client: 'Получатель',
  supplier: 'Поставщик',
  author: 'Автор',
  record_category: 'Объект',
  executor: 'Подрядчик',
  is_original: 'Оригинал',
  is_closing: 'Закрывающий документ',
  is_processed: 'Обработка завершена',
  group: 'Группа',
  category: 'Категория',
  exclude_category: 'Кроме категории',
  mode: 'Режим',
  technics: 'Техника'
}

const FILTER_RADIO_VALUE_NAME: StringSignature<string> = {
  received: 'Получен',
  not_received: 'Не получен'
}

const isDocsFilters = (filters: FilterValuesDocs | FilterValuesReports | ProofOfWorkFilterValues | ReportsMechanicsFilters): filters is FilterValuesDocs => {
  return 'o' in filters
}

const isDocsFiltersToSend = (filters: FiltersToSendTypeDocs | FiltersToSendTypeReports | ProofOfWorkFilterValues | ReportsMechanicsFilters): filters is FiltersToSendTypeDocs => {
  return 'is_processed' in filters
}

const isReportsFilters = (filters: FilterValuesDocs | FilterValuesReports | ProofOfWorkFilterValues | ReportsMechanicsFilters): filters is FilterValuesReports => {
  return 'own_entity' in filters
}

const isReportsMechanicsFilters = (filters: FilterValuesDocs | FilterValuesReports | ProofOfWorkFilterValues | ReportsMechanicsFilters): filters is ReportsMechanicsFilters => {
  return 'mode' in filters
}

const isDate = (filterKey: string) => {
  const dates = ['date_gte', 'date_lte', 'created_at_gte', 'created_at_lte', 'date']
  return dates.includes(filterKey)
}

const FILTER_KEY_OBJECT = ['client', 'supplier', 'record_category', 'category', 'exclude_category']

export function useFiltersTags<
  FilterV extends FilterValuesDocs | FilterValuesReports | ProofOfWorkFilterValues | ReportsMechanicsFilters,
  FiltersToSendType extends FiltersToSendTypeDocs | FiltersToSendTypeReports | ReportsMechanicsFilters
>(filterValues: FilterV | null, filtersToSend: FiltersToSendType, setFilterValues: (filters: FilterV) => void, showCategory = true) {
  type FilterKey = keyof FilterV
  const [tags, setTags] = useState<FilterKey[]>([])

  const getRadioValue = (filterKey: 'is_original' | 'is_closing' | 'is_processed') => {
    if (!filterValues) return

    if (isDocsFilters(filterValues)) {
      // if (isDocsFilters(filterValues) && isDocsFiltersToSend(filtersToSend)) {
      if (filterKey === 'is_processed' && 'is_processed' in filtersToSend) {
        return filtersToSend.is_processed ? 'Да' : 'Нет'
      }
      return FILTER_RADIO_VALUE_NAME[filterValues[filterKey]]
    }
  }

  const getTagLabel = (filterKey: FilterKey) => {
    if (!filterValues || filterValues[filterKey] === null) return

    let value = ''

    if (FILTER_KEY_OBJECT.includes(filterKey as string)) {
      if (isDocsFilters(filterValues)) {
        value = (filterValues[filterKey] as IdAndNameObject).name
      }

      if (isReportsFilters(filterValues)) {
        value = (filterValues[filterKey] as IdAndNameObject[]).map((i) => i?.name).join(', ')
      }
    }

    if (filterKey === 'author') {
      if (Array.isArray(filterValues.author) && filterValues.author?.length) {
        value = filterValues.author!.map((g) => `${g.name} ${g.last_name}`).join(', ')
      } else if (!Array.isArray(filterValues.author) && filterValues.author) {
        value = `${filterValues.author.name} ${filterValues.author.last_name}`
      }
    }

    if (filterKey === 'group') {
      if (isReportsFilters(filterValues)) {
        value = filterValues.group!.map((g) => g.name).join(', ')
      }
    }

    if (filterKey === 'mode' && isReportsMechanicsFilters(filterValues)) {
      value = filterValues.mode.label
    }

    if (filterKey === 'technics' && isReportsMechanicsFilters(filterValues)) {
      value = filterValues.technics?.name || ''
    }

    // if (filterKey === 'record_category') {
    //   if (isReportsFilters(filterValues)) {
    //     value = filterValues[filterKey]
    //   }
    // }

    if (filterKey === 'is_original' || filterKey === 'is_closing' || filterKey === 'is_processed') {
      value = getRadioValue(filterKey)!
    } else if (isDocsFilters(filterValues) && filterKey === 'author') {
      value = (filterValues[filterKey] as User[])?.map((a) => `${a.name} ${a.last_name}`).join(', ')
    } else if (isDate(filterKey as string)) {
      value = dateFormat(filterValues[filterKey] as unknown as string, 'yyyy-mm-dd')
    } else if (!value) {
      value = filterValues[filterKey] as unknown as string
    }

    return `${FILTER_VALUE_NAME[filterKey as keyof (FilterValuesDocs & FilterValuesReports)]}: ${value}`
  }

  const removeFilter = (filterKey: FilterKey) => {
    if (!filterValues) return

    Object.keys(filterValues).forEach((key) => {
      if (key === filterKey && (filterKey === 'is_original' || key === 'is_closing')) {
        setFilterValues({ ...filterValues, [key]: 'not_defined' })
        return
      }
      if (key === filterKey) {
        setFilterValues({ ...filterValues, [key]: null })
      }
    })
  }

  const isFilterSelected = (filterKey: FilterKey) => {
    if (filterKey === 'o' || (filterKey === 'category' && !showCategory) || !filterValues) return false

    if (isDocsFilters(filterValues) && (filterKey === 'is_original' || filterKey === 'is_closing')) {
      return filterValues[filterKey] !== 'not_defined'
    }

    return filterValues[filterKey]
  }

  useEffect(() => {
    if (!filtersToSend) {
      setTags([])
    } else {
      setTags([...Object.keys(filtersToSend).filter((key) => isFilterSelected(key as keyof FilterV))] as (keyof FilterV)[])
    }
  }, [filtersToSend])

  const resetFilters = useCallback(
    (defaultFilterValues: FilterV) => {
      setFilterValues(defaultFilterValues)
    },
    [setFilterValues]
  )

  return [getTagLabel, removeFilter, resetFilters, tags] as const
}

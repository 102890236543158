import React from 'react'
import { Box, Checkbox, FormControl, FormErrorMessage, FormLabel, HStack, IconButton, Input, Stack } from '@chakra-ui/react'
import { Control, Controller, FieldArrayWithId, FieldErrors, UseFieldArrayRemove } from 'react-hook-form'
import { AsyncSelect } from 'chakra-react-select'
import { SelectOption } from '~/types/common'
import { LimitFields } from '~/types/limits'
import { RecordUnit } from '~/types/records'
import { BiMinus } from 'react-icons/bi'

interface Props {
  control: Control<LimitFields>
  errors: FieldErrors
  units: RecordUnit[]
  index: number
  onRemove: (index: number) => void
  isOnlyItem: boolean
  onIgnoreToggle: (status: boolean) => void
}

export default function LimitItem({ control, errors, units, index, onRemove, isOnlyItem, onIgnoreToggle }: Props) {
  return (
    <Stack>
      <HStack>
        <FormControl isInvalid={!!errors?.items && errors?.items[index]?.unit} isRequired>
          <FormLabel htmlFor="unit">Ед. изм</FormLabel>
          <Controller
            name={`items.${index}.unit`}
            control={control}
            rules={{ required: 'Это поле обязательно' }}
            render={({ field }) => (
              <AsyncSelect<SelectOption>
                placeholder="Введите..."
                id="unit"
                ref={field.ref}
                getOptionValue={(option: SelectOption) => `${option.id}`}
                getOptionLabel={(option: SelectOption) => `${option.name}`}
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue)
                }}
                defaultOptions={units}
                isSearchable
              />
            )}
          />

          <FormErrorMessage>{!!errors?.items && !!errors?.items[index]?.unit && errors?.items[index]?.unit?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.items && !!errors?.items[index]?.amount} isRequired>
          <FormLabel htmlFor="amount">Количество</FormLabel>

          <Controller
            name={`items.${index}.amount`}
            control={control}
            rules={{
              required: 'Это поле обязательно'
            }}
            render={({ field }) => (
              <Input
                placeholder="Введите..."
                value={field.value ? `${field.value}` : ''}
                onInput={(e) => {
                  return field.onChange(e.currentTarget.value.replace(',', '.').replace(/[^\d.-]/g, ''))
                }}
              />
            )}
          />

          <FormErrorMessage>{!!errors?.items && !!errors?.items[index]?.amount && errors?.items[index]?.amount?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.items && !!errors?.items[index]?.price} isRequired>
          <FormLabel htmlFor="price">Цена</FormLabel>
          <Controller
            name={`items.${index}.price`}
            control={control}
            rules={{
              required: 'Это поле обязательно',
              min: {
                value: 0,
                message: 'Не должно быть меньше нуля'
              }
            }}
            render={({ field }) => (
              <Input
                id="price"
                placeholder="Введите..."
                value={field.value ? `${field.value}` : ''}
                onInput={(e) => {
                  return field.onChange(e.currentTarget.value.replace(',', '.').replace(/[^\d.-]/g, ''))
                }}
              />
            )}
          />

          <FormErrorMessage>{!!errors?.items && !!errors?.items[index]?.price && errors?.items[index]?.price?.message}</FormErrorMessage>
        </FormControl>

        {/* <IconButton alignSelf="flex-end" colorScheme="blue" aria-label="Delete" icon={<BiMinus />} onClick={() => onRemove(index)} disabled={isOnlyItem} /> */}
      </HStack>

      {/* <FormControl> */}
      {/*  <Controller */}
      {/*    name={`items.${index}.is_ignored`} */}
      {/*    control={control} */}
      {/*    render={({ field }) => ( */}
      {/*      <Checkbox */}
      {/*        isChecked={!!field.value} */}
      {/*        onChange={(event) => { */}
      {/*          const status = event.target.checked */}
      {/*          onIgnoreToggle(status) */}
      {/*          return field.onChange(status) */}
      {/*        }} */}
      {/*      > */}
      {/*        Не учитывать при определении стоимости лимита (контроль только количества) */}
      {/*      </Checkbox> */}
      {/*    )} */}
      {/*  /> */}
      {/* </FormControl> */}
    </Stack>
  )
}

import { Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react'
import { useRef } from 'react'

interface Props {
  alertHeader: string
  alertBody: string
  actionConfirmed: () => void
  isOpen: boolean
  onClose: () => void
}

export default function AlertDialogComponent({ alertHeader, alertBody, actionConfirmed, isOpen, onClose }: Props) {
  const cancelRef = useRef() as React.RefObject<HTMLButtonElement>

  const confirmAction = () => {
    actionConfirmed()
    onClose()
  }

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {alertHeader}
          </AlertDialogHeader>

          <AlertDialogBody>{alertBody}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Отмена
            </Button>
            <Button colorScheme="red" onClick={confirmAction} ml={3}>
              Удалить
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

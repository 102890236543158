import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Heading, Stack, HStack, Button, Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiLink, FiCheckCircle } from 'react-icons/fi'
import { createRecord as createRecordApi, getRecords as getRecordsApi, deleteRecord as deleteRecordApi, updateRecord as updateRecordApi } from '~/api/records'
import { NewRecord, Record as RecordType } from '~/types/records'
import { getNumberWithSpaces, getSum } from '~/utils/helpers'
import { useOpenRecordModal } from '~/hooks/useOpenRecordModal'
import { getLimitsItem } from '~/api/limits'
import RecordModal from './RecordModal'

interface Props {
  docId: number
  totalPrice: number
  supplierId: number | null
  onRecordsChange: (records: RecordType[]) => void
}

export default function Records({ docId, totalPrice, onRecordsChange, supplierId }: Props) {
  const [records, setRecords] = useState<RecordType[]>([])
  const [limitsWithRealNames, setLimitsWithRealNames] = useState<Record<number, string>>({})
  const [isOpen, onClose, record, isCreate, openToCreate, openToUpdate, closeModal] = useOpenRecordModal()

  const getGroupLimitsRealNames = async (ids: number[]) => {
    const limitsRealNames: Record<number, string> = {}

    const fullLimits = await Promise.all(ids.map(getLimitsItem))

    fullLimits.forEach((limit) => {
      limitsRealNames[limit.id] = limit.real_name || limit.name || 'Unknown Limit'
    })

    setLimitsWithRealNames(limitsRealNames)
  }

  const getRecords = async () => {
    const { results } = await getRecordsApi({ document: docId })
    setRecords(results)
    onRecordsChange(results)

    const groupLimitsIds = results.map((r) => r.group_limit?.id).filter(Boolean) as number[]
    getGroupLimitsRealNames(groupLimitsIds)
  }

  const deleteRecord = async (recordId: number) => {
    await deleteRecordApi(recordId)
    getRecords()
    onClose()
  }

  const updateRecord = async (recordId: number, r: NewRecord) => {
    await updateRecordApi(recordId, r)
    getRecords()
    onClose()
  }

  const createRecord = async (r: NewRecord) => {
    await createRecordApi(r)
    getRecords()
    onClose()
  }

  useEffect(() => {
    getRecords()
  }, [])

  return (
    <Stack spacing={3}>
      <HStack justifyContent="space-between">
        <Heading as="h3" size="sm">
          Учёт
        </Heading>
        <Button colorScheme="blue" onClick={openToCreate} isDisabled={!supplierId}>
          Добавить
        </Button>
      </HStack>
      <TableContainer>
        <Table variant="simple" colorScheme="blackAlpha">
          <Thead>
            <Tr>
              <Th textAlign="center">#</Th>
              <Th>Получатель</Th>
              <Th>Категория / Объект</Th>
              <Th>Группа</Th>
              <Th>Ед. изм</Th>
              <Th>Количество</Th>
              <Th>Цена</Th>
              <Th>Стоимость</Th>
              <Th>
                <FiLink />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {records.map((r, idx) => (
              <Tr key={r.id}>
                <Td>
                  <Button colorScheme="blue" fontWeight="bold" variant="link" onClick={() => openToUpdate(r)}>
                    {idx + 1}
                  </Button>
                </Td>
                <Td>{r.client!.name}</Td>
                <Td>{r.category!.name}</Td>
                <Td>
                  {r.group!.name}
                  {r.group_limit?.id && <Box sx={{ fontSize: '12px' }}>{limitsWithRealNames[r.group_limit.id]}</Box>}
                </Td>
                <Td>{r.unit!.name}</Td>
                <Td>{r.quantity}</Td>
                <Td>{r.price}</Td>
                <Td>{getNumberWithSpaces(getSum(r.quantity!, r.price!), true)}</Td>
                <Td>{r.subclient ? <FiCheckCircle color="green" /> : ''}</Td>
              </Tr>
            ))}
            <Tr>
              <Td />
              <Td />
              <Td />
              <Td />
              <Td />
              <Td />
              <Td />
              <Td>Ит.: {getNumberWithSpaces(totalPrice, true)}</Td>
              <Td />
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <RecordModal
        onClose={closeModal}
        isOpen={isOpen}
        onDelete={deleteRecord}
        onUpdate={updateRecord}
        onCreate={createRecord}
        isCreate={isCreate}
        record={record}
        docId={docId}
        supplierId={supplierId}
        isRecordsSet={false}
      />
    </Stack>
  )
}

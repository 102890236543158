import { useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { Record } from '~/types/records'

const defaultRecord = {
  id: 0,
  document: 0,
  category: null,
  client: null,
  subclient: null,
  group: null,
  group_limit: null,
  unit: null,
  quantity: null,
  price: null
}

export function useOpenRecordModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isCreate, setIsCreate] = useState(false)
  const [record, setRecord] = useState<Record>(defaultRecord)

  const openToCreate = async () => {
    setIsCreate(true)
    setRecord(defaultRecord)
    onOpen()
  }

  const openToUpdate = async (r: Record) => {
    setIsCreate(false)
    setRecord(r)
    onOpen()
  }

  const closeModal = async () => {
    setRecord(defaultRecord)
    onClose()
  }

  return [isOpen, onClose, record, isCreate, openToCreate, openToUpdate, closeModal] as const
}

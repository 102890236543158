import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Input,
  useDisclosure
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { DeleteRecordGroupBody, ShortRecordGroup } from '~/types/records'
import { Category, CRUDLoadingState, ModalAction, SelectTypeOption } from '~/types/common'
import { Group, GroupType } from '~/types/groups'
import { Select } from 'chakra-react-select'
import { GROUP_TYPES } from '~/utils/constants'
import GroupDeleteModal from '~/components/references/GroupDeleteModal'

interface Props {
  groupData: Group
  categories: Category[]
  selectedCategory: Category
  isCreate: boolean
  onUpdate: (id: number, newUnit: ShortRecordGroup) => void
  onDelete: (body: DeleteRecordGroupBody) => void
  onCreate: (newUnit: ShortRecordGroup) => void
  isOpen: boolean
  onClose: () => void
  loadingState: CRUDLoadingState
}

export type GroupModalInputs = {
  category: Category
  name: string
  type: GroupType | null
}

export default function GroupModal({ groupData, selectedCategory, isCreate, onUpdate, onDelete, onCreate, isOpen, onClose, loadingState }: Props) {
  const [action, setAction] = useState<ModalAction>()
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()

  const initialType = useMemo(() => {
    return GROUP_TYPES.find((t) => t.id === groupData.type) || GROUP_TYPES[0]
  }, [groupData.type])

  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitting },
    setValue
  } = useForm<GroupModalInputs>({
    defaultValues: useMemo(() => {
      return {
        category: selectedCategory,
        name: groupData.name,
        type: initialType
      }
    }, [groupData, selectedCategory, initialType])
  })

  const onSubmit = async (prop?: ModalAction) => {
    setAction(prop)

    if (prop === 'create') {
      return onCreate({ ...getValues(), category: getValues().category.id, type: getValues().type?.id || null })
    }
    if (prop === 'update') {
      return onUpdate(groupData.id!, { ...getValues(), category: getValues().category.id, type: getValues().type?.id || null })
    }
    if (prop === 'delete') {
      return onDeleteOpen()
    }
  }

  const onDeleteGroup = async (body: Omit<DeleteRecordGroupBody, 'id'>) => {
    return onDelete({ id: groupData.id!, ...body })
  }

  useEffect(() => {
    reset({
      category: selectedCategory,
      name: groupData.name
    })

    if (initialType) {
      setValue('type', initialType)
    }
  }, [groupData, isOpen])

  return (
    <>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
        <ModalOverlay />
        <ModalContent as="form" noValidate>
          <ModalHeader>{isCreate ? 'Добавить' : 'Изменить'} Группу</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <FormControl isInvalid={!!errors?.name?.message} isRequired>
                <FormLabel htmlFor="iin">Наименование</FormLabel>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: 'Это поле обязательно'
                  }}
                  render={({ field }) => <Input placeholder="Введите..." id="name" {...field} />}
                />
                <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="type">Категория группы</FormLabel>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Select<SelectTypeOption>
                      value={field.value}
                      getOptionValue={(option: SelectTypeOption) => `${option?.id}`}
                      getOptionLabel={(option: SelectTypeOption) => `${option?.name}`}
                      onChange={(newValue) => {
                        field.onChange(newValue)
                      }}
                      placeholder="Выберите..."
                      options={GROUP_TYPES}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            {isCreate ? (
              <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('create'))} isLoading={loadingState.isCreating}>
                Сохранить
              </Button>
            ) : (
              <HStack w="full" justifyContent="space-between">
                <Button colorScheme="red" mr={3} onClick={handleSubmit(() => onSubmit('delete'))} isLoading={action === 'delete' && loadingState.isDeleting}>
                  Удалить
                </Button>
                <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('update'))} isLoading={action === 'update' && loadingState.isUpdating}>
                  Сохранить
                </Button>
              </HStack>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <GroupDeleteModal isOpen={isDeleteOpen} onClose={onDeleteClose} onSubmit={onDeleteGroup} />
    </>
  )
}

import { Stack, FormControl, FormLabel, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Radio, Input, Switch } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { SelectTypeOption } from '~/types/common'

import { LimitsFilters } from '~/types/limits'
import { GROUP_TYPES } from '~/utils/constants'
import { ChangeEvent, useEffect, useState } from 'react'
import { updateUsersFilters } from '~/api/users'
import { UsersFilters } from '~/types/users'

interface Props {
  isFilterOpen: boolean
  filterValues: LimitsFilters
  userFilters: UsersFilters
  setFilterClose: (status: boolean) => void
  setFilterValues: (filters: LimitsFilters) => void
  onRefresh: () => void
}

export default function LimitsFiltersSidebar({ isFilterOpen, filterValues, userFilters, setFilterClose, setFilterValues, onRefresh }: Props) {
  const fetchedUserPlannedTakings = userFilters?.references_filters?.plannedTakings
  const isCustomTakingsChecked = userFilters?.references_filters?.isChecked

  const [isChecked, setIsChecked] = useState(isCustomTakingsChecked)
  const [customPlannedTakings, setCustomPlannedTakings] = useState(fetchedUserPlannedTakings)

  useEffect(() => {
    setIsChecked(isCustomTakingsChecked)
    setCustomPlannedTakings(fetchedUserPlannedTakings)
  }, [fetchedUserPlannedTakings, isCustomTakingsChecked])

  const onClose = async () => {
    if (fetchedUserPlannedTakings !== customPlannedTakings || isChecked !== fetchedUserPlannedTakings) {
      await updateUsersFilters({ ...userFilters, references_filters: { isChecked, plannedTakings: customPlannedTakings || null } })
      onRefresh()
    }

    setFilterClose(true)
  }

  return (
    <Drawer isOpen={isFilterOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Выберите фильтр</DrawerHeader>

        <DrawerBody>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel htmlFor="group_type">Категория группы</FormLabel>
              <Select<SelectTypeOption>
                id="group_type"
                isClearable
                value={filterValues.group_type}
                getOptionValue={(option: SelectTypeOption) => `${option?.id}`}
                getOptionLabel={(option: SelectTypeOption) => `${option?.name}`}
                // @ts-expect-error TODO
                onChange={(newValue) => setFilterValues({ ...filterValues, group_type: newValue || null })}
                placeholder="Выберите..."
                options={GROUP_TYPES}
              />
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="customPlannedTakings" mb="0">
                Задать плановую выручку
              </FormLabel>
              <Switch id="customPlannedTakings" isChecked={isChecked} onChange={(event: ChangeEvent<HTMLInputElement>) => setIsChecked(event.target.checked)} />
            </FormControl>

            {isChecked && (
              <FormControl>
                <Input
                  bg="white"
                  type="number"
                  placeholder="Введите плановую выручку..."
                  value={customPlannedTakings || undefined}
                  onInput={(e: ChangeEvent<HTMLInputElement>) => setCustomPlannedTakings(+e.target.value)}
                />
              </FormControl>
            )}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, HStack, FormControl, Stack, Checkbox, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { DeleteRecordGroupBody } from '~/types/records'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (body: Omit<DeleteRecordGroupBody, 'id'>) => void
}

export default function GroupDeleteModal({ isOpen, onClose, onSubmit }: Props) {
  const [isWithLimits, setIsWithLimits] = useState(false)
  const [isWithRecords, setIsWithRecords] = useState(false)

  const handleSubmit = async () => {
    onSubmit({ with_limits: isWithLimits, with_records: isWithRecords })
    onClose()
  }

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
      <ModalOverlay />

      <ModalContent as="form" noValidate>
        <ModalHeader>Удаление группы</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <FormControl>
              <Checkbox isChecked={isWithLimits} onChange={(event) => setIsWithLimits(event.target.checked)}>
                Удалить связанные с группой лимиты
              </Checkbox>
            </FormControl>
            <FormControl>
              <Checkbox isChecked={isWithRecords} onChange={(event) => setIsWithRecords(event.target.checked)}>
                Удалить связанные с группой позиции учета во всех документах
              </Checkbox>
            </FormControl>
          </Stack>

          {(isWithLimits || isWithRecords) && (
            <Text fontSize="xs" color="tomato" mt={3}>
              Восстановить выбранные данные после удаления будет невозоможно
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack w="full" justifyContent="space-between">
            <Button colorScheme="red" mr={3} onClick={handleSubmit}>
              Подтвердить
            </Button>
            <Button colorScheme="gray" onClick={onClose}>
              Отмена
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { Accordion, Box, Button, Checkbox, Flex, FormControl, FormLabel, Heading, HStack, IconButton, Stack, Tag, TagCloseButton, TagLabel, useDisclosure } from '@chakra-ui/react'
import { ActionMeta, Select } from 'chakra-react-select'
import dateFormat from 'dateformat'
import { useEffect, useMemo, useState } from 'react'
import { FaUndo } from 'react-icons/fa'
import { FiFilter } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { getRecordsCategories } from '~/api/records'
import {
  getReportsObject,
  getReportsObjectByClient,
  getReportsObjectByGroupAndClient,
  getReportsObjectByLimits,
  getReportsObjectByQuantity,
  getReportsObjectBySupplier
} from '~/api/reports'
import EmptyResults from '~/components/EmptyResults'
import Loader from '~/components/Loader'
import ClientObject from '~/components/reports/objects/ClientObject'
import FinancialByClientObject from '~/components/reports/objects/FinancialByClientObject'
import FinancialObject from '~/components/reports/objects/FinancialObject'
import QuantityObject from '~/components/reports/objects/QuantityObject'
import SupplierObject from '~/components/reports/objects/SupplierObject'
import ObjectsFilters from '~/components/reports/ObjectsFilters'
import TotalCount from '~/components/TotalCount'
import { useFiltersTags } from '~/hooks/useFiltersTags'
import { Category, SelectOption } from '~/types/common'
import { GroupType, GroupTypesKey, GroupTypesSignature } from '~/types/groups'
import {
  FiltersToSendType,
  FiltersToSendType as FiltersToSendTypeReports,
  FilterValues,
  GetReportsObjectByClientItem,
  GetReportsObjectByGroupAndClientItem,
  GetReportsObjectByQuantityItem,
  GetReportsObjectBySupplierItem,
  ReportByResponse,
  ReportObject
} from '~/types/reports'
import { BASE_CATEGORY_NAME, GROUP_TYPES_LABEL } from '~/utils/constants'
import { addOneDay } from '~/utils/helpers'
import { localStorageGetValue, localStorageRemoveValue, localStorageSetValue } from '~/utils/localStorage'
import ExludedListModal from '~/components/reports/objects/ExludedListModal'

const REPORT_MODES = [
  {
    key: 'financial',
    label: 'Финансовый'
  },
  {
    key: 'by-quantity',
    label: 'Количественный'
  },
  {
    key: 'by-supplier',
    label: 'По поставщику'
  },
  {
    key: 'by-client',
    label: 'По получателю'
  },
  {
    key: 'financial-by-client',
    label: 'Финансовый (по получателю)'
  }
] as const

type ReportMode = typeof REPORT_MODES[number]

export default function Objects() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [categories, setCategories] = useState<Category[]>([])
  const [listCategories, setListCategories] = useState<Category[]>([])

  const defaultFilters = {
    category: null,
    exclude_category: null,
    group: null,
    client: null,
    supplier: null,
    group_type: null,
    date_gte: null,
    date_lte: null,
    created_at_gte: null,
    created_at_lte: null,
    date_diff_min: null,
    own_entity: true,
    split_by_group_type: true
  }

  const [reportMode, setReportMode] = useState<ReportMode>(REPORT_MODES[0])
  const [objects, setObjects] = useState<ReportByResponse | null>(null)
  const [filterValues, setFilterValues] = useState<FilterValues | null>(null)
  const [filtersToSend, setFiltersToSend] = useState<FiltersToSendTypeReports>({} as FiltersToSendTypeReports)
  const [isLocalStorageChecked, setIsLocalStorageChecked] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isFetchingReports, setIsFetchingReports] = useState(false)
  const [isExcludeCategory, setIsExcludeCategory] = useState(false)
  const [isGroupLimits, setIsGroupLimits] = useState(false)
  const [totalGroupPrice, setTotalGroupPrice] = useState(0)
  const [totalGroupTakings, setTotalGroupTakings] = useState(0)
  const [groupTypeTotalPrice, setGroupTypeTotalPrice] = useState<GroupTypesSignature<number>>({})
  const [documentsCount, setDocumentsCount] = useState(0)
  const [reportId, setReportId] = useState<number | null>(null)
  const navigate = useNavigate()

  const { isOpen: isListOpen, onToggle: onToggleList } = useDisclosure()

  const [getTagLabel, removeFilter, resetFilters, tags] = useFiltersTags<FilterValues, FiltersToSendTypeReports>(filterValues, filtersToSend, setFilterValues, true)

  const getCategories = async () => {
    const { results } = await getRecordsCategories({ limit: 1000 })
    setCategories(results)
    setListCategories(results)
  }

  const categoryKey = isExcludeCategory ? 'exclude_category' : 'category'
  const removeCategoryKey = !isExcludeCategory ? 'exclude_category' : 'category'

  const onSaveList = (newExludedCategories: Category[]) => {
    // @ts-expect-error category exclude_category
    setFilterValues({ ...filterValues, category: newExludedCategories.length ? newExludedCategories : null })
    onToggleList()
  }

  const selectedCategoriesToSend = useMemo(() => {
    if (!categoryKey) return
    const base = categories.find((cat) => cat.name === BASE_CATEGORY_NAME)

    if (filterValues && filterValues.category) {
      if (filterValues.category!.length) {
        const res = filterValues.category!.map((c) => c?.id)

        if (base?.id) {
          res.push(base.id)
        }
        return res.join(',')
      }
    }
    return base ? `${base.id}` : undefined
  }, [filterValues, categories])

  const getReportFunction = () => {
    let func

    if (reportMode.key === 'by-quantity') {
      func = getReportsObjectByQuantity
    } else if (reportMode.key === 'by-client') {
      func = getReportsObjectByClient
    } else if (reportMode.key === 'by-supplier') {
      func = getReportsObjectBySupplier
    } else if (reportMode.key === 'financial-by-client') {
      func = getReportsObjectByGroupAndClient
    } else if (isGroupLimits) {
      func = getReportsObjectByLimits
    } else {
      func = getReportsObject
    }

    return func
  }

  const isFinancialByClientReport = (results: ReportByResponse): results is GetReportsObjectByGroupAndClientItem[] => {
    return reportMode.key === 'financial-by-client'
  }

  const isClientReport = (results: ReportByResponse): results is GetReportsObjectByClientItem[] => {
    return reportMode.key === 'by-client'
  }

  const isQuantityReport = (results: ReportByResponse): results is GetReportsObjectByQuantityItem[] => {
    return reportMode.key === 'by-quantity'
  }

  const isSupplierReport = (results: ReportByResponse): results is GetReportsObjectBySupplierItem[] => {
    return reportMode.key === 'by-supplier'
  }

  const getTotal = (results: ReportByResponse) => {
    let total = 0

    if (isQuantityReport(results)) {
      total = results.reduce((acc, cur) => acc + cur.total_price, 0)
    } else if (isSupplierReport(results)) {
      total = results.reduce((acc, cur) => acc + cur.total_price, 0)
    } else if (isClientReport(results)) {
      total = results.reduce((acc, cur) => acc + cur.total_price, 0)
    } else if (isFinancialByClientReport(results)) {
      total = results.reduce((acc, cur) => acc + cur.total_price, 0)
    } else {
      total = results.reduce((acc, cur) => acc + cur.total_price, 0)
    }

    return total
  }

  const isTagsHidden =
    tags.length === 1 && filterValues?.category && filterValues?.category.length === 1 && filterValues?.category[0] && filterValues.category[0].name === BASE_CATEGORY_NAME

  const getDocCount = (results: ReportByResponse) => {
    // todo: remove optional cur?.documents_num when back is ready
    let total = 0

    if (isQuantityReport(results)) {
      total = results.reduce((prev, cur) => prev + (cur?.documents_num || 0), 0)
    } else if (isSupplierReport(results)) {
      total = results.reduce((prev, cur) => prev + (cur?.documents_num || 0), 0)
    } else if (isClientReport(results)) {
      total = results.reduce((prev, cur) => prev + (cur?.documents_num || 0), 0)
    } else if (isFinancialByClientReport(results)) {
      total = results.reduce((prev, cur) => prev + (cur?.documents_num || 0), 0)
    } else {
      total = results.reduce((prev, cur) => prev + (cur?.documents_num || 0), 0)
    }

    return total
  }

  const getReports = async () => {
    if (!filterValues) return

    setIsFetchingReports(true)

    const getReport = getReportFunction()

    const { results } = await getReport({ ...filtersToSend, limit: 100, [categoryKey]: selectedCategoriesToSend, [removeCategoryKey]: undefined })

    setReportId(results[0]?.report_id || null)

    let categoriesTakings: Category[] = []

    const categoryIds = new Set()

    results.forEach((r) => {
      r.category_ids.forEach((id) => categoryIds.add(id))
    })

    const { results: catRes } = await getRecordsCategories({ id: [...categoryIds].join(','), limit: 1000 })
    setListCategories(catRes)
    categoriesTakings = catRes

    const totalPrice = getTotal(results)
    const totalTakings =
      categoriesTakings.reduce((prev, b) => {
        return +prev + +(b.takings || 0)
      }, 0) || 0

    const count = getDocCount(results)

    setTotalGroupPrice(totalPrice)
    setTotalGroupTakings(totalTakings)
    setObjects(results)
    setDocumentsCount(count)
    setIsFetchingReports(false)
  }

  const replaceDocFilters = (ids: number[]) => {
    localStorageRemoveValue('filters')
    navigate(`/documents/${ids}?type=reports`)
  }

  const navigateByReportId = () => {
    if (!reportId) return
    localStorageRemoveValue('filters')
    navigate(`/documents/0?reportId=${reportId}&type=reports`)
  }

  useEffect(() => {
    if (!categories.length) return

    const filtersFromStorage = localStorageGetValue<FilterValues>('object-filters')
    const modeFromStorage = localStorageGetValue<ReportMode>('object-reports-mode')
    const isExcludeCategoryFromStorage = localStorageGetValue<ReportMode>('object-exclude-category')

    if (filtersFromStorage) {
      setFilterValues(filtersFromStorage)
    } else {
      setFilterValues({ ...defaultFilters, category: null, exclude_category: null })
    }

    setIsExcludeCategory(!!isExcludeCategoryFromStorage)

    if (modeFromStorage) {
      setReportMode(modeFromStorage)
    }
  }, [categories])

  // ecли свитчер true (активный) - то не отправляю own_entity, если false (не активный) - отправлять false
  const getOwnEntityFilterValue = (value: boolean) => {
    return value ? null : false
  }

  const getDateData = (value: Date | null) => {
    if (!value) return null
    return dateFormat(value, 'yyyy-mm-dd')
  }

  const earliestDate = useMemo(() => {
    let date = ''
    let ids = [] as number[]

    if (!objects)
      return {
        date,
        ids
      }

    objects.forEach((o) => {
      if (!date || o.earliest_date.date < date) {
        date = o.earliest_date.date
        ids = o.earliest_date.documents_ids
      }
    })

    return {
      date,
      ids
    }
  }, [objects])

  const latestDate = useMemo(() => {
    let date = ''
    let ids = [] as number[]

    if (!objects)
      return {
        date,
        ids
      }

    objects.forEach((o) => {
      if (o.latest_date.date > date) {
        date = o.latest_date.date
        ids = o.latest_date.documents_ids
      }
    })

    return {
      date,
      ids
    }
  }, [objects])

  const changeReportMode = (newMode: ReportMode) => {
    setLoading(true)

    localStorageSetValue('object-reports-mode', newMode)
    setReportMode(newMode)
  }

  useEffect(() => {
    if (isLocalStorageChecked && !isFetchingReports) {
      getReports()
    }
  }, [filtersToSend, reportMode, isLocalStorageChecked, isExcludeCategory, isGroupLimits])

  useEffect(() => {
    if (!filterValues) return

    setIsLocalStorageChecked(true)

    const groupType = filterValues?.group_type === null ? undefined : filterValues?.group_type?.id === null ? 'null' : filterValues?.group_type?.id

    const transformedFilters: FiltersToSendType = {
      supplier: filterValues.supplier?.map((s) => s.id).join(',') || null,
      group: filterValues.group?.map((s) => s.id).join(',') || null,
      client: filterValues.client?.map((s) => s.id).join(',') || null,
      author: filterValues.author?.map((s) => s.id).join(',') || null,
      date_diff_min: filterValues.date_diff_min,
      group_type: groupType,
      date_gte: getDateData(filterValues.date_gte),
      date_lte: getDateData(filterValues.date_lte),
      created_at_gte: getDateData(filterValues.created_at_gte),
      created_at_lte: getDateData(addOneDay(filterValues.created_at_lte)),
      own_entity: getOwnEntityFilterValue(filterValues.own_entity)
    }

    Object.keys(transformedFilters).forEach((key) => {
      if (transformedFilters[key] === null && key !== 'category' && key !== 'exclude_category') {
        delete transformedFilters[key]
      }
    })

    setFiltersToSend({ ...transformedFilters, [categoryKey]: selectedCategoriesToSend, [removeCategoryKey]: null })
    localStorageSetValue('object-filters', filterValues)
  }, [filterValues, isLocalStorageChecked, selectedCategoriesToSend])

  useEffect(() => {
    setLoading(true)

    getCategories()
  }, [])

  useEffect(() => {
    if (!objects) return
    setLoading(false)
  }, [objects])

  const changeCategory = async (selectAction: ActionMeta<SelectOption>) => {
    if (!filterValues) return

    const category = selectAction.action

    if (category === 'remove-value') {
      const removed = selectAction.removedValue
      const updatedCategories = filterValues.category!.filter((a) => a.id !== removed.id!)
      setFilterValues({ ...filterValues, category: updatedCategories.length ? updatedCategories : null })
    }

    if (category === 'select-option') {
      const newCategory = selectAction.option as Category

      if (newCategory) {
        const updatedCategories = filterValues.category || []
        updatedCategories.push(newCategory)

        setFilterValues({ ...filterValues, category: updatedCategories })
      }
    }
  }

  const groupTypes = useMemo((): GroupType[] => {
    if (!objects || !filterValues) return []
    if (!filterValues.split_by_group_type || isSupplierReport(objects) || isClientReport(objects)) {
      setGroupTypeTotalPrice({})
      return []
    }

    const groupTypesTotal: GroupTypesSignature<number> = {}

    const types = new Set<GroupTypesKey>()

    objects.forEach((o) => {
      if (!isSupplierReport(objects) && !isClientReport(objects) && o.group) {
        if (o.group.type) {
          types.add(o.group.type)

          groupTypesTotal[o.group.type] = groupTypesTotal[o.group.type] ? groupTypesTotal[o.group.type]! + o.total_price : o.total_price
        } else {
          groupTypesTotal.null = groupTypesTotal.null ? groupTypesTotal.null + o.total_price : o.total_price
          types.add('null')
        }
      }
    })

    setGroupTypeTotalPrice(groupTypesTotal)

    return [...types].map((t) => ({ id: t, name: GROUP_TYPES_LABEL[t] })) as GroupType[]
  }, [objects])

  const toggleExcludeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const status = event.target.checked
    setIsExcludeCategory(status)
    localStorageSetValue('object-exclude-category', status)
  }

  const isLimitsAndHiglightHidden = (filterValues?.category?.length && filterValues?.category?.length > 1) || isExcludeCategory

  return (
    <Stack spacing={6}>
      <HStack justifyContent="space-between">
        <Heading as="h2" size="lg">
          Отчет - объект
        </Heading>

        <IconButton onClick={onOpen} aria-label="Open filters" icon={<FiFilter />} />
      </HStack>

      <HStack>
        <FormControl>
          <FormLabel htmlFor="category">Объект</FormLabel>
          <Select
            id="category"
            value={filterValues?.category || null}
            placeholder="Выберите..."
            isSearchable
            isClearable={false}
            isMulti
            getOptionValue={(option: SelectOption) => `${option?.id}`}
            getOptionLabel={(option: SelectOption) => `${option?.name}`}
            onChange={(newCoauthor, selectAction) => changeCategory(selectAction)}
            options={categories}
            chakraStyles={{
              control: (provided) => ({ ...provided, backgroundColor: 'white' })
            }}
            selectedOptionStyle="check"
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="reportMode">Режим</FormLabel>
          <Select<ReportMode>
            id="reportMode"
            value={reportMode}
            placeholder="Выберите..."
            getOptionValue={(option: ReportMode) => `${option.key}`}
            getOptionLabel={(option: ReportMode) => `${option.label}`}
            onChange={(newMode) => changeReportMode(newMode!)}
            options={REPORT_MODES}
            chakraStyles={{
              control: (provided) => ({ ...provided, backgroundColor: 'white' })
            }}
          />
        </FormControl>
      </HStack>

      <HStack justifyContent="space-between">
        <FormControl>
          <Checkbox isChecked={isExcludeCategory} onChange={toggleExcludeCategory}>
            Все объекты, кроме указанных выше{' '}
            {isExcludeCategory && (
              <>
                (
                <Button colorScheme="teal" variant="link" onClick={onToggleList}>
                  список
                </Button>
                )
              </>
            )}
          </Checkbox>
        </FormControl>
        {reportMode.key === 'financial' && (
          <FormControl>
            <Checkbox isChecked={isGroupLimits} onChange={(e) => setIsGroupLimits(e.target.checked)}>
              Группировать по лимитам
            </Checkbox>
          </FormControl>
        )}
      </HStack>

      {!isTagsHidden && (
        <Flex wrap="wrap" gap="2">
          {tags.map((filterKey) => {
            return (
              <Tag size="sm" key={filterKey} borderRadius="full" variant="solid" colorScheme="blue">
                <TagLabel>{getTagLabel(filterKey)}</TagLabel>
                <TagCloseButton onClick={() => removeFilter(filterKey)} />
              </Tag>
            )
          })}
          {tags.length ? (
            <Button onClick={() => resetFilters(defaultFilters)} colorScheme="red" size="xs">
              <FaUndo /> <Box ml={1}>Сбросить</Box>
            </Button>
          ) : null}
        </Flex>
      )}

      <Box rounded="full" position="relative" minH="70vh">
        {isLoading || isFetchingReports ? (
          <Loader />
        ) : !objects || objects.length === 0 ? (
          <EmptyResults />
        ) : (
          <>
            <Flex justifyContent="flex-end" mr="40px">
              {groupTypes.map((type) => (
                <Box key={type.id} width="100px" textAlign="center" py={2}>
                  {type.name.toLowerCase()}
                </Box>
              ))}
            </Flex>

            <Accordion allowMultiple>
              {objects.map((object, i) => {
                if (reportMode.key === 'by-quantity') {
                  return (
                    <QuantityObject
                      key={i}
                      object={object as GetReportsObjectByQuantityItem}
                      replaceDocFilters={replaceDocFilters}
                      groupTypes={groupTypes}
                      isLimitExceeded={(object as GetReportsObjectByQuantityItem).is_limit_exceeded}
                      isLimitsAndHiglightHidden={isLimitsAndHiglightHidden}
                    />
                  )
                }

                if (reportMode.key === 'by-supplier') {
                  return <SupplierObject key={i} object={object as GetReportsObjectBySupplierItem} replaceDocFilters={replaceDocFilters} />
                }

                if (reportMode.key === 'by-client') {
                  return <ClientObject key={i} object={object as GetReportsObjectByClientItem} replaceDocFilters={replaceDocFilters} />
                }

                if (reportMode.key === 'financial-by-client') {
                  return (
                    <FinancialByClientObject
                      key={i}
                      object={object as GetReportsObjectByGroupAndClientItem}
                      replaceDocFilters={replaceDocFilters}
                      groupTypes={groupTypes}
                      isLimitExceeded={(object as GetReportsObjectByGroupAndClientItem).is_limit_exceeded}
                      isLimitsAndHiglightHidden={isLimitsAndHiglightHidden}
                    />
                  )
                }

                return (
                  <FinancialObject
                    key={i}
                    object={object as ReportObject}
                    replaceDocFilters={replaceDocFilters}
                    groupTypes={groupTypes}
                    isLimitExceeded={(object as ReportObject).is_limit_exceeded}
                    isGroupLimits={isGroupLimits}
                    isLimitsAndHiglightHidden={isLimitsAndHiglightHidden}
                  />
                )
              })}
              <HStack justifyContent="space-between">
                <Stack>
                  <Box>
                    Первый документ от:{' '}
                    <Button
                      variant="link"
                      color="blue.400"
                      fontWeight="bold"
                      textDecoration="underline"
                      _hover={{ color: 'blue.700' }}
                      onClick={() => replaceDocFilters(earliestDate.ids)}
                    >
                      {earliestDate.date}
                    </Button>
                  </Box>
                  <Box>Актуально на: {latestDate.date}</Box>
                  <Box>
                    Количество документов:
                    <Button variant="link" color="blue.400" fontWeight="bold" textDecoration="underline" _hover={{ color: 'blue.700' }} onClick={navigateByReportId}>
                      {documentsCount}
                    </Button>
                  </Box>
                </Stack>
                <TotalCount
                  totalGroupPrice={totalGroupPrice}
                  isAdditionalTotalVisible={reportMode.key !== 'by-quantity'}
                  isGroupsTotalVisible={!!filterValues && !!filterValues.split_by_group_type && Object.keys(groupTypeTotalPrice).length > 0}
                  totalGroupTakings={totalGroupTakings}
                  groupTypeTotalPrice={groupTypeTotalPrice}
                />
              </HStack>
            </Accordion>
          </>
        )}
      </Box>

      <ObjectsFilters isFilterOpen={isOpen} filterValues={filterValues || defaultFilters} setFilterClose={onClose} setFilterValues={setFilterValues} />
      <ExludedListModal isOpen={isListOpen} onClose={onToggleList} onSave={onSaveList} excludedCategories={filterValues?.category || []} categories={listCategories} />
    </Stack>
  )
}

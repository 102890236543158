import { useMemo, useState } from 'react'
import { getInfoByIIN } from '~/api/organizations'
import { useForm } from 'react-hook-form'
import { DefaultOrg, Org } from '~/types/organizations'

type Inputs = {
  id?: number
  iin: number | ''
  name: string | ''
  address: string | ''
  comment: string | ''
  own_entity: boolean
}

interface DisabledInputs {
  name: boolean
  address: boolean
}

export function useSearchByIIN(orgData: Org | DefaultOrg) {
  const {
    reset,
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors, isSubmitting }
  } = useForm<Inputs>({
    defaultValues: useMemo(() => {
      return orgData
    }, [orgData])
  })

  const [iinResults, setIinResults] = useState([])
  const [disabledInputs, setDisabledInputs] = useState<DisabledInputs>({ name: true, address: true })

  const searchByIIN = async () => {
    const { iin } = getValues()

    const res = await getInfoByIIN(`${iin}`)
    setIinResults(res)

    if (res.length) {
      const name = res[0].data.name.short_with_opf
      const address = res[0].data.address.unrestricted_value
      setValue('name', name)
      setValue('address', address)
      trigger(['name', 'address'])
    }
  }

  return [iinResults, getValues, reset, errors, control, isSubmitting, handleSubmit, disabledInputs, setDisabledInputs, searchByIIN] as const
}

import {
  Stack,
  Heading,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  HStack,
  Image,
  useDisclosure,
  Switch,
  Textarea,
  useToast,
  Link,
  Wrap,
  WrapItem
} from '@chakra-ui/react'
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link as ReactLink, useNavigate, useParams } from 'react-router-dom'
import { CloneDocParams, DocCoauthor, GetDocResponse, ShortDoc } from '~/types/docs'
import { addDocMedia, deleteDocMedia, getDoc as getDocApi, updateDoc, getDocsCategories, duplicateDoc, downloadMediaDoc, getDocs } from '~/api/docs'
import dateFormat from 'dateformat'
import Loader from '~/components/Loader'
import { Select, AsyncSelect, ActionMeta } from 'chakra-react-select'
import DatePicker from '~/components/DatePicker'
import { FiTrash } from 'react-icons/fi'
import AlertDialogComponent from '~/components/AlertDialog'
import Records from '~/components/doc/Records'
import { Category, ModalAction, SelectOption } from '~/types/common'
import { CategoriesContext } from '~/utils/context'
import { getUsers as getUsersApi } from '~/api/users'
import { User } from '~/types/users'
import { createCoauthor, deleteCoauthor } from '~/api/coauthors'
import CloneModal from '~/components/doc/CloneModal'
import CloneModalSuccess from '~/components/doc/CloneModalSuccess'
import RecordSet from '~/components/doc/RecordSet'
import { useGetOrganizations } from '~/hooks/useGetOrganizations'
import FileViewer from '~/components/doc/FileViewer'
import { getExtension } from '~/utils/helpers'
import FileUploader from '~/components/doc/FileUploader'

import { PROOF_OF_WORK_CATEGORY_NAME, TRANSFER_CATEGORY_NAME } from '~/utils/constants'
import TransferInfo from '~/components/doc/TransferInfo'
import { GetRecordsCategoriesParams, Record } from '~/types/records'
import { getRecordsCategories } from '~/api/records'
import CloneAlertModal from '~/components/doc/CloneAlertModal'
import Xls from '../assets/images/xls.png'
import Pdf from '../assets/images/pdf.png'

export default function DocView() {
  const id = useParams().id!

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isCloneOpen, onOpen: onCloneOpen, onClose: onCloneClose } = useDisclosure()
  const { isOpen: isCloneSuccessOpen, onOpen: onCloneSuccessOpen, onClose: onCloneSuccessClose } = useDisclosure()
  const { isOpen: isOpenFilePreviwer, onOpen: onOpenFilePreviwer, onClose: onCloseFilePreviwer } = useDisclosure()

  const navigate = useNavigate()
  const { categories, setCategories } = useContext(CategoriesContext)

  const [isLoading, setIsLoading] = useState(false)
  const [isMediaLoading, setIsMediaLoading] = useState(false)
  const [docData, setDocData] = useState<GetDocResponse>({
    id: 0,
    author: {
      id: 0,
      name: '-',
      last_name: '-'
    },
    category: {
      id: 0,
      name: '-'
    },
    record_category: {
      id: 0,
      name: '-'
    },
    executor: {
      id: 0,
      name: '-'
    },
    receiver: {
      id: 0,
      name: ''
    },
    supplier: {
      id: 0,
      name: ''
    },
    coauthors: [
      {
        id: 0,
        author: {
          id: 0,
          name: 'string',
          last_name: 'string'
        }
      }
    ],
    created_at: '',
    comment: '-',
    number: null,
    date: new Date(),
    is_closing: false,
    is_original: false,
    is_processed: false,
    media: [],
    records: [],
    total_price: 0,
    record_sets_total_price: 0,
    is_recordset_hidden: true,
    transfer: null,
    created_by_transfer_document_id: null,
    records_group_id: ''
  })
  const [users, setUsers] = useState<User[]>([])
  const [recordsCategories, setRecordsCategories] = useState<Category[]>([])
  const [isProcessedDisabled, setIsProcessedDisabled] = useState(true)
  const [isDocDataSubmitted, setIsDocDataSubmitted] = useState(false)
  const [action, setAction] = useState<ModalAction>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isRecordsUpdated, setIsRecordsUpdated] = useState(false)
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0)
  const [imageToDelete, setImageToDelete] = useState<null | number>(null)
  const [isCloneAlertOpen, setIsCloneAlertOpen] = useState(false)
  const [isRecordSetRefetch, setIsRecordSetRefetch] = useState(false)
  const [recordGroupCount, setRecordGroupCount] = useState(0)
  const [selectedRecords, setSelectedRecords] = useState<Record[]>([])

  const [organizations, loadOrganizations, getOrganizatons] = useGetOrganizations()

  const isTransfer = useMemo(() => docData.category.name === TRANSFER_CATEGORY_NAME, [docData])
  const isProofOfWorkCategory = useMemo(() => {
    return docData.category.name === PROOF_OF_WORK_CATEGORY_NAME
  }, [docData])
  const isTransferAvailable = useMemo(() => isTransfer && isDocDataSubmitted, [isDocDataSubmitted, isTransfer])

  const toast = useToast()

  const getCategories = async (params: GetRecordsCategoriesParams = {}) => {
    const { results } = await getRecordsCategories({ ...params, is_closed: false, limit: 1000 })
    setRecordsCategories(results)
    return results
  }

  const onOpenPreviewer = (index: number) => {
    setSelectedMediaIndex(index)
    onOpenFilePreviwer()
  }

  const updateSidebarCategories = async () => {
    const { results } = await getDocsCategories()
    setCategories(results)
  }

  const updateDate = async (newDate: Date | null) => {
    if (newDate) {
      setIsDocDataSubmitted(true)
    } else {
      setIsDocDataSubmitted(false)
    }

    try {
      await updateDoc(docData.id, { date: newDate ? dateFormat(newDate, 'yyyy-mm-dd') : null })
    } catch (e) {
      console.error(e)
    }
  }

  const onDataUpdate = (date: Date | null) => {
    setDocData({ ...docData, date })
    updateDate(date)
  }

  const getIsShowCloneAlert = async (): Promise<boolean> => {
    try {
      const { count } = await getDocs({ records_group_id: docData.records_group_id, limit: 1 })
      return count > recordGroupCount
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const onSubmit = async (prop?: ModalAction) => {
    setAction(prop)
    setIsSubmitting(true)

    if (prop === 'update') {
      const toSend: ShortDoc = {
        category: docData.category.id,
        receiver: docData.receiver ? docData.receiver.id : null,
        supplier: docData.supplier ? docData.supplier.id : null,
        record_category: docData.record_category ? docData.record_category.id : null,
        executor: docData.executor ? docData.executor.id : null,
        comment: docData.comment,
        number: docData.number,
        date: docData.date ? dateFormat(docData.date, 'yyyy-mm-dd') : null,
        is_closing: docData.is_closing,
        is_original: docData.is_original,
        is_processed: docData.is_processed
      }

      try {
        await updateDoc(docData.id, toSend)
        toast({
          title: 'Документ изменен.',
          status: 'success',
          duration: 3000,
          isClosable: true
        })

        setIsRecordSetRefetch(true)
      } catch (e) {
        toast({
          title: 'Произошла ошибка.',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }

    if (prop === 'delete') {
      await updateDoc(docData.id, { is_removed: true })
      navigate(`/documents/${docData.category.id}`)
    }

    await updateSidebarCategories()

    setIsSubmitting(false)

    const isShow = await getIsShowCloneAlert()
    setIsCloneAlertOpen(isShow)
  }

  const getDoc = async () => {
    const res = await getDocApi(+id)
    res.date = res.date ? new Date(res.date) : null
    return res
  }

  const getRecordGroupCount = async (groupId: string) => {
    try {
      const { count } = await getDocs({ records_group_id: groupId, limit: 1 })
      setRecordGroupCount(count)
    } catch (e) {
      console.error(e)
    }
  }

  const getFullDoc = async () => {
    setIsLoading(true)
    const fullDoc = await getDoc()
    getRecordGroupCount(fullDoc.records_group_id)
    if (fullDoc.date) {
      setIsDocDataSubmitted(true)
    }
    setDocData(fullDoc)
    setIsLoading(false)
  }

  const getUsers = async () => {
    const { results } = await getUsersApi({ groups: '1' })
    setUsers(results)
  }

  const onAlertDeleteImage = (mediaId: number) => {
    setImageToDelete(mediaId)
    onOpen()
  }

  const removeMedia = async () => {
    if (!imageToDelete) return

    setIsMediaLoading(true)
    await deleteDocMedia(imageToDelete)
    const updatedMedia = docData.media.filter((m) => m.id !== imageToDelete)
    setDocData({ ...docData, media: updatedMedia })
    setIsMediaLoading(false)
  }

  const updateMedia = async () => {
    setIsMediaLoading(true)
    const res = await getDoc()
    setDocData({ ...docData, media: res.media })
    setIsMediaLoading(false)
  }

  const addMedia = async (event: ChangeEvent<HTMLInputElement>) => {
    const targetEvent = event.target as HTMLInputElement
    const newFile = targetEvent.files![0]

    const formData = new FormData()
    formData.append('file', newFile)
    formData.append('fileName', newFile.name)
    formData.append('document', `${docData.id}`)
    formData.append('file_type', newFile.type)

    await addDocMedia(formData)
    await updateMedia()
  }

  const updateRecords = useCallback(
    async (updatedRecords: Record[]) => {
      const res = await getDoc()
      setDocData({ ...res, total_price: res.total_price, records: res.records })
      setIsRecordsUpdated(true)

      setSelectedRecords(updatedRecords)
    },
    [docData, getDoc]
  )

  const updateRecordSet = async () => {
    const res = await getDoc()
    setDocData({ ...docData, record_sets_total_price: res.record_sets_total_price, records: res.records })
  }

  const changeCoauthor = async (selectAction: ActionMeta<SelectOption>) => {
    const coauthorAction = selectAction.action

    if (coauthorAction === 'remove-value') {
      const removed = selectAction.removedValue
      await deleteCoauthor(removed.id!)
    }

    if (coauthorAction === 'select-option') {
      const newCoathor = selectAction.option
      await createCoauthor({ author: newCoathor!.id!, document: docData.id })
    }

    getFullDoc()
  }

  const transformCoauthors = (coauthors: DocCoauthor[]) => {
    if (!coauthors || coauthors.length === 0) return []
    return coauthors.map((coauthor) => {
      return { id: coauthor.id, name: `${coauthor.author.name ?? ''}`, last_name: `${coauthor.author.last_name ?? ''}` }
    })
  }

  const cloneDoc = async (params: CloneDocParams) => {
    await duplicateDoc({ document_id: +id, duplicate_dates: params.dates, options: params.options, category_id: params.category_id })
    updateSidebarCategories()
    onCloneClose()
    onCloneSuccessOpen()
  }

  const getImage = (src: string) => {
    const extension = getExtension(src)

    if (extension === 'pdf') {
      return Pdf
    }

    if (extension === 'xls' || extension === 'xlsx') {
      return Xls
    }

    return src
  }

  const downloadMedia = async () => {
    try {
      const { media_url: url } = await downloadMediaDoc({ document: docData.id })

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', '')

      document.body.appendChild(link)

      link.click()

      link.parentNode?.removeChild(link)
    } catch (e) {
      console.error(e)
    }
  }

  const onCloseCloneAlert = () => {
    setIsCloneAlertOpen(false)
    navigate(`/documents/${docData.category.id}`)
  }

  // todo: move getOrganizatons to useGetOrg?
  useEffect(() => {
    getFullDoc()
    getOrganizatons()
    getUsers()
    getCategories()
  }, [])

  useEffect(() => {
    if (isProcessedDisabled) {
      setDocData({ ...docData, is_processed: false })
    }
  }, [isProcessedDisabled])

  useEffect(() => {
    if (docData.records.length > 0 && docData.supplier && docData.date) {
      setIsProcessedDisabled(false)
    } else {
      setIsProcessedDisabled(true)
    }
  }, [docData])

  return (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <Heading as="h2" size="lg">
          Документ #{docData?.id ?? '0'} от {dateFormat(docData?.created_at, 'dd.mm.yyyy')}
        </Heading>
        {docData.created_by_transfer_document_id && (
          <Heading as="h4" size="sm">
            Автоматически создан перемещением{' '}
            <Link
              as={ReactLink}
              color="blue.400"
              fontWeight="bold"
              textDecoration="underline"
              to={`/document/${docData.created_by_transfer_document_id}`}
              _hover={{ color: 'blue.700' }}
              target="_blank"
            >
              {docData.created_by_transfer_document_id}
            </Link>
          </Heading>
        )}
      </Stack>

      <Box as="form" position="relative" rounded="lg" bg={useColorModeValue('white', 'gray.700')} border="1px" borderColor="gray.200" p={5}>
        {isLoading && <Loader />}
        <Stack spacing={8}>
          <Stack spacing={3}>
            <Heading as="h3" size="sm">
              Документ
            </Heading>
            <HStack>
              <FormControl>
                <FormLabel htmlFor="author">Автор</FormLabel>
                <Select<SelectOption>
                  isDisabled
                  getOptionValue={(author: SelectOption) => `${author.id}`}
                  getOptionLabel={(author: SelectOption) => `${author.name} ${author.last_name}`}
                  value={docData?.author}
                  id="author"
                  placeholder="Выберите автора..."
                />
              </FormControl>

              {!isTransfer && (
                <>
                  <FormControl>
                    <FormLabel htmlFor="category">Категория</FormLabel>
                    <Select<SelectOption>
                      placeholder="Выберите категорию..."
                      value={docData.category}
                      getOptionValue={(doc: SelectOption) => `${doc?.id}`}
                      getOptionLabel={(doc: SelectOption) => `${doc?.name}`}
                      onChange={(newValue) => setDocData({ ...docData, category: { id: newValue!.id!, name: newValue!.name! } })}
                      options={categories}
                    />
                  </FormControl>

                  {!isProofOfWorkCategory && (
                    <FormControl>
                      <FormLabel htmlFor="supplier">Поставщик</FormLabel>
                      <AsyncSelect<SelectOption>
                        isClearable
                        placeholder="Выберите поставщика..."
                        loadOptions={loadOrganizations}
                        defaultOptions={organizations}
                        getOptionValue={(org: SelectOption) => `${org?.id}`}
                        getOptionLabel={(org: SelectOption) => `${org?.name}`}
                        value={docData.supplier}
                        // @ts-expect-error fix later (supplier can be null)
                        onChange={(newValue) => setDocData({ ...docData, supplier: newValue! })}
                      />
                    </FormControl>
                  )}
                </>
              )}
            </HStack>
            <HStack alignItems="flex-start">
              {!isProofOfWorkCategory && (
                <>
                  <FormControl>
                    <FormLabel htmlFor="coauthors">Соавторы</FormLabel>
                    <Select
                      isClearable={false}
                      isMulti
                      name="coauthors"
                      value={transformCoauthors(docData.coauthors)}
                      options={users}
                      getOptionValue={(coauthor: SelectOption) => `${coauthor?.id}`}
                      getOptionLabel={(coauthor: SelectOption) => `${coauthor?.name ?? ''} ${coauthor?.last_name ?? ''}`}
                      onChange={(newCoauthor, selectAction) => changeCoauthor(selectAction)}
                      placeholder="Выберите соавторов..."
                      selectedOptionStyle="check"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="number">Номер</FormLabel>
                    <Input
                      id="number"
                      placeholder="Введите..."
                      value={docData.number || ''}
                      onChange={(e) => setDocData({ ...docData, number: e.target.value ? +e.target.value : null })}
                    />
                  </FormControl>
                </>
              )}
              <FormControl>
                <FormLabel htmlFor="date">Дата</FormLabel>
                <DatePicker id="date" selectedDate={docData.date} onChange={(date) => onDataUpdate(date)} showPopperArrow />
              </FormControl>
            </HStack>

            {isProofOfWorkCategory && (
              <HStack>
                <FormControl>
                  <FormLabel htmlFor="executor">Подрядчик</FormLabel>
                  <AsyncSelect<SelectOption>
                    id="executor"
                    isClearable
                    isSearchable
                    placeholder="Выберите подрядчика..."
                    loadOptions={loadOrganizations}
                    defaultOptions={organizations}
                    getOptionValue={(org: SelectOption) => `${org?.id}`}
                    getOptionLabel={(org: SelectOption) => `${org?.name}`}
                    value={docData.executor}
                    onChange={(newValue) => setDocData({ ...docData, executor: newValue ? { id: newValue!.id!, name: newValue!.name! } : null })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="record_category">Объект</FormLabel>
                  <Select<SelectOption>
                    id="record_category"
                    placeholder="Выберите объект..."
                    value={docData.record_category}
                    getOptionValue={(doc: SelectOption) => `${doc?.id}`}
                    getOptionLabel={(doc: SelectOption) => `${doc?.name}`}
                    onChange={(newValue) => setDocData({ ...docData, record_category: { id: newValue!.id!, name: newValue!.name! } })}
                    options={recordsCategories}
                  />
                </FormControl>
              </HStack>
            )}
          </Stack>

          <Stack spacing={3}>
            <HStack justifyContent="space-between">
              <Heading as="h3" size="sm">
                Медиафайлы
              </Heading>

              <Button onClick={downloadMedia}>Скачать все</Button>
            </HStack>
            <Wrap position="relative">
              {isMediaLoading && <Loader />}

              {docData.media.map((image, index) => (
                <WrapItem minW="100px" key={image.id} position="relative">
                  <button type="button" onClick={() => onOpenPreviewer(index)}>
                    <Image border="1px" borderColor="gray.200" boxSize="100px" objectFit="cover" src={getImage(image.file)} alt="Document media" />
                  </button>
                  <Button position="absolute" top={0} right={0} colorScheme="red" size="xs" onClick={() => onAlertDeleteImage(image.id)}>
                    <FiTrash size="15px" />
                  </Button>
                  <AlertDialogComponent
                    isOpen={isOpen}
                    onClose={onClose}
                    alertHeader="Удаление медиа"
                    alertBody="Уверены ли вы? Вы не можете отменить это действие впоследствии"
                    actionConfirmed={() => removeMedia()}
                  />
                </WrapItem>
              ))}
            </Wrap>
            <FileUploader onAdd={addMedia} />
          </Stack>

          {isTransfer ? (
            <TransferInfo docId={docData.id} isTransferAvailable={isTransferAvailable} transfer={docData.transfer} />
          ) : (
            <Box>
              {!isProofOfWorkCategory && (
                <Box>
                  <Records docId={+id} totalPrice={docData.total_price} onRecordsChange={updateRecords} supplierId={docData.supplier?.id ?? null} />

                  <RecordSet
                    docId={+id}
                    totalPrice={docData.record_sets_total_price}
                    supplierId={docData.supplier?.id ?? null}
                    onRecordSetChange={updateRecordSet}
                    isRecordsSetHidden={docData.is_recordset_hidden}
                    isRecordsUpdated={isRecordsUpdated}
                    isRecordSetRefetch={isRecordSetRefetch}
                    setRecordsUpdated={(status) => setIsRecordsUpdated(status)}
                    setIsRecordSetRefetch={(isRefetched) => setIsRecordSetRefetch(isRefetched)}
                    selectedRecords={selectedRecords}
                  />

                  <Stack spacing={3} mt={8} mb={4}>
                    <Heading as="h3" size="sm">
                      Бухгалтерия
                    </Heading>

                    <HStack spacing="5">
                      <FormControl w="auto" display="flex" alignItems="center">
                        <FormLabel htmlFor="isOriginal" mb="0">
                          Оригинал
                        </FormLabel>
                        <Switch
                          id="isOriginal"
                          isChecked={docData.is_original}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => setDocData({ ...docData, is_original: event.target.checked })}
                        />
                      </FormControl>
                      <FormControl w="auto" display="flex" alignItems="center">
                        <FormLabel htmlFor="isClosed" mb="0">
                          Закрывающие документы
                        </FormLabel>
                        <Switch
                          id="isClosed"
                          isChecked={docData.is_closing}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => setDocData({ ...docData, is_closing: event.target.checked })}
                        />
                      </FormControl>
                    </HStack>
                  </Stack>
                </Box>
              )}
              <Stack spacing={3}>
                <Heading as="h3" size="sm">
                  Комментарий
                </Heading>

                <Textarea value={docData.comment} onChange={(event) => setDocData({ ...docData, comment: event.target.value })} />
              </Stack>
              <FormControl w="auto" display="flex" alignItems="center" isDisabled={isProcessedDisabled}>
                <FormLabel htmlFor="isProcessed" mb="0">
                  Обработка завершена
                </FormLabel>
                <Switch
                  id="isProcessed"
                  isChecked={docData.is_processed}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setDocData({ ...docData, is_processed: event.target.checked })}
                />
              </FormControl>
            </Box>
          )}
        </Stack>

        <HStack justifyContent="space-between" mt="7">
          <Button colorScheme="red" onClick={() => onSubmit('delete')} isLoading={action === 'delete' && isSubmitting}>
            Удалить
          </Button>

          <HStack justifyContent="space-between" mt="7">
            {!isTransfer && (
              <Button colorScheme="gray" disabled={!docData.supplier} onClick={onCloneOpen}>
                Клонировать
              </Button>
            )}

            <Button colorScheme="green" onClick={() => onSubmit('update')} isLoading={action === 'update' && isSubmitting}>
              Сохранить
            </Button>
          </HStack>
        </HStack>
      </Box>
      <CloneModal isOpen={isCloneOpen} onClose={onCloneClose} onClone={cloneDoc} />
      <CloneModalSuccess category={docData.category} isOpen={isCloneSuccessOpen} onClose={onCloneSuccessClose} />
      <CloneAlertModal isOpen={isCloneAlertOpen} onClose={onCloseCloneAlert} />

      {docData.media.length && (
        <FileViewer
          isMediaLoading={isMediaLoading}
          isOpen={isOpenFilePreviwer}
          onClose={onCloseFilePreviwer}
          docs={docData.media}
          selectedIndex={selectedMediaIndex}
          onAdd={addMedia}
        />
      )}
    </Stack>
  )
}

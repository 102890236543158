import { useState } from 'react'
import { Doc } from '~/types/docs'

import { getRecords as getRecordsApi } from '~/api/records'
import { Record } from '~/types/records'
import { useDisclosure } from '@chakra-ui/react'

enum ActionValue {
  merge = 'merge',
  delete = 'delete',
  complete = 'complete'
}

export interface ActionOption {
  label: string
  value: ActionValue
}

const ACTIONS: ActionOption[] = [
  { label: 'Объединить', value: ActionValue.merge },
  { label: 'Удалить', value: ActionValue.delete },
  { label: 'Завершить обработку', value: ActionValue.complete }
]

interface OpenedReportsPreview {
  [id: string]: boolean
}

interface DocRecords {
  [id: string]: Record[]
}

export function useExtendedDocs(docs: Doc[]) {
  const [openedRecordsPreview, setOpenedRecordsPreview] = useState<OpenedReportsPreview>({})
  const [docRecords, setDocRecords] = useState<DocRecords>({})
  const [selectedExtendedDocsIds, setSelectedExtendedDocs] = useState<number[]>([])
  const { isOpen: isMergeDocsModalOpen, onOpen: onMergeDocsModalOpen, onClose: onMergeDocsModalClose } = useDisclosure()
  const { isOpen: isDeleteDocsModalOpen, onOpen: onDeleteDocsModalOpen, onClose: onDeleteDocsModalClose } = useDisclosure()
  const { isOpen: isUpdateDocsModalOpen, onOpen: onUpdateDocsModalOpen, onClose: onUpdateDocsModalClose } = useDisclosure()

  const onActionOptionClick = async (action: ActionOption | null) => {
    if (action === null) {
      onMergeDocsModalClose()
      return
    }
    if (action.value === ActionValue.merge) {
      onMergeDocsModalOpen()
    }
    if (action.value === ActionValue.delete) {
      onDeleteDocsModalOpen()
    }
    if (action.value === ActionValue.complete) {
      onUpdateDocsModalOpen()
    }
  }

  const getDocRecords = async (docId: number) => {
    try {
      if (docId in docRecords) {
        return docRecords[docId]
      }

      const { results } = await getRecordsApi({ document: docId })

      const updated = { ...docRecords }
      updated[docId] = results
      setDocRecords(updated)

      return results
    } catch (e) {
      console.log(e)
    }
  }

  const toggleRecordsPreview = async (id: number) => {
    const updated = { ...openedRecordsPreview }
    if (id in updated) {
      updated[id] = !updated[id]
    } else {
      updated[id] = true
      await getDocRecords(id)
    }
    setOpenedRecordsPreview(updated)
  }

  const selectExtendedDoc = (isChecked: boolean, docId: number) => {
    let updated = [...selectedExtendedDocsIds]
    if (isChecked) {
      updated.push(docId)
    } else {
      updated = updated.filter((i) => i !== docId)
    }
    setSelectedExtendedDocs(updated)
  }

  const selectAllExtendedDoc = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedExtendedDocs(docs.map((doc) => doc.id))
    } else {
      setSelectedExtendedDocs([])
    }
  }

  const isMergeActionDisabled = () => {
    if (selectedExtendedDocsIds.length < 2) return true

    const selectedDocs = docs.filter((doc) => selectedExtendedDocsIds.includes(doc.id))

    const suppliers = selectedDocs.map((d) => d.supplier?.id || null).filter((s) => s !== null)
    const isSupplierNotTheSame = suppliers.length === 0 ? false : [...new Set<number | null>(suppliers)].length > 1
    if (isSupplierNotTheSame) return true

    const dates: string[] = selectedDocs.map((d) => d.date!).filter((d) => d !== null)
    const isDateNotTheSame = [...new Set<string>(dates)].length > 1
    if (isDateNotTheSame) return true

    const clientIds: number[] = selectedDocs
      .map((doc) => doc.records.map((r) => r.client.id))
      .flat()
      .filter((id) => id !== null)
    const isClientsNotTheSame = clientIds.length === 0 ? false : [...new Set<number>(clientIds)].length > 1
    if (isClientsNotTheSame) return true

    return false
  }

  const isCompleteDisabled = () => {
    if (selectedExtendedDocsIds.length < 1) return true

    const selectedDocs = docs.filter((doc) => selectedExtendedDocsIds.includes(doc.id))

    const isSomeRecordsEmpty = selectedDocs.some((d) => d.records.length === 0)
    if (isSomeRecordsEmpty) return true

    const isOneProcessed = selectedDocs.some((d) => d.is_processed)
    if (isOneProcessed) return true

    return false
  }

  const isActionOptionDisabled = (option: ActionOption) => {
    if (option.value === ActionValue.merge) {
      return isMergeActionDisabled()
    }

    if (option.value === ActionValue.delete) {
      return selectedExtendedDocsIds.length < 1
    }

    if (option.value === ActionValue.complete) {
      return isCompleteDisabled()
    }

    return true
  }

  return {
    selectedExtendedDocsIds,
    selectExtendedDoc,
    selectAllExtendedDoc,
    isActionOptionDisabled,
    toggleRecordsPreview,
    docRecords,
    openedRecordsPreview,
    onActionOptionClick,
    isMergeDocsModalOpen,
    onMergeDocsModalClose,
    isDeleteDocsModalOpen,
    onDeleteDocsModalClose,
    isUpdateDocsModalOpen,
    onUpdateDocsModalClose,
    ACTIONS
  }
}

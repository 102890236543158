import { Flex, Icon, Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel, List, ListItem, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { NavItemProps } from '~/types/sidebar'

export default function NavItem({ icon, name, href, subLinks, isCollapsed, ...rest }: NavItemProps) {
  if (subLinks!.length > 0) {
    return (
      <Accordion defaultIndex={[isCollapsed ? -1 : 0]} allowToggle>
        <AccordionItem borderBottom="none" borderColor="gray.700">
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Flex align="center" p="2" mx="2" borderRadius="lg" role="group" cursor="pointer" {...rest}>
                {icon && <Icon mr="4" fontSize="16" as={icon} />}
                {name}
              </Flex>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <List px="4" spacing={3}>
              {subLinks?.map((link) => (
                <ListItem key={link.name}>
                  <Link to={link.href} style={{ textDecoration: 'none' }}>
                    {link.name}
                    {link.documents_count ? (
                      <Text fontSize="sm" display="inline" ml="2">
                        ({link.documents_count})
                      </Text>
                    ) : (
                      ''
                    )}
                  </Link>
                </ListItem>
              ))}
            </List>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    )
  }
  return (
    <Link to={href} style={{ textDecoration: 'none' }}>
      <Flex align="center" p="4" mx="4" borderRadius="lg" role="group" cursor="pointer" {...rest}>
        {icon && <Icon mr="4" fontSize="16" as={icon} />}
        {name}
      </Flex>
    </Link>
  )
}

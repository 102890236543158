import { FormControl, FormLabel } from '@chakra-ui/react'
import { Controller, Control } from 'react-hook-form'
import { Select } from 'chakra-react-select'
import { User } from '~/types/users'
import { getUsers as getUsersApi } from '~/api/users'
import { useEffect, useState } from 'react'
import { SelectOption } from '~/types/common'
import { CategoryInputs } from '~/types/category'

interface Params {
  control: Control<CategoryInputs>
}

export default function ResponsibleUsers({ control }: Params) {
  const [users, setUsers] = useState<User[]>([])

  const getUsers = async () => {
    const { results } = await getUsersApi()
    setUsers(results)
    return results
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <FormControl>
      <FormLabel htmlFor="responsible_users">Ответственный</FormLabel>
      <Controller
        name="responsible_users"
        control={control}
        render={({ field }) => (
          <Select<SelectOption>
            id="responsible_users"
            value={field.value}
            getOptionValue={(option: SelectOption) => `${option?.id}`}
            getOptionLabel={(option: SelectOption) => `${option?.name}`}
            onChange={(newValue) => {
              field.onChange(newValue)
            }}
            isSearchable
            // @ts-expect-error dunno why erroring
            isMulti
            placeholder="Выберите..."
            options={users}
          />
        )}
      />
    </FormControl>
  )
}

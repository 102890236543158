const LS_KEYWORD = 'megagon'
type Key =
  | 'token'
  | 'refresh'
  | 'filters'
  | 'limits-filters'
  | 'object-filters'
  | 'proof-of-work-filters'
  | 'object-reports-mode'
  | 'record-set-filters'
  | 'pagination'
  | 'object-exclude-category'

export interface PaginationObject {
  [key: string]: {
    page: number
    offset: number
  }
}

export const localStorageSetValue = <T>(key: Key, value: T) => {
  const valueToStore = JSON.stringify(value)
  localStorage.setItem(`${LS_KEYWORD}-${key}`, valueToStore)
}

export const localStorageGetValue = <T>(key: Key): T | null => {
  const token = localStorage.getItem(`${LS_KEYWORD}-${key}`)

  if (token) {
    return JSON.parse(token)
  }

  return null
}

export const localStorageRemoveValue = (key: Key) => {
  localStorage.removeItem(`${LS_KEYWORD}-${key}`)
}

export const localStorageClear = () => {
  localStorage.clear()
}

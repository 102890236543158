import React, { ReactElement } from 'react'
import { TableRowProps, Tr as ChakraTr } from '@chakra-ui/react'
import { Consumer } from '~/utils/context'

interface ITrInnerProps extends TableRowProps {
  headers?: Record<number, any>
  inHeader?: boolean
}

function TrInner({ headers, children, inHeader, ...rest }: ITrInnerProps) {
  if (headers && inHeader) {
    React.Children.map(children, (child, i) => {
      if (child) {
        headers[i] = (child as ReactElement).props.children
      }
    })
  }

  const childProps = (idx: number) => (inHeader ? { key: idx } : { key: idx, columnKey: idx })

  return <ChakraTr {...rest}>{children && React.Children.map(children, (child, idx) => React.isValidElement(child) && React.cloneElement(child, childProps(idx)))}</ChakraTr>
}

TrInner.defaultProps = {
  headers: null,
  inHeader: false
}

export type ITrProps = Omit<ITrInnerProps, 'headers'>

export function Tr(props: ITrProps) {
  return <Consumer>{(headers: Record<number, any>) => <TrInner {...props} headers={headers} />}</Consumer>
}

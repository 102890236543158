import { Category, SelectOption, ShortCategoryAndGroup } from '~/types/common'
import { Group } from '~/types/groups'
import { GetRecordsGroupsParams } from '~/types/records'
import { BASE_CATEGORY_NAME } from '~/utils/constants'
import { useCallback, useEffect, useState } from 'react'
import { getCategories } from '~/utils/categories'
import { getRecordsGroups } from '~/api/records'

export const getGroupsFromCategory = async (params: GetRecordsGroupsParams, baseCategory: Category, category: number[]) => {
  const requestToSelected = await getRecordsGroups({ ...params, category: category.join(','), limit: 1000 })

  let groupsFromCategory: Group[] = []

  if (!baseCategory?.id) return []

  if (!requestToSelected.results.length) {
    const requestToBase = await getRecordsGroups({ ...params, category: [baseCategory.id!].join(','), limit: 1000 })
    groupsFromCategory = requestToBase.results
  } else {
    groupsFromCategory = requestToSelected.results
  }

  return groupsFromCategory
}

export function useCategoryGroup(selectedCategory: ShortCategoryAndGroup | Category | null, categories: Category[]) {
  const [groups, setGroups] = useState<Group[]>([])

  const getGroups = async (params: GetRecordsGroupsParams = {}, changedCategory: ShortCategoryAndGroup | Category | null = null) => {
    const newCategory = changedCategory || selectedCategory

    if (!newCategory?.id) return []

    const baseCategory = categories.find((cat) => cat.name === BASE_CATEGORY_NAME)!

    const selectedCategories = [newCategory!.id]
    if (!newCategory?.is_main_groups_hidden && baseCategory && newCategory!.id !== baseCategory.id) {
      selectedCategories.push(baseCategory.id!)
    }

    const groupsFromCategory = await getGroupsFromCategory(params, baseCategory, selectedCategories)
    setGroups(groupsFromCategory)
    return groupsFromCategory
  }

  const searchCategories = (inputValue: string, callback: (options: SelectOption[]) => void) => {
    getCategories({ name: inputValue }).then((updated) => {
      callback(updated)
    })
  }

  const checkCategories = async (value: string) => {
    if (!value) {
      await getCategories()
    }
  }

  const searchGroups = (inputValue: string, callback: (options: SelectOption[]) => void) => {
    getGroups({ name: inputValue }).then((updated) => {
      callback(updated)
    })
  }

  useEffect(() => {
    if (selectedCategory && selectedCategory?.id) {
      getGroups()
    }
  }, [selectedCategory])

  return [groups, getGroups, searchCategories, checkCategories, searchGroups] as const
}

export const BASE_CATEGORY_NAME = 'Основная'
export const TRANSFER_CATEGORY_NAME = 'Перемещение'
export const PROOF_OF_WORK_CATEGORY_SLUG = 'proof-of-work'
export const PRODUCT_DOCUMENTS_CATEGORY_SLUG = 'tovarnye-dokumenty'
export const TRANSPORT_LISTS_CATEGORY_SLUG = 'putevye-listy'
export const PROOF_OF_WORK_CATEGORY_NAME = 'Фотофиксация работ'
export const DEFAULT_SELECTED_CATEGORY = { id: 0, name: BASE_CATEGORY_NAME, executors: [], is_closed: false, is_main_groups_hidden: true, responsible_users: [] }
export const DEFAULT_SELECTED_GROUP = { id: 0, name: '', category: 0, takings: '', type: null }
export const DEFAULT_LIMIT_ITEM = {
  price: '',
  amount: null,
  unit: null,
  is_ignored: false
}
export const DEFAULT_SELECTED_LIMIT = {
  id: 0,
  price: '',
  amount: 0,
  total_price: 0,
  unit: null,
  groups: [],
  category: null,
  items: [DEFAULT_LIMIT_ITEM],
  secondary_group: null,
  name: null,
  real_name: null
}
export const DEFAULT_FULLUSER = {
  id: 0,
  name: '',
  last_name: '',
  birth_date: '',
  secret_word: '',
  date_joined: '',
  tg_id: '',
  public_password: '',
  tg_username: '',
  is_banned: true,
  is_staff: true,
  is_mechanic: true,
  is_web_allowed: false
}

export const GROUP_TYPES = [
  {
    id: null,
    name: 'Не задано'
  },
  {
    id: 'goods',
    name: 'Материалы'
  },
  { id: 'service', name: 'Услуги' },
  { id: 'subcontract', name: 'Субподряд' }
] as const

export const GROUP_TYPES_LABEL = {
  null: 'Не задано',
  goods: 'Материалы',
  service: 'Услуги',
  subcontract: 'Субподряд'
} as const

export const NO_GROUPS = 'Нет групп'

export const OBJECT_TYPES = [
  {
    key: 'author',
    label: 'Сотрудники'
  },
  {
    key: 'technics',
    label: 'Техника'
  }
] as const

export const MECHANICS_MODES = [
  {
    key: 'mechanic',
    label: '«Техника» - «День»'
  },
  {
    key: 'author',
    label: '«Сотрудник» - «День»'
  },
  {
    key: 'category',
    label: '«Объект» - «День»'
  }
] as const

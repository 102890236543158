import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import React from 'react'

interface Props {
  totalSum: string
  selectedDocAmount: number
  docsCount: number
  totalSumSelectedDocs: string
  isDocAmountVisible: boolean
}

export default function DocsTotalSum({ totalSum, selectedDocAmount, totalSumSelectedDocs, docsCount, isDocAmountVisible }: Props) {
  const isBlockVisible = !!totalSum || isDocAmountVisible || selectedDocAmount > 0

  return isBlockVisible ? (
    <HStack justifyContent="space-between" alignItems="flex-start" backgroundColor="#EDF2F7" border="1px solid #E2E8F0" borderRadius="5px" padding="10px">
      <Box>
        <Stack>
          {!!totalSum && (
            <Text fontSize="sm" fontWeight="600">
              Cумма всех документов: {totalSum}
            </Text>
          )}
          <Box>
            {isDocAmountVisible && (
              <Text fontSize="sm" fontWeight="600">
                Документов: {docsCount}
              </Text>
            )}
          </Box>
        </Stack>
      </Box>
      <Box>
        {selectedDocAmount > 0 && (
          <Stack>
            <Text fontSize="sm" fontWeight="600">
              Cумма выделенных документов: {totalSumSelectedDocs}
            </Text>
            <Text fontSize="sm" fontWeight="600">
              Выделено документов: {selectedDocAmount}
            </Text>
          </Stack>
        )}
      </Box>
    </HStack>
  ) : null
}

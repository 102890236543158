import { Stack, HStack, Heading, Button, Box, Input, InputGroup, InputRightElement, useDisclosure } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tr, Th, Td } from '~/components/reponsive-table'
import { ChangeEvent, useEffect, useState } from 'react'
import { getOrgs, createOrg as createOrgApi } from '~/api/organizations'
import Loader from '~/components/Loader'
import Pagination from '~/components/Pagination'
import { DefaultOrg, GetOrgsParams, Org, ShortOrg } from '~/types/organizations'
import { FiSearch } from 'react-icons/fi'
import EmptyResults from '~/components/EmptyResults'
import OrgModal from '~/components/references/OrgModal'

import '~/assets/responsive-table.css'
import { useNavigate } from 'react-router-dom'

const defaultOrg = { iin: '', name: '', address: '', comment: '', own_entity: false } as DefaultOrg

export default function Organizations() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [organizations, setOrganizations] = useState<Org[]>([])
  const [totalPages, setTotalPages] = useState(0)
  const [orgData, setOrgData] = useState<DefaultOrg | Org>(defaultOrg)
  const [error, setError] = useState('')

  const navigate = useNavigate()

  const getOrganizations = async (params: GetOrgsParams) => {
    setIsLoading(true)
    const { results, count } = await getOrgs({ ...params, limit: 10 })
    setOrganizations(results)
    setTotalPages(Math.ceil(count / 10))
    setIsLoading(false)
  }

  const searchOrganizations = async (inputValue: string) => {
    const params = {} as GetOrgsParams

    if (inputValue) {
      const isNumber = !Number.isNaN(+inputValue)
      if (isNumber) {
        params.iin = +inputValue
      } else {
        params.name = inputValue
      }
    }

    params.limit = 10
    getOrganizations(params)
  }

  const handlePageClick = (offset: number) => {
    getOrganizations({ offset })
  }

  const createOrg = async (newOrg: ShortOrg) => {
    try {
      await createOrgApi(newOrg)
      getOrganizations({})
      onClose()
    } catch (e: any) {
      const err = e.response.data.iin
      setError(err)
    }
  }

  const openToCreate = async () => {
    setOrgData(defaultOrg)
    onOpen()
  }

  const openToUpdate = async (org: Org) => {
    navigate(`/organization/${org.id}`)
  }

  useEffect(() => {
    getOrganizations({})
  }, [])

  useEffect(() => {
    setError('')
  }, [isOpen])

  return (
    <Stack spacing={6}>
      <HStack justifyContent="space-between">
        <Heading as="h2" size="lg">
          Справочники - Организации
        </Heading>
        <Button colorScheme="green" onClick={openToCreate}>
          Добавить
        </Button>
      </HStack>
      <InputGroup>
        <Input bg="white" placeholder="Введите наименование или ИНН организации..." onInput={(e: ChangeEvent<HTMLInputElement>) => searchOrganizations(e.target.value)} />
        <InputRightElement pointerEvents="none" children={<FiSearch color="gray.300" />} />
      </InputGroup>
      <Box rounded="full" position="relative" minH="70vh">
        {isLoading && <Loader />}

        {organizations.length === 0 ? (
          <EmptyResults />
        ) : (
          <Table variant="simple" colorScheme="blackAlpha">
            <Thead>
              <Tr>
                <Th>Наименование</Th>
                <Th>ИНН</Th>
                <Th>Юр. адрес</Th>
                <Th>Комментарий</Th>
              </Tr>
            </Thead>
            <Tbody width="100%">
              {organizations.map((org) => (
                <Tr key={org.id}>
                  <Td>
                    <Button colorScheme="blue" fontWeight="bold" variant="link" onClick={() => openToUpdate(org)}>
                      {org.name}
                    </Button>
                  </Td>
                  <Td>{org.iin}</Td>
                  <Td>{org.address}</Td>
                  <Td>{org.comment}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
      <Pagination totalPages={totalPages} handlePageClick={handlePageClick} />
      <OrgModal onClose={onClose} isOpen={isOpen} onCreate={createOrg} orgData={orgData} error={error} />
    </Stack>
  )
}

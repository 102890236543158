import { FormControl, Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { FiFile } from 'react-icons/fi'
import { useRef } from 'react'

interface Props {
  onAdd: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default function FileUploader({ onAdd }: Props) {
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>

  return (
    <FormControl>
      <InputGroup>
        <InputLeftElement pointerEvents="none" style={{ zIndex: 0 }} children={<Icon as={FiFile} />} />
        <input type="file" accept="image/*" ref={inputRef} style={{ display: 'none' }} onChange={(e) => onAdd(e)} />
        <Input placeholder="Выберите файл ..." onClick={() => inputRef!.current!.click()} isReadOnly />
      </InputGroup>
    </FormControl>
  )
}

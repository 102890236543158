import { Container, Flex, useColorModeValue } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

export default function AuthLayout() {
  return (
    <Flex minH="100vh" align="center" justify="center" bg={useColorModeValue('gray.50', 'gray.800')}>
      <Container>
        <Outlet />
      </Container>
    </Flex>
  )
}

import { Category } from '~/types/common'
import {
  GetRecordsParams,
  GetRecordsResponse,
  GetRecordsCategoriesResponse,
  GetRecordsGroupsResponse,
  GetRecordsUnitsResponse,
  NewRecord,
  GetRecordsCategoriesParams,
  ShortRecordCategory,
  ShortRecordUnit,
  GetRecordsGroupsParams,
  ShortRecordGroup,
  DeleteRecordGroupBody
} from '~/types/records'
import { get, post, patch, deleteApi } from './base'

export const getRecords = (params: GetRecordsParams) => get<GetRecordsResponse>(`records/`, { params })

export const getRecordsCategories = (params: GetRecordsCategoriesParams) => get<GetRecordsCategoriesResponse>(`records-categories/`, { params })
export const createRecordCategory = (body: ShortRecordCategory) => post<ShortRecordCategory>(`records-categories/`, body)
export const updateRecordCategory = (id: number, body: Partial<ShortRecordCategory>) => patch<ShortRecordCategory>(`records-categories/${id}/`, body)
export const deleteRecordCategory = (id: number) => deleteApi(`records-categories/${id}/`)
export const getRecordCategory = (id: number) => get<Category>(`records-categories/${id}/`)

export const getRecordsGroups = (params: GetRecordsGroupsParams) => get<GetRecordsGroupsResponse>(`records-groups/`, { params: { ...params } })
export const createRecordGroup = (body: ShortRecordGroup) => post<ShortRecordGroup>(`records-groups/`, body)
export const updateRecordGroup = (id: number, body: ShortRecordGroup) => patch<ShortRecordGroup>(`records-groups/${id}/`, body)
export const deleteRecordGroup = (body: DeleteRecordGroupBody) => post<ShortRecordGroup>(`records-groups/remove/`, body)

export const getRecordsUnits = (params: { limit?: number; offset?: number }) => get<GetRecordsUnitsResponse>(`records-units/`, { params })
export const createRecordUnit = (body: ShortRecordUnit) => post<ShortRecordUnit>(`records-units/`, body)
export const updateRecordUnit = (id: number, body: ShortRecordUnit) => patch<ShortRecordUnit>(`records-units/${id}/`, body)
export const deleteRecordUnit = (id: number) => deleteApi(`records-units/${id}/`)

export const createRecord = (record: NewRecord) => post<NewRecord>(`records/`, record)
export const deleteRecord = (recordId: number) => deleteApi<NewRecord>(`records/${recordId}/`)
export const updateRecord = (recordId: number, body: NewRecord) => patch<NewRecord>(`records/${recordId}/`, body)

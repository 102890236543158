import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Input,
  Alert,
  AlertIcon,
  Checkbox
} from '@chakra-ui/react'
import { useForm, Controller, useWatch } from 'react-hook-form'
import React, { useEffect, useMemo, useState } from 'react'
import { Category, ModalAction, SelectOption } from '~/types/common'
import { Group } from '~/types/groups'
import { Limit, LimitFields, LimitItemToSend, LimitItemExisted } from '~/types/limits'
import { AsyncSelect } from 'chakra-react-select'
import { GetRecordsGroupsParams, RecordUnit } from '~/types/records'
import { getRecordsUnits } from '~/api/records'
import { getGroupsFromCategory } from '~/hooks/useCategoryGroup'
import { BASE_CATEGORY_NAME, DEFAULT_LIMIT_ITEM } from '~/utils/constants'
import { createLimit as createLimitApi, createLimitItem, updateLimitItem, updateLimit as updateLimitApi, deleteLimitItems, getLimits } from '~/api/limits'
import LimitItemComponent from '~/components/references/LimitItem'

interface Props {
  categoryId: number
  limitData: Limit
  categories: Category[]
  isCreate: boolean
  onUpdate: () => void
  onDelete: (id: number) => void
  onCreate: () => void
  isOpen: boolean
  onClose: () => void
}

const DUPLICATED_LIMIT_ERROR = 'Лимит для выбранной категории уже создан'

export default function LimitModal({ categoryId, limitData, isCreate, categories, onUpdate, onDelete, onCreate, isOpen, onClose }: Props) {
  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitting },
    setValue
  } = useForm<LimitFields>({
    defaultValues: useMemo(() => {
      return limitData
    }, [limitData])
  })

  const [error, setError] = useState('')
  const [groups, setGroups] = useState<Group[]>([])
  const [isManualPrice, setIsManualPrice] = useState(false)
  const [finalPrice, setFinalPrice] = useState(0)

  const items = useWatch({ control, name: 'items' })
  const selectedGroups = useWatch({ control, name: 'groups' })

  const notIgnoredItems = useMemo(() => {
    return items?.length ? items.filter((i) => !i.is_ignored) : []
  }, [items])

  useEffect(() => {
    if (limitData.price) return

    if (notIgnoredItems.length === 0) {
      // setFinalPrice(0)
    } else {
      setFinalPrice(notIgnoredItems.reduce((prev, cur) => prev + (+cur.price * cur.amount! || 0), 0))
    }
  }, [notIgnoredItems, limitData])

  const onIgnoreToggle = (status: boolean) => {
    if (!status) {
      setIsManualPrice(false)
    } else {
      const isAllIgnored = items.filter((i) => i.is_ignored).length + 1 === items.length
      if (isAllIgnored) {
        setIsManualPrice(true)
      }
    }
  }

  const getGroups = async (params: GetRecordsGroupsParams = {}) => {
    if (!categoryId) return []

    const baseCategory = categories.find((cat) => cat.name === BASE_CATEGORY_NAME)!

    const category = [categoryId]
    if (baseCategory && categoryId !== baseCategory.id) {
      category.push(baseCategory.id!)
    }

    const groupsFromCategory = await getGroupsFromCategory(params, baseCategory, [categoryId])
    setGroups(groupsFromCategory)
    return groupsFromCategory
  }

  const searchGroups = (inputValue: string, callback: (options: SelectOption[]) => void) => {
    getGroups({ name: inputValue }).then((updated) => {
      callback(updated)
    })
  }
  const [units, setUnits] = useState<RecordUnit[]>([])

  const [action, setAction] = useState<ModalAction>()

  const getInitialUnits = async () => {
    const { results } = await getRecordsUnits({ limit: 1000 })
    setUnits(results)
  }

  const onSubmit = async (prop?: ModalAction) => {
    setAction(prop)

    const values = getValues()

    const shortenedVersion = {
      ...getValues(),
      category: categoryId,
      groups: getValues().groups!.map((g) => g.id),
      secondary_group: getValues().secondary_group?.id,
      price: isManualPrice ? finalPrice : null,
      name: selectedGroups.length > 1 ? getValues().name : null
    }

    if (prop === 'create') {
      try {
        const { id } = await createLimitApi(shortenedVersion)
        const updatedItems = values.items.map((v) => ({ ...v, unit: v.unit!.id, limit: id })) as LimitItemToSend[]
        await Promise.all(updatedItems.map(createLimitItem))
        return onCreate()
      } catch (e) {
        setError(e as string)
      }
    }
    if (prop === 'update') {
      try {
        await updateLimitApi(limitData.id!, shortenedVersion)
        const updatedItems = values.items.map((v) => ({ ...v, unit: v.unit!.id, limit: limitData.id! })) as LimitItemExisted[]

        const initialItemsIds = (limitData.items as LimitItemExisted[]).map((i) => i.id)
        const currentItemsIds = updatedItems.map((i) => i.id)
        const deletedItemsIds = initialItemsIds.filter((id) => id && !currentItemsIds.includes(id)) as number[]

        if (deletedItemsIds.length) {
          await deleteLimitItems(deletedItemsIds)
        }

        await Promise.all(updatedItems.map((item) => (item?.id ? updateLimitItem(item.id, item) : createLimitItem(item))))

        return onUpdate()
      } catch (e) {
        setError(e as string)
      }
    }
    if (prop === 'delete') {
      const itemsIds = (values.items as LimitItemExisted[]).map((item) => (item?.id ? item.id : null)).filter((i) => i) as number[]
      await deleteLimitItems(itemsIds)

      return onDelete(limitData.id!)
    }
  }

  const onModalOpen = async () => {
    const data = {
      unit: limitData.unit!,
      amount: limitData.amount!,
      groups: limitData.groups!,
      price: limitData.price,
      name: limitData.name,
      items: limitData.items,
      secondary_group: limitData.secondary_group
    } as Limit

    reset(data)
    setFinalPrice(+limitData.price)
    setIsManualPrice(!!limitData.price)

    setError('')
  }

  useEffect(() => {
    if (isOpen) {
      onModalOpen()
    }
  }, [limitData, isOpen])

  useEffect(() => {
    getInitialUnits()
  }, [])

  useEffect(() => {
    if (categoryId && categories.length) {
      getGroups()
    }
  }, [categories, categoryId])

  const addItem = () => {
    const updatedItems = [...items, DEFAULT_LIMIT_ITEM]
    setValue('items', updatedItems)
  }

  const onItemRemove = (index: number) => {
    const initialItems = [...items]
    initialItems.splice(index, 1)
    setValue('items', initialItems)
  }

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
      <ModalOverlay />
      <ModalContent as="form" noValidate>
        <ModalHeader>{isCreate ? 'Добавить' : 'Изменить'} Лимит</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}
            {selectedGroups.length > 1 && (
              <FormControl>
                <FormLabel htmlFor="limitName">Наименование лимита</FormLabel>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="limitName"
                      placeholder="Введите..."
                      value={field.value || ''}
                      onInput={(e) => {
                        return field.onChange(e.currentTarget.value)
                      }}
                    />
                  )}
                />
              </FormControl>
            )}
            <FormControl isInvalid={!!errors?.groups} isRequired>
              <FormLabel htmlFor="groups">Группа (можно выбрать несколько)</FormLabel>
              <Controller
                name="groups"
                control={control}
                rules={{ required: 'Это поле обязательно' }}
                render={({ field }) => (
                  <AsyncSelect<SelectOption>
                    placeholder="Введите..."
                    // @ts-expect-error dunno why erroring
                    isMulti
                    id="groups"
                    ref={field.ref}
                    isSearchable
                    getOptionValue={(option: SelectOption) => `${option.id}`}
                    getOptionLabel={(option: SelectOption) => `${option.name}`}
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue)
                    }}
                    defaultOptions={groups}
                    loadOptions={searchGroups}
                  />
                )}
              />

              <FormErrorMessage>{errors.groups && errors?.groups?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.secondary_group}>
              <FormLabel htmlFor="secondary_group">Сопутствующая группа</FormLabel>
              <Controller
                name="secondary_group"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <AsyncSelect<SelectOption>
                    placeholder="Введите..."
                    id="groups"
                    ref={field.ref}
                    isSearchable
                    getOptionValue={(option: SelectOption) => `${option.id}`}
                    getOptionLabel={(option: SelectOption) => `${option.name}`}
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue)
                    }}
                    defaultOptions={groups}
                    loadOptions={searchGroups}
                  />
                )}
              />

              <FormErrorMessage>{errors.secondary_group && errors?.secondary_group?.message}</FormErrorMessage>
            </FormControl>

            {items?.length
              ? items.map((item, i) => (
                  <LimitItemComponent
                    key={i}
                    index={i}
                    isOnlyItem={items.length === 1}
                    control={control}
                    units={units}
                    errors={errors}
                    onRemove={onItemRemove}
                    onIgnoreToggle={onIgnoreToggle}
                  />
                ))
              : null}

            {/* <Flex justifyContent="flex-end"> */}
            {/*  <IconButton colorScheme="blue" aria-label="Add" icon={<BiPlus />} onClick={addItem} /> */}
            {/* </Flex> */}

            <FormControl isDisabled={!isManualPrice} isRequired>
              <FormLabel htmlFor="cost">Стоимость</FormLabel>
              <Input id="cost" type="number" value={finalPrice} onInput={(e) => setFinalPrice(+e.currentTarget.value.replace(',', '.').replace(/[^\d.-]/g, ''))} />
            </FormControl>

            <FormControl>
              <Checkbox
                isChecked={isManualPrice}
                onChange={(event) => {
                  const status = event.target.checked
                  const updatedItems = items.map((i) => ({ ...i, is_ignored: status }))
                  setValue('items', updatedItems)
                  setIsManualPrice(status)
                }}
              >
                Задать стоимость вручную
              </Checkbox>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          {isCreate ? (
            <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('create'))} isLoading={isSubmitting}>
              Сохранить
            </Button>
          ) : (
            <HStack w="full" justifyContent="space-between">
              <Button colorScheme="red" mr={3} onClick={handleSubmit(() => onSubmit('delete'))} isLoading={action === 'delete' && isSubmitting}>
                Удалить
              </Button>
              <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('update'))} isLoading={action === 'update' && isSubmitting}>
                Сохранить
              </Button>
            </HStack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Image, Box, Flex, IconButton, Stack, HStack, Button } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { Viewer, Worker, LocalizationMap, LoadError } from '@react-pdf-viewer/core'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

import { GrRotateRight, GrRotateLeft, GrFormPrevious, GrFormNext } from 'react-icons/gr'
import { BiPlus, BiMinus } from 'react-icons/bi'

import ruLocale from '@react-pdf-viewer/locales/lib/ru_RU.json'

import { DocMedia } from '~/types/docs'
import { getExtension } from '~/utils/helpers'
import FileUploader from '~/components/doc/FileUploader'
import Loader from '~/components/Loader'

import Xls from '../../assets/images/xls.png'

interface Props {
  isOpen: boolean
  onClose: () => void
  docs: DocMedia[]
  selectedIndex: number
  isMediaLoading: boolean
  onAdd: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default function FileViewer({ isOpen, onClose, docs, selectedIndex, onAdd, isMediaLoading }: Props) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  const [currentIndex, setCurrentIndex] = useState(selectedIndex)
  const [rotateValue, setRotateValue] = useState(0)

  const currentDoc = useMemo(() => docs[currentIndex], [docs, currentIndex])
  const isLastDoc = useMemo(() => currentIndex === docs.length - 1, [docs, currentIndex])
  const isFirstDoc = useMemo(() => currentIndex === 0, [currentIndex])
  const currentDocExtension = useMemo(() => getExtension(currentDoc.file), [currentDoc])
  const isImageToolsDisabled = useMemo(() => currentDocExtension === 'pdf' || currentDocExtension === 'xls' || currentDocExtension === 'xlsx', [currentDocExtension])

  useEffect(() => {
    setRotateValue(0)
  }, [currentIndex])

  useEffect(() => {
    setCurrentIndex(selectedIndex)
  }, [selectedIndex])

  const renderError = (error: LoadError) => {
    let message = ''
    switch (error.name) {
      case 'InvalidPDFException':
        message = 'Документ поврежден'
        break
      case 'MissingPDFException':
        message = 'Документа не существует'
        break
      case 'UnexpectedResponseException':
        message = 'Ошибка сервера'
        break
      default:
        message = 'Ошибка при загрузке документа'
        break
    }

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            backgroundColor: '#e53e3e',
            borderRadius: '0.25rem',
            color: '#fff',
            padding: '0.5rem'
          }}
        >
          {message}
        </div>
      </div>
    )
  }

  const getPreview = (docUrl: string) => {
    if (currentDocExtension === 'pdf') {
      return (
        <Stack alignItems="center">
          <a href={docUrl} target="_blank" rel="noreferrer">
            <Button>Открыть</Button>
          </a>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.js">
            <div
              style={{
                height: '550px',
                width: '900px',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <Viewer fileUrl={docUrl} plugins={[defaultLayoutPluginInstance]} renderError={renderError} localization={ruLocale as unknown as LocalizationMap} />
            </div>
          </Worker>
        </Stack>
      )
    }

    if (currentDocExtension === 'xls' || currentDocExtension === 'xlsx') {
      return (
        <Stack alignItems="center">
          <a href={docUrl} target="_blank" rel="noreferrer">
            <Button>Скачать</Button>
          </a>

          <Image boxSize="550px" objectFit="cover" src={Xls} alt="XLS Preview" />
        </Stack>
      )
    }

    return (
      <Flex justifyContent="center">
        <TransformWrapper initialScale={1}>
          {({ zoomIn, zoomOut }) => (
            <Stack>
              <HStack justifyContent="space-between">
                <Flex className="tools" gap={2}>
                  <IconButton colorScheme="blue" aria-label="Zoom Out" icon={<BiMinus />} onClick={() => zoomOut()} disabled={isImageToolsDisabled} />
                  <IconButton colorScheme="blue" aria-label="Zoom In" icon={<BiPlus />} onClick={() => zoomIn()} disabled={isImageToolsDisabled} />
                </Flex>
                <Flex gap={2}>
                  <IconButton
                    colorScheme="blue"
                    aria-label="Rotate Left"
                    icon={<GrRotateLeft />}
                    onClick={() => setRotateValue(rotateValue - 90)}
                    disabled={isImageToolsDisabled}
                  />
                  <IconButton
                    colorScheme="blue"
                    aria-label="Rotate Right"
                    icon={<GrRotateRight />}
                    onClick={() => setRotateValue(rotateValue + 90)}
                    disabled={isImageToolsDisabled}
                  />
                </Flex>
              </HStack>
              <Box overflow="auto">
                <TransformComponent contentStyle={{ height: '800px', width: '800px' }}>
                  <Image src={docUrl} alt="Image Preview" objectFit="contain" style={{ transform: `rotate(${rotateValue}deg)`, width: '100%', height: '100%' }} />
                </TransformComponent>
              </Box>
            </Stack>
          )}
        </TransformWrapper>
      </Flex>
    )
  }

  const showNext = () => {
    if (!isLastDoc) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const showPrev = () => {
    if (!isFirstDoc) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const onLoadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentIndex(0)
    onAdd(e)
  }

  useEffect(() => {
    return () => setCurrentIndex(0)
  }, [])

  if (!docs.length) {
    return null
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="980px">
        <ModalHeader>
          <ModalCloseButton />
          <Flex mt={8} gap={2}>
            <FileUploader onAdd={onLoadFile} />
          </Flex>
        </ModalHeader>

        <ModalBody maxH="80vh" overflow="auto">
          <HStack gap="3">
            <IconButton colorScheme="yellow" aria-label="Previous" icon={<GrFormPrevious />} onClick={showPrev} disabled={isFirstDoc} />

            <Box>{getPreview(currentDoc.file)}</Box>

            <IconButton colorScheme="yellow" aria-label="Next" icon={<GrFormNext />} onClick={showNext} disabled={isLastDoc} />
          </HStack>
        </ModalBody>

        {isMediaLoading && <Loader />}
      </ModalContent>
    </Modal>
  )
}

import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false
}

const styles = {
  global: {
    Input: {
      _placeholder: {
        color: 'gray.400'
      },
      _disabled: {
        borderColor: 'gray.300',
        backgroundColor: 'gray.200'
      }
    },
    ':not(.chakra-dont-set-collapse) > .chakra-collapse': {
      overflow: 'initial !important'
    }
  }
}

const theme = extendTheme({ config, styles })

export default theme

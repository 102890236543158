import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Input,
  Alert,
  AlertIcon,
  Box,
  Text
} from '@chakra-ui/react'
import { useForm, Controller, NestedValue } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { GetRecordsCategoriesParams, GetRecordsGroupsParams, RecordUnit } from '~/types/records'
import { Category, ModalAction, SelectOption, ShortCategoryAndGroup } from '~/types/common'
import { Group } from '~/types/groups'
import { AsyncSelect } from 'chakra-react-select'
import { getRecordsCategories, getRecordsGroups, getRecordsUnits } from '~/api/records'
import { getPriceRecord } from '~/api/price-records'
import { CreatePriceRecord, PriceRecord } from '~/types/price-record'
import { format } from 'date-fns'
import { BASE_CATEGORY_NAME } from '~/utils/constants'

interface Props {
  priceRecordData: PriceRecord
  isCreate: boolean
  onUpdate: (priceRecord: PriceRecord) => void
  onDelete: (id: number) => void
  onCreate: (newPriceRecord: CreatePriceRecord) => void
  isOpen: boolean
  error: string
  onClose: () => void
}

type Inputs = {
  category: NestedValue<ShortCategoryAndGroup> | null
  group: NestedValue<ShortCategoryAndGroup> | null
  unit: NestedValue<ShortCategoryAndGroup> | null
  quantity: number | null
  price: string | null
}

export default function PriceRecordModal({ priceRecordData, isCreate, onUpdate, onDelete, onCreate, isOpen, onClose, error }: Props) {
  const [fullPriceRecord, setFullPriceRecord] = useState<PriceRecord>()
  const [action, setAction] = useState<ModalAction>()
  const [groups, setGroups] = useState<Group[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [units, setUnits] = useState<RecordUnit[]>([])

  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm<Inputs>({
    defaultValues: useMemo(() => {
      return isCreate ? priceRecordData : fullPriceRecord
    }, [priceRecordData, fullPriceRecord])
  })

  const getPriceRecordFullInfo = async () => {
    const res = await getPriceRecord(priceRecordData.id)
    setFullPriceRecord(res)
  }

  const getUnits = async () => {
    const { results } = await getRecordsUnits({ limit: 1000 })
    setUnits(results)
  }

  const getCategories = async (params: GetRecordsCategoriesParams = {}) => {
    const { results } = await getRecordsCategories({ ...params, is_closed: false, limit: 1000 })
    setCategories(results)
    return results
  }

  const getGroups = async (params: GetRecordsGroupsParams = {}) => {
    const { results } = await getRecordsGroups({ ...params, limit: 1000 })

    setGroups(results)
    return results
  }

  const searchGroups = (inputValue: string, callback: (options: SelectOption[]) => void) => {
    getGroups({ name: inputValue }).then((updated) => {
      callback(updated)
    })
  }

  const searchCategories = (inputValue: string, callback: (options: SelectOption[]) => void) => {
    getCategories({ name: inputValue }).then((updated) => {
      callback(updated)
    })
  }

  const checkCategories = async (value: string) => {
    if (!value) {
      await getCategories()
    }
  }

  const onSubmit = async (prop?: ModalAction) => {
    setAction(prop)

    if (prop === 'create') {
      return onCreate({ unit: getValues().unit!.id!, price: getValues()!.price!, group: getValues()!.group!.id!, category: getValues().category?.id ?? null })
    }
    if (prop === 'update') {
      return onUpdate({ ...priceRecordData, price: getValues().price! })
    }
    if (prop === 'delete') {
      return onDelete(priceRecordData.id!)
    }
  }

  useEffect(() => {
    reset()
  }, [priceRecordData, isOpen])

  useEffect(() => {
    if (isOpen) {
      getGroups()
      getCategories()
      getUnits()

      if (!isCreate) {
        getPriceRecordFullInfo()
      }
    }
  }, [isOpen])

  useEffect(() => {
    reset(priceRecordData)
  }, [priceRecordData, isOpen])

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
      <ModalOverlay />
      <ModalContent as="form" noValidate>
        <ModalHeader>{isCreate ? 'Добавить' : 'Изменить'} позицию прайса</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}
            <FormControl isInvalid={!!errors?.group} isRequired isDisabled={!isCreate}>
              <FormLabel htmlFor="group">Товар / услуга</FormLabel>
              <Controller
                name="group"
                control={control}
                rules={{ required: 'Это поле обязательно' }}
                render={({ field }) => (
                  <AsyncSelect<SelectOption>
                    placeholder="Введите..."
                    id="group"
                    ref={field.ref}
                    isSearchable
                    getOptionValue={(option: SelectOption) => `${option.id}`}
                    getOptionLabel={(option: SelectOption) => `${option.name}`}
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    defaultOptions={groups}
                    loadOptions={searchGroups}
                  />
                )}
              />

              <FormErrorMessage>{errors.group && errors.group.message}</FormErrorMessage>
            </FormControl>
            <HStack>
              <FormControl isInvalid={!!errors?.unit} isRequired isDisabled={!isCreate}>
                <FormLabel htmlFor="unit">Ед. изм</FormLabel>
                <Controller
                  name="unit"
                  control={control}
                  rules={{ required: 'Это поле обязательно' }}
                  render={({ field }) => (
                    <AsyncSelect<SelectOption>
                      placeholder="Введите..."
                      id="unit"
                      ref={field.ref}
                      getOptionValue={(option: SelectOption) => `${option.id}`}
                      getOptionLabel={(option: SelectOption) => `${option.name}`}
                      value={field.value}
                      onChange={(newValue) => field.onChange(newValue)}
                      defaultOptions={units}
                      isSearchable
                    />
                  )}
                />

                <FormErrorMessage>{errors.unit && errors.unit.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.price} isRequired>
                <FormLabel htmlFor="price">Цена</FormLabel>
                <Controller
                  name="price"
                  control={control}
                  rules={{
                    required: 'Это поле обязательно',
                    min: {
                      value: 0,
                      message: 'Не должно быть меньше нуля'
                    }
                  }}
                  render={({ field }) => (
                    <Input placeholder="Введите..." id="price" type="number" value={`${field.value}`} onInput={(e) => field.onChange(+e.currentTarget.value)} />
                  )}
                />

                <FormErrorMessage>{errors.price && errors.price.message}</FormErrorMessage>
              </FormControl>
            </HStack>

            <FormControl isInvalid={!!errors?.category} isDisabled={!isCreate}>
              <FormLabel htmlFor="category">Категория / Объект</FormLabel>
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <AsyncSelect<SelectOption>
                    placeholder="Введите..."
                    id="category"
                    ref={field.ref}
                    getOptionValue={(option: SelectOption) => `${option.id}`}
                    getOptionLabel={(option: SelectOption) => `${option.name}`}
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue)
                    }}
                    onInputChange={checkCategories}
                    defaultOptions={categories.filter((c) => c.name !== BASE_CATEGORY_NAME)}
                    loadOptions={searchCategories}
                  />
                )}
              />

              <FormErrorMessage>{errors.category && errors.category.message}</FormErrorMessage>
            </FormControl>

            {fullPriceRecord?.price_history?.length && (
              <Box>
                <Text fontSize="md" fontWeight="600">
                  История изменений
                </Text>
                <Stack>
                  {fullPriceRecord?.price_history.map((price, i, len) => (
                    <Text fontSize="sm" key={i}>
                      {len.length - i}. {format(new Date(price.updated_at), 'dd.MM.yyyy')} {price.old} {'->'} {price.new}
                    </Text>
                  ))}
                </Stack>
              </Box>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          {isCreate ? (
            <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('create'))} isLoading={isSubmitting}>
              Сохранить
            </Button>
          ) : (
            <HStack w="full" justifyContent="space-between">
              <Button colorScheme="red" mr={3} onClick={handleSubmit(() => onSubmit('delete'))} isLoading={action === 'delete' && isSubmitting}>
                Удалить
              </Button>
              <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('update'))} isLoading={action === 'update' && isSubmitting}>
                Сохранить
              </Button>
            </HStack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

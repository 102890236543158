import { Modal, ModalOverlay, ModalContent, ModalHeader, Link, ModalCloseButton, ModalBody, ModalFooter, Button, HStack, Text } from '@chakra-ui/react'
import { GetDocResponse } from '~/types/docs'

interface Props {
  category: GetDocResponse['category']
  isOpen: boolean
  onClose: () => void
}

export default function CloneModalSuccess({ category, isOpen, onClose }: Props) {
  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
      <ModalOverlay />
      <ModalContent as="form" noValidate>
        <ModalHeader>Успешное клонирование</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="lg">
            Создан клон документа. Новые документы отображаются <br />
            <Link href={`/documents/${category.id}`} isExternal textDecoration="underline" color="teal.400">
              в корневом разделе
            </Link>
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack w="full" justifyContent="flex-end">
            <Button colorScheme="green" onClick={onClose}>
              Ок
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import React, { useMemo } from 'react'
import { Box, Flex, Stack } from '@chakra-ui/react'
import { GroupTypesKey, GroupTypesSignature } from '~/types/groups'
import { GROUP_TYPES_LABEL } from '~/utils/constants'
import { getNumberWithSpaces } from '~/utils/helpers'

interface Props {
  totalGroupPrice: number
  isGroupsTotalVisible: boolean
  groupTypeTotalPrice: GroupTypesSignature<number>
  totalGroupTakings: number
  isAdditionalTotalVisible?: boolean
  noContributionMargin?: boolean
  customPlannedTakings?: number | null
  isCustomTakingsChecked?: boolean
}

export default function TotalCount({
  totalGroupPrice,
  isGroupsTotalVisible,
  groupTypeTotalPrice,
  totalGroupTakings,
  isAdditionalTotalVisible = true,
  noContributionMargin = false,
  customPlannedTakings = null,
  isCustomTakingsChecked = false
}: Props) {
  const customTotalGroupTakings = useMemo(() => {
    if (customPlannedTakings && isCustomTakingsChecked) return customPlannedTakings
    return totalGroupTakings
  }, [customPlannedTakings, isCustomTakingsChecked, totalGroupTakings])

  const totalSum = useMemo(() => {
    const groupPrice = totalGroupPrice

    const basePrice = `${parseInt(`${groupPrice}`, 10)}`.split('.')[0]
    const restOfPrice = `${groupPrice}`.split('.')[1]?.slice(0, 2) || ''

    return `${getNumberWithSpaces(basePrice)}.${+restOfPrice}`
  }, [totalGroupPrice])

  const contributionMargin = useMemo(() => {
    if (noContributionMargin) return ''

    const income = customTotalGroupTakings - totalGroupPrice
    const percent = ((customTotalGroupTakings - totalGroupPrice) * 100) / +customTotalGroupTakings

    return `${getNumberWithSpaces(income.toFixed(2))} (${Number.isFinite(percent) ? percent.toFixed(2) : 0}%)`
  }, [noContributionMargin, totalGroupPrice, customTotalGroupTakings])

  const plannedTakings = useMemo(() => {
    if (customPlannedTakings && isCustomTakingsChecked) return getNumberWithSpaces(customPlannedTakings || '0', true).toString()

    const baseTakings = `${parseInt(`${totalGroupTakings}`, 10)}`.split('.')[0]
    const restOfTakings = `${totalGroupTakings}`.split('.')[1]?.slice(0, 2) || ''

    return `${getNumberWithSpaces(baseTakings)}.${+restOfTakings}`
  }, [customPlannedTakings, isCustomTakingsChecked, totalGroupTakings])

  return (
    <Stack>
      <Box textAlign="end" marginTop={2} fontWeight={600}>
        Итого: {totalSum}
      </Box>
      {isAdditionalTotalVisible && (
        <>
          {isGroupsTotalVisible && (
            <Box>
              <Box fontWeight={600} textAlign="right">
                В том числе:
              </Box>
              {(Object.keys(groupTypeTotalPrice) as GroupTypesKey[]).map((key) => (
                <Flex key={key} gap={2} justifyContent="flex-end">
                  <Box fontWeight={600}>{GROUP_TYPES_LABEL[key]}:</Box>
                  <Box>{getNumberWithSpaces(groupTypeTotalPrice[key] || 0, true)}</Box>
                </Flex>
              ))}
            </Box>
          )}

          <Box textAlign="end" marginTop={2} fontWeight={600}>
            Плановая выручка: {plannedTakings}
          </Box>
          <Box textAlign="end" marginTop={2} fontWeight={600}>
            Маржинальная прибыль: {contributionMargin}
          </Box>
        </>
      )}
    </Stack>
  )
}

TotalCount.defaultProps = {
  isAdditionalTotalVisible: true,
  noContributionMargin: false,
  isCustomTakingsChecked: false,
  customPlannedTakings: null
}

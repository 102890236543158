import axios, { AxiosRequestHeaders } from 'axios'

import { getTgTokenRefresh, logOut } from '~/api/auth'
import { localStorageGetValue, localStorageSetValue } from '~/utils/localStorage'

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DOMAIN}/api` : `${process.env.REACT_APP_DOMAIN_DEV}/api`

axios.interceptors.request.use(async (requestConfig) => {
  const token = localStorageGetValue('token')

  const headers: AxiosRequestHeaders = {
    ...requestConfig.headers,
    'Content-Type': 'application/json'
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return { ...requestConfig, headers }
})

axios.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      logOut()
    }
    return response
  },
  async (error) => {
    const { config, response } = error

    const errorCode = response.data?.code

    if (error.response.status === 403 && errorCode === 'token_not_valid' && !config._retry) {
      config._retry = true
      const refreshToken = localStorageGetValue<string>('refresh')

      if (refreshToken) {
        try {
          const { access } = await getTgTokenRefresh(refreshToken)
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${access}`
          }

          localStorageSetValue('token', access)

          return axios(config)
        } catch (e) {
          logOut()
        }
      } else {
        logOut()
      }
    }

    const firstKey = Object.keys(response?.data)[0]

    if (firstKey) {
      return Promise.reject(response.data[firstKey][0])
    }
  }
)

import { Box, CloseButton, Flex, Text, BoxProps } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'

import { FiBook, FiUsers, FiFileText, FiCheckSquare } from 'react-icons/fi'
import { Path } from '~/types/router'
import { NavItemProps } from '~/types/sidebar'
import { CategoriesContext } from '~/utils/context'
import NavItem from './NavItem'

interface SidebarProps extends BoxProps {
  onClose: () => void
}

export default function SidebarContent({ onClose, ...rest }: SidebarProps) {
  const { categories } = useContext(CategoriesContext)

  const [linkItems, setLinkItems] = useState<NavItemProps[]>([
    { name: 'Пользователи', href: Path.USERS, icon: FiUsers },
    {
      name: 'Документы',
      href: '',
      icon: FiFileText,
      subLinks: []
    },
    {
      name: 'Справочники',
      href: '',
      icon: FiBook,
      subLinks: [
        { name: 'Организации', href: Path.REF_ORG },
        { name: 'Объекты', href: Path.REF_CATEGORIES },
        { name: 'Группы', href: Path.REF_GROUPS },
        { name: 'Единицы измерения', href: Path.REF_UNITS }
      ],
      isCollapsed: true
    },
    {
      name: 'Отчеты',
      href: '',
      icon: FiCheckSquare,
      subLinks: [
        { name: 'Механизаторы', href: Path.REP_MECHANICS },
        { name: 'Объекты', href: Path.REP_OBJECTS },
        { name: 'Перевыставить', href: Path.RECORD_SET }
      ]
    }
  ])

  const getDocsCategories = async () => {
    const updatedResults = categories.map((res) => ({ href: `documents/${res.id}`, ...res }))
    const updatedLinks = linkItems.map((link) => (link.name === 'Документы' ? { ...link, subLinks: updatedResults } : link))
    setLinkItems(updatedLinks)
  }

  useEffect(() => {
    getDocsCategories()
  }, [categories])

  return (
    <Box
      transition="3s ease"
      bg="gray.900"
      borderRight="1px"
      borderRightColor="gray.700"
      color="white"
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      overflowY="auto"
      {...rest}
    >
      <Flex h="20" alignItems="center" pr="4" pl="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          Megagon
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {linkItems.map((link) => (
        <NavItem isCollapsed={!!link?.isCollapsed} icon={link.icon} key={link.name} href={link.href} subLinks={link.subLinks || []} name={link.name} />
      ))}
    </Box>
  )
}

import React from 'react'
import { Tr, Td, TableContainer, Table, Tbody } from '@chakra-ui/react'
import { Record } from '~/types/records'
import { getNumberWithSpaces } from '~/utils/helpers'

interface Props {
  isOpen: boolean
  records: Record[]
}

export default function DocsRecordsPreview({ isOpen, records }: Props) {
  return isOpen ? (
    <Tr>
      <Td colSpan={2} />
      <Td colSpan={6} sx={{ padding: 0 }}>
        <TableContainer>
          <Table variant="simple" colorScheme="blackAlpha">
            <Tbody>
              {records.map((record) => (
                <Tr key={record.id} sx={{ backgroundColor: 'white' }}>
                  <Td>{record.category?.name}</Td>
                  <Td>{record.group?.name}</Td>
                  <Td sx={{ textAlign: 'end' }}>
                    {record.quantity} {record.unit?.name}
                  </Td>
                  <Td sx={{ textAlign: 'end' }}>{record.price}</Td>
                  <Td sx={{ textAlign: 'end' }}>{getNumberWithSpaces(+(record.price || 0) * (record.quantity || 1), true)}</Td>
                  <Td />
                  <Td />
                  <Td />
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Td>
    </Tr>
  ) : null
}

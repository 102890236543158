import { Center, Text } from '@chakra-ui/react'

export default function EmptyResults({ text }: { text?: string }) {
  return (
    <Center>
      <Text fontSize="xl" color="gray.500">
        {text}
      </Text>
    </Center>
  )
}

EmptyResults.defaultProps = {
  text: 'Поиск не дал результатов...'
}

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  HStack,
  FormErrorMessage,
  Radio,
  RadioGroup
} from '@chakra-ui/react'
import { AsyncSelect, Select } from 'chakra-react-select'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Category, IdAndNameObject, ModalAction, SelectOption, ShortCategoryAndGroup } from '~/types/common'
import { Org } from '~/types/organizations'
import { GetRecordsCategoriesParams, MaxGroupQuantity, NewRecord, Record as RecordType, RecordUnit } from '~/types/records'
import { getRecordsCategories, getRecordsUnits } from '~/api/records'
import { useForm, Controller, NestedValue } from 'react-hook-form'
import { getPriceRecords as getPriceRecordsApi } from '~/api/price-records'
import { PriceRecord } from '~/types/price-record'
import { BASE_CATEGORY_NAME } from '~/utils/constants'
import { useCategoryGroup } from '~/hooks/useCategoryGroup'
import { useGetOrganizations } from '~/hooks/useGetOrganizations'
import { Group } from '~/types/groups'
import { getLimits, getLimits as getLimitsApi } from '~/api/limits'
import { Limit } from '~/types/limits'

interface Props {
  docId: number
  supplierId: number | null
  record: RecordType
  isCreate: boolean
  onUpdate: (recordId: number, newRecord: NewRecord) => void
  onDelete: (id: number) => void
  onCreate: (newRecord: NewRecord) => void
  isOpen: boolean
  isRecordsSet: boolean
  onClose: () => void
  selectedRecordsGroupIds?: RecordType['id'][]
  maxQuantityGroups?: MaxGroupQuantity
}

type Inputs = {
  client: NestedValue<ShortCategoryAndGroup> | null
  category: NestedValue<ShortCategoryAndGroup> | null
  group: NestedValue<ShortCategoryAndGroup> | null
  unit: NestedValue<ShortCategoryAndGroup> | null
  group_limit: NestedValue<IdAndNameObject> | null
  quantity: number | null
  price: string | null
}

export default function RecordModal({
  record,
  isCreate,
  onClose,
  isOpen,
  onDelete,
  onUpdate,
  onCreate,
  docId,
  supplierId,
  isRecordsSet,
  selectedRecordsGroupIds,
  maxQuantityGroups
}: Props) {
  const {
    reset,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<Inputs>({
    defaultValues: useMemo(() => {
      return record
    }, [record])
  })

  const [categories, setCategories] = useState<Category[]>([])
  const [units, setUnits] = useState<RecordUnit[]>([])
  const [initialUnits, setInitialUnits] = useState<RecordUnit[]>([])
  const [action, setAction] = useState<ModalAction>()
  const [executors, setExecutors] = useState<Org[]>([])
  const [initialOrganizations, setInitialOrganizations] = useState<Org[]>([])
  const [changedGroup, changedCategory, changedUnit] = watch(['group', 'category', 'unit'])
  const [isPriceRecordsWithSelectedCategoryCalled, setIsPriceRecordsWithSelectedCategoryCalled] = useState(false)
  const [isPriceRecordsWithBaseCategoryCalled, setIsPriceRecordsWithBaseCategoryCalled] = useState(false)
  const [isRecordsLoading, setIsRecordsLoading] = useState(false)
  const [priceRecords, setPriceRecords] = useState<PriceRecord[]>([])
  const [secondaryLimits, setSecondaryLimits] = useState<Limit[]>([])

  const [groups, getGroups, searchCategories, checkCategories] = useCategoryGroup(getValues().category, categories)
  const [organizations, loadOrganizations, getOrganizatons, setOrganizations] = useGetOrganizations()

  const [groupsFromLimits, setFetchedGroups] = useState<Group[]>([])

  const filterGroups = useCallback(
    (gr: Group[]) => {
      if (!selectedRecordsGroupIds?.length) return []
      // if (!selectedRecordsGroupIds?.length) return gr

      return gr.filter((g) => selectedRecordsGroupIds.includes(g.id))
    },
    [selectedRecordsGroupIds]
  )

  const onGetRecordsLimits = async () => {
    if (!changedGroup) return
    const selectedCategoryId = getValues().category?.id

    // const mockLimits = [
    //   {
    //     id: 251,
    //     name: null,
    //     price: null,
    //     items: [
    //       {
    //         id: 248,
    //         unit: {
    //           id: 2,
    //           name: 'т.'
    //         },
    //         price: '4770.00',
    //         amount: 24,
    //         is_ignored: false
    //       }
    //     ],
    //     groups: [
    //       {
    //         id: 127,
    //         name: 'Асфальт Б2',
    //         type: 'goods',
    //         takings: '0.00',
    //         category: 5,
    //         is_removed: false
    //       }
    //     ],
    //     category: {
    //       id: 79,
    //       name: 'СНТ Родник'
    //     },
    //     real_name: 'Асфальт Б2',
    //     total_price: 114480,
    //     secondary_group: null
    //   },
    //   {
    //     id: 252,
    //     name: null,
    //     price: null,
    //     items: [
    //       {
    //         id: 249,
    //         unit: {
    //           id: 2,
    //           name: 'т.'
    //         },
    //         price: '500.00',
    //         amount: 24,
    //         is_ignored: false
    //       }
    //     ],
    //     groups: [
    //       {
    //         id: 28,
    //         name: 'Транспорт. Доставка асфальта',
    //         type: 'goods',
    //         takings: '0.00',
    //         category: 5,
    //         is_removed: false
    //       }
    //     ],
    //     category: {
    //       id: 79,
    //       name: 'СНТ Родник'
    //     },
    //     real_name: 'Транспорт. Доставка асфальта',
    //     total_price: 12000,
    //     secondary_group: null
    //   },
    //   {
    //     id: 253,
    //     name: null,
    //     price: null,
    //     items: [
    //       {
    //         id: 250,
    //         unit: {
    //           id: 5,
    //           name: 'м2'
    //         },
    //         price: '350.00',
    //         amount: 170,
    //         is_ignored: false
    //       }
    //     ],
    //     groups: [
    //       {
    //         id: 138,
    //         name: 'Субподряд. Разнорабочие',
    //         type: 'subcontract',
    //         takings: '0.00',
    //         category: 5,
    //         is_removed: false
    //       }
    //     ],
    //     category: {
    //       id: 79,
    //       name: 'СНТ Родник'
    //     },
    //     real_name: 'Субподряд. Разнорабочие',
    //     total_price: 59500,
    //     secondary_group: null
    //   },
    //   {
    //     id: 254,
    //     name: null,
    //     price: null,
    //     items: [
    //       {
    //         id: 251,
    //         unit: {
    //           id: 1,
    //           name: 'маш. ч.'
    //         },
    //         price: '3000.00',
    //         amount: 10,
    //         is_ignored: false
    //       }
    //     ],
    //     groups: [
    //       {
    //         id: 19,
    //         name: 'Спецтехника. Экскаватор-погрузчик, типа JCB',
    //         type: 'service',
    //         takings: '0.00',
    //         category: 5,
    //         is_removed: false
    //       }
    //     ],
    //     category: {
    //       id: 79,
    //       name: 'СНТ Родник'
    //     },
    //     real_name: 'Спецтехника. Экскаватор-погрузчик, типа JCB',
    //     total_price: 30000,
    //     secondary_group: null
    //   },
    //   {
    //     id: 255,
    //     name: null,
    //     price: null,
    //     items: [
    //       {
    //         id: 252,
    //         unit: {
    //           id: 1,
    //           name: 'маш. ч.'
    //         },
    //         price: '2900.00',
    //         amount: 10,
    //         is_ignored: false
    //       }
    //     ],
    //     groups: [
    //       {
    //         id: 44,
    //         name: 'Спецтехника. Каток мал.',
    //         type: 'service',
    //         takings: '0.00',
    //         category: 5,
    //         is_removed: false
    //       }
    //     ],
    //     category: {
    //       id: 79,
    //       name: 'СНТ Родник'
    //     },
    //     real_name: 'Спецтехника. Каток мал.',
    //     total_price: 29000,
    //     secondary_group: null
    //   },
    //   {
    //     id: 256,
    //     name: null,
    //     price: null,
    //     items: [
    //       {
    //         id: 253,
    //         unit: {
    //           id: 4,
    //           name: 'м3'
    //         },
    //         price: '1250.00',
    //         amount: 10,
    //         is_ignored: false
    //       }
    //     ],
    //     groups: [
    //       {
    //         id: 151,
    //         name: 'Щебень фр. 5-20',
    //         type: 'goods',
    //         takings: '0.00',
    //         category: 5,
    //         is_removed: false
    //       }
    //     ],
    //     category: {
    //       id: 79,
    //       name: 'СНТ Родник'
    //     },
    //     real_name: 'Щебень фр. 5-20',
    //     total_price: 12500,
    //     secondary_group: null
    //   }
    // ]

    try {
      const { results } = await getLimits({ category: selectedCategoryId, secondary_group: changedGroup.id })

      setSecondaryLimits(results)
      // setSecondaryLimits(mockLimits as unknown as Limit[])
    } catch (e) {
      console.error(e)
    }
  }

  const getLimitsGroups = async () => {
    try {
      const selectedCategoryId = getValues().category?.id

      if (!selectedCategoryId) return []
      const { results } = await getLimitsApi({ category: selectedCategoryId })

      const limitGroups = new Set<Group>()

      results.forEach((limit) => {
        limit.groups.forEach((curGroup) => {
          limitGroups.add(curGroup)
        })
      })

      setFetchedGroups([...limitGroups])
    } catch (e) {
      console.error(e)
      setFetchedGroups([])
    }
  }

  const filteredGroups = useMemo(() => {
    return groupsFromLimits.length ? groupsFromLimits : filterGroups(groups)
  }, [groups, filterGroups, groupsFromLimits])

  const fetchGroups = async (inputValue?: string) => {
    await getLimitsGroups()
    return getGroups({ name: inputValue }, null)
  }

  // const searchFilteredGroups = useCallback(
  //   async (inputValue: string, callback: (options: SelectOption[]) => void) => {
  //     fetchGroups(inputValue).then((updated) => {
  //       const filtered = filterGroups(updated)
  //       callback(filtered)
  //     })
  //     // getGroups({ name: inputValue }).then((updated) => {
  //     //   const filtered = filterGroups(updated)
  //     //   callback(filtered)
  //     // })
  //   },
  //   [fetchGroups, filterGroups]
  // )

  const getPriceRecords = async (params: { category?: number }) => {
    setIsRecordsLoading(true)
    const { results } = await getPriceRecordsApi({ is_archived: false, organization: supplierId!, group: changedGroup!.id!, ...params })
    setPriceRecords(results)
    setIsRecordsLoading(false)
  }

  const getInitialOrganizatons = async () => {
    const initial = await getOrganizatons()

    setInitialOrganizations(initial)
  }

  const getCategories = async (params: GetRecordsCategoriesParams = {}) => {
    const { results } = await getRecordsCategories({ ...params, is_closed: false, limit: 1000 })
    setCategories(results)
    return results
  }

  const getInitialUnits = async () => {
    const { results } = await getRecordsUnits({ limit: 1000 })
    setUnits(results)
    setInitialUnits(results)
  }

  const onSubmit = async (prop?: ModalAction) => {
    setAction(prop)

    const updatedFields = getValues()

    const updated: NewRecord = {
      document: docId,
      category: updatedFields.category!.id,
      client: updatedFields.client?.id || null,
      group: updatedFields.group!.id,
      unit: updatedFields.unit!.id,
      quantity: updatedFields.quantity!,
      group_limit: updatedFields.group_limit?.id || null,
      price: updatedFields.price!
    }

    if (prop === 'create') {
      return onCreate(updated)
    }
    if (prop === 'update') {
      return onUpdate(record.id!, updated)
    }
    if (prop === 'delete') {
      return onDelete(record.id!)
    }
  }

  const searchExecutors = (inputValue: string, callback: (options: SelectOption[]) => void) => {
    if (executors.length) {
      callback(executors)
    } else {
      loadOrganizations(inputValue, callback)
    }
  }

  useEffect(() => {
    if (changedGroup && changedCategory) {
      setIsPriceRecordsWithSelectedCategoryCalled(true)
      getPriceRecords({ category: changedCategory.id })
      onGetRecordsLimits()
    }
  }, [changedGroup, changedCategory])

  useEffect(() => {
    if (priceRecords.length === 0 && isPriceRecordsWithSelectedCategoryCalled) {
      getPriceRecords({ category: 0 })
      setIsPriceRecordsWithSelectedCategoryCalled(false)
      setIsPriceRecordsWithBaseCategoryCalled(true)
    } else if (priceRecords.length === 0 && isPriceRecordsWithBaseCategoryCalled) {
      getPriceRecords({})
      setIsPriceRecordsWithBaseCategoryCalled(false)
    } else if (priceRecords.length === 0 && !isPriceRecordsWithSelectedCategoryCalled && !isPriceRecordsWithBaseCategoryCalled) {
      setUnits(initialUnits)
    } else if (priceRecords.length === 1) {
      const isPriceRecordMatches = priceRecords[0].category?.id === changedCategory?.id
      const isPriceCategoryEmpty = !priceRecords[0].category

      if (isPriceRecordMatches || isPriceCategoryEmpty) {
        setValue('unit', priceRecords[0].unit)
        setValue('price', priceRecords[0].price)
        setUnits([])
      }
    } else if (priceRecords.length > 1) {
      const allUnits = priceRecords.map((p) => ({ id: p.unit!.id, name: p.unit!.name }))

      setUnits(allUnits)
    }
  }, [priceRecords])

  useEffect(() => {
    if (priceRecords.length > 1 && changedUnit) {
      const selectedPriceRecord = priceRecords.find((r) => r.unit!.id === changedUnit.id)

      if (selectedPriceRecord) {
        setValue('price', selectedPriceRecord.price)
      }
    }
  }, [changedUnit])

  useEffect(() => {
    reset(record)
  }, [record, isOpen])

  useEffect(() => {
    if (isRecordsSet) {
      setOrganizations(initialOrganizations)
      return
    }

    if (executors.length > 1) {
      setOrganizations(executors)
      setValue('client', null)
    } else if (executors.length === 1) {
      setValue('client', executors[0])
      setOrganizations(initialOrganizations)
    } else if (executors.length === 0) {
      setOrganizations(initialOrganizations)
      setValue('client', null)
    }
  }, [executors])

  useEffect(() => {
    getInitialOrganizatons()
    getCategories()
    getInitialUnits()

    // if (!isCreate) {
    //   onGetRecordsLimits()
    // }
  }, [])

  const header = () => {
    if (isRecordsSet) {
      return isCreate ? 'Перевыставить отчет' : 'Изменение перевыставленного отчета'
    }

    return isCreate ? 'Добавить позицию учёта' : 'Изменение отчета'
  }

  const maxQuantity = useMemo(() => {
    if (!maxQuantityGroups || !Object.keys(maxQuantityGroups).length || !changedGroup?.id || !changedUnit?.id) return undefined

    try {
      const totalMax = maxQuantityGroups[changedGroup.id][changedUnit.id]

      if (isCreate) {
        return totalMax
      }

      return totalMax + (record?.quantity || 0)
    } catch (e) {
      return undefined
    }
  }, [changedGroup?.id, changedUnit?.id, isCreate, maxQuantityGroups, record?.quantity])

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
      <ModalOverlay />
      <ModalContent as="form" noValidate>
        {/* todo make memo??? */}
        <ModalHeader>{header()}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <FormControl isInvalid={!!errors?.category} isRequired>
              <FormLabel htmlFor="category">Категория / Объект</FormLabel>
              <Controller
                name="category"
                control={control}
                rules={{ required: 'Это поле обязательно' }}
                render={({ field }) => (
                  <AsyncSelect<SelectOption>
                    placeholder="Введите..."
                    id="category"
                    ref={field.ref}
                    getOptionValue={(option: SelectOption) => `${option.id}`}
                    getOptionLabel={(option: SelectOption) => `${option.name}`}
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue)
                      setValue('group', null)
                      fetchGroups()

                      setExecutors(newValue?.executors || [])

                      setValue('group', null)
                    }}
                    onInputChange={checkCategories}
                    defaultOptions={categories.filter((c) => c.name !== BASE_CATEGORY_NAME)}
                    loadOptions={searchCategories}
                  />
                )}
              />

              <FormErrorMessage>{errors.category && errors.category.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors?.client} isRequired={!isRecordsSet}>
              <FormLabel htmlFor="recordReceiver">Получатель (заказчик)</FormLabel>
              <Controller
                name="client"
                control={control}
                rules={{ required: 'Это поле обязательно' }}
                render={({ field }) => (
                  <AsyncSelect<SelectOption>
                    placeholder="Введите..."
                    id="client"
                    ref={field.ref}
                    isSearchable
                    getOptionValue={(option: SelectOption) => `${option.id}`}
                    getOptionLabel={(option: SelectOption) => `${option.name}`}
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    defaultOptions={organizations}
                    loadOptions={searchExecutors}
                  />
                )}
              />
            </FormControl>
            <FormControl isInvalid={!!errors?.group} isRequired isDisabled={!(getValues().category && getValues().category!.id)}>
              <FormLabel htmlFor="group">Группа</FormLabel>
              <Controller
                name="group"
                control={control}
                rules={{ required: 'Это поле обязательно' }}
                render={({ field }) => (
                  <Select<SelectOption>
                    placeholder="Введите..."
                    id="group"
                    ref={field.ref}
                    isSearchable
                    getOptionValue={(option: SelectOption) => `${option.id}`}
                    getOptionLabel={(option: SelectOption) => `${option.name}`}
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    options={filteredGroups}
                  />
                )}
              />

              <FormErrorMessage>{errors.group && errors.group.message}</FormErrorMessage>
            </FormControl>

            {secondaryLimits.length && (
              <FormControl isInvalid={!!errors?.group_limit}>
                <FormLabel>Выберите лимит для указанной группы</FormLabel>
                <Controller
                  name="group_limit"
                  control={control}
                  rules={{ required: 'Это поле обязательно' }}
                  render={({ field }) => (
                    <RadioGroup id="group_limit" ref={field.ref} value={field.value?.id}>
                      <Stack>
                        {secondaryLimits.map((limit) => (
                          <Radio
                            key={limit.id}
                            value={limit.id}
                            onChange={() => {
                              field.onChange(limit)
                            }}
                          >
                            {limit.name || limit.real_name}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  )}
                />
                <FormErrorMessage>{errors.group_limit && errors.group_limit.message}</FormErrorMessage>
              </FormControl>
            )}
            <HStack>
              <FormControl isInvalid={!!errors?.unit} isRequired isDisabled={!changedGroup || !changedCategory || isRecordsLoading}>
                <FormLabel htmlFor="unit">Ед. изм</FormLabel>
                <Controller
                  name="unit"
                  control={control}
                  rules={{ required: 'Это поле обязательно' }}
                  render={({ field }) => (
                    <AsyncSelect<SelectOption>
                      placeholder="Введите..."
                      id="unit"
                      ref={field.ref}
                      getOptionValue={(option: SelectOption) => `${option.id}`}
                      getOptionLabel={(option: SelectOption) => `${option.name}`}
                      value={field.value}
                      onChange={(newValue) => field.onChange(newValue)}
                      defaultOptions={units}
                      isSearchable
                    />
                  )}
                />

                <FormErrorMessage>{errors.unit && errors.unit.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.quantity} isRequired>
                <FormLabel htmlFor="quantity">Количество</FormLabel>
                <Controller
                  name="quantity"
                  control={control}
                  rules={{
                    required: true,
                    max: maxQuantity
                  }}
                  render={({ field }) => (
                    <Input
                      placeholder="Введите..."
                      value={field.value ? `${field.value}` : ''}
                      onInput={(e) => {
                        return field.onChange(e.currentTarget.value.replace(',', '.').replace(/[^\d.-]/g, ''))
                      }}
                    />
                  )}
                />

                {errors.quantity && errors.quantity.type === 'required' && <FormErrorMessage>Это поле обязательно</FormErrorMessage>}
                {errors.quantity && errors.quantity.type === 'max' && maxQuantity && <FormErrorMessage>Не должно быть больше {maxQuantity}</FormErrorMessage>}
              </FormControl>

              <FormControl isInvalid={!!errors?.price} isRequired isDisabled={!changedGroup || !changedCategory || !changedUnit || isRecordsLoading}>
                <FormLabel htmlFor="price">Цена</FormLabel>
                <Controller
                  name="price"
                  control={control}
                  rules={{
                    required: 'Это поле обязательно',
                    min: {
                      value: 0,
                      message: 'Не должно быть меньше нуля'
                    }
                  }}
                  render={({ field }) => (
                    <Input
                      id="price"
                      placeholder="Введите..."
                      value={field.value ? `${field.value}` : ''}
                      onInput={(e) => {
                        return field.onChange(e.currentTarget.value.replace(',', '.').replace(/[^\d.-]/g, ''))
                      }}
                    />
                  )}
                />

                <FormErrorMessage>{errors.price && errors.price.message}</FormErrorMessage>
              </FormControl>
            </HStack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          {isCreate ? (
            <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('create'))} isLoading={isSubmitting}>
              Сохранить
            </Button>
          ) : (
            <HStack w="full" justifyContent="space-between">
              <Button colorScheme="red" mr={3} onClick={() => onSubmit('delete')} isLoading={action === 'delete' && isSubmitting}>
                Удалить
              </Button>
              <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('update'))} isLoading={action === 'update' && isSubmitting}>
                Сохранить
              </Button>
            </HStack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

RecordModal.defaultProps = {
  selectedRecordsGroupIds: [],
  maxQuantityGroups: null
}

import { SignInData, SignInResponse, TgTokenData } from '~/types/auth'
import { Path } from '~/types/router'
import { localStorageClear } from '~/utils/localStorage'
import { post } from './base'

export const signIn = (signinData: SignInData) => post<SignInResponse>(`login/`, signinData)
export const getTgToken = (signinData: TgTokenData) => post<SignInResponse>(`oauth/tg-token/`, signinData)
export const getTgTokenRefresh = (refresh: string) => post<SignInResponse>(`oauth/tg-token-refresh/`, { refresh })

export const logOut = () => {
  localStorageClear()
  window.location.replace(Path.LOGIN)
}

import { ChakraProvider } from '@chakra-ui/react'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import customTheme from '~/app/theme'
import '~/assets/index.css'

import { useEffect, useMemo, useState } from 'react'
import { Path } from '~/types/router'

import MainLayout from '~/layouts/MainLayout'
import AuthLayout from '~/layouts/AuthLayout'

import Login from '~/views/Login'
import NotFound from '~/views/NotFound'
import Users from '~/views/Users'
import CreateUser from '~/views/CreateUser'
import UserView from '~/views/UserView'
import Docs from '~/views/Docs'
import DocView from '~/views/DocView'
import OrganizationView from '~/views/Organization'
import Categories from '~/views/referencies/Categories'
import Organizations from '~/views/referencies/Organizations'
import Units from '~/views/referencies/Units'
import Groups from '~/views/referencies/Groups'
import Mechanics from '~/views/reports/Mechanics'
import Objects from '~/views/reports/Objects'
import RecordSet from '~/views/reports/RecordSet'
import { localStorageGetValue } from '~/utils/localStorage'
import { DocsCategoryResponse } from '~/types/docs'
import { CategoriesContext } from '~/utils/context'
import { getDocsCategories } from '~/api/docs'
import CategoryInfoLimits from '~/views/referencies/CategoryInfoLimits'
import { getMe } from '~/api/users'
import { FullUser } from '~/types/users'
import { UserContext } from './utils/userContext'

export function App() {
  const navigate = useNavigate()
  const token = localStorageGetValue('token')
  const [categories, setCategories] = useState<DocsCategoryResponse[]>([])
  const [user, setUser] = useState<null | FullUser>(null)

  const categoriesValue = useMemo(() => ({ categories, setCategories }), [categories])

  const getCategories = async () => {
    const { results } = await getDocsCategories()
    setCategories(results)
  }

  const getUserInfo = async () => {
    try {
      const result = await getMe()
      setUser(result)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (!token) {
      return navigate(Path.LOGIN)
    }
  }, [navigate, token])

  useEffect(() => {
    if (token) {
      getCategories()
      getUserInfo()
    }
  }, [token])

  return (
    <ChakraProvider theme={customTheme}>
      <CategoriesContext.Provider value={categoriesValue}>
        <UserContext.Provider value={user}>
          <Routes>
            <Route path={Path.HOME} element={<MainLayout />}>
              <Route path={Path.HOME} element={<Navigate to={Path.USERS} />} />
              <Route path={Path.USERS} element={<Users />} />
              <Route path={Path.CREATE_USER} element={<CreateUser />} />
              <Route path={Path.USER} element={<UserView />} />
              <Route path={Path.DOCS} element={<Docs />} />
              <Route path={Path.DOC} element={<DocView />} />
              <Route path={Path.REF_ORG} element={<Organizations />} />
              <Route path={Path.REF_CATEGORIES} element={<Categories />} />
              <Route path={Path.REF_UNITS} element={<Units />} />
              <Route path={Path.REF_GROUPS} element={<Groups />} />
              <Route path={Path.REP_MECHANICS} element={<Mechanics />} />
              <Route path={Path.REP_OBJECTS} element={<Objects />} />
              <Route path={Path.ORGANIZATION} element={<OrganizationView />} />
              <Route path={Path.RECORD_SET} element={<RecordSet />} />
              <Route path={Path.REF_CATEGORY_LIMITS} element={<CategoryInfoLimits />} />
            </Route>
            <Route element={<AuthLayout />}>
              <Route path={Path.LOGIN} element={<Login />} />
            </Route>
            <Route path={Path.NOT_FOUND} element={<NotFound />} />
          </Routes>
        </UserContext.Provider>
      </CategoriesContext.Provider>
    </ChakraProvider>
  )
}

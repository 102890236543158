import { Stack, Heading, Box, Input, FormControl, FormLabel, useColorModeValue, Button, Alert, AlertIcon } from '@chakra-ui/react'
import { SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '~/components/Loader'
import { CreateUserError, NewUser } from '~/types/users'
import { createUser } from '~/api/users'
import NumberFormat from 'react-number-format'
import { updateDateForBackend } from '~/utils/helpers'

export default function CreateUser() {
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [formData, setFormData] = useState<NewUser>({
    name: '',
    last_name: '',
    birth_date: '',
    secret_word: ''
  })
  const [error, setError] = useState('')

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault()
    setLoading(true)
    try {
      const updatedDate = updateDateForBackend(formData.birth_date)
      const { id } = await createUser({ ...formData, birth_date: updatedDate })
      navigate(`/users/${id}`)
    } catch (err) {
      const typedError = err as CreateUserError
      const createError = Object.values(typedError.response.data)[0]
      setError(createError[0])
    } finally {
      setLoading(false)
    }
  }

  return (
    <Stack spacing={6}>
      <Heading as="h2" size="lg">
        Создание пользователя
      </Heading>

      <Box as="form" position="relative" rounded="lg" bg={useColorModeValue('white', 'gray.700')} border="1px" borderColor="gray.200" p={8} onSubmit={submit}>
        {isLoading && <Loader />}
        <Stack spacing={3}>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <FormControl isRequired>
            <FormLabel htmlFor="name">Имя</FormLabel>
            <Input id="name" type="text" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="surname">Фамилия</FormLabel>
            <Input id="surname" type="text" value={formData.last_name} onChange={(e) => setFormData({ ...formData, last_name: e.target.value })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="birthday">Дата рождения</FormLabel>
            <Input
              as={NumberFormat}
              id="birthday"
              format="##.##.####"
              placeholder="DD/MM/YYYY"
              type="text"
              value={formData.birth_date}
              onChange={(e) => setFormData({ ...formData, birth_date: e.target.value })}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="secretWord">Секретное слово</FormLabel>
            <Input id="secretWord" type="text" value={formData.secret_word} onChange={(e) => setFormData({ ...formData, secret_word: e.target.value })} />
          </FormControl>
        </Stack>
        <Stack alignItems="flex-end" mt="7">
          <Button colorScheme="green" type="submit">
            Сохранить
          </Button>
        </Stack>
      </Box>
    </Stack>
  )
}

import { Box, Center, CircularProgress } from '@chakra-ui/react'

export default function Loader() {
  return (
    <Center position="absolute" top="0" bottom="0" right="0" left="0">
      <Box position="absolute" top="0" bottom="0" right="0" left="0" zIndex="2" bg="gray.100" opacity="0.5" />
      <CircularProgress
        position="absolute"
        zIndex="3"
        top="0"
        bottom="0"
        left="0"
        right="0"
        margin="auto"
        height="20px"
        width="20px"
        opacity="1"
        isIndeterminate
        color="green.300"
      />
    </Center>
  )
}

import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Stack, HStack, Button, FormControl, Switch, FormLabel, Text } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { FiLink, FiCheckCircle } from 'react-icons/fi'
import { MaxGroupQuantity, NewRecord, Record } from '~/types/records'
import { getNumberWithSpaces, getSum } from '~/utils/helpers'
import { useCallbackPrompt } from '~/hooks/useCallbackPrompt'
import LeavePrompt from '~/components/LeavePrompt'
import { deleteRecordsSet, createRecordSet, getRecordsSets, updateRecordSet } from '~/api/records-sets'
import { updateDoc } from '~/api/docs'
import { useOpenRecordModal } from '~/hooks/useOpenRecordModal'
import { Doc } from '~/types/docs'
import RecordModal from './RecordModal'

interface Props {
  docId: number
  totalPrice: number
  supplierId: number | null
  isRecordsSetHidden: boolean
  isRecordsUpdated: boolean
  selectedRecords: Record[]
  setRecordsUpdated: (status: boolean) => void
  onRecordSetChange: () => void
  isRecordSetRefetch: boolean
  setIsRecordSetRefetch: (isFetched: boolean) => void
}

export default function RecordSet({
  docId,
  totalPrice,
  supplierId,
  isRecordSetRefetch,
  setIsRecordSetRefetch,
  isRecordsSetHidden,
  isRecordsUpdated,
  setRecordsUpdated,
  onRecordSetChange,
  selectedRecords
}: Props) {
  const [isSetHidden, setIsSetHidden] = useState(isRecordsSetHidden)
  const [recordSets, setRecordSets] = useState<Record[]>([])

  const [isOpen, onClose, record, isCreate, openToCreate, openToUpdate, closeModal] = useOpenRecordModal()

  const [watchLeaving, setWatchLeaving] = useState(false)
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(watchLeaving)

  const isSetHiddenDisabled = useMemo(() => {
    const clientsIds = new Set()
    selectedRecords.forEach((r) => {
      clientsIds.add(r.client?.id)
    })

    return [...clientsIds].length > 1
  }, [selectedRecords])

  const getRecords = async () => {
    const { results } = await getRecordsSets({ document: docId })
    setRecordSets(results)
    setIsRecordSetRefetch(false)
  }

  useEffect(() => {
    if (isRecordSetRefetch) {
      getRecords()
    }
  }, [isRecordSetRefetch])

  const preConfirmNavigation = () => {
    setIsSetHidden(true)
    confirmNavigation()
  }

  const updateDocument = async (value: Doc['is_recordset_hidden']) => {
    await updateDoc(docId, { is_recordset_hidden: value })
  }

  const deleteRecord = async (recordId: number) => {
    await deleteRecordsSet(recordId)
    getRecords()
    onClose()
    onRecordSetChange()
  }

  const updateRecord = async (recordId: number, r: NewRecord) => {
    await updateRecordSet(recordId, r)
    getRecords()
    onClose()
    onRecordSetChange()
  }

  const createRecord = async (r: NewRecord) => {
    await createRecordSet(r)
    getRecords()
    onClose()
    onRecordSetChange()
  }

  useEffect(() => {
    if (!isSetHidden) {
      getRecords()
    }
    // setRecordsUpdated(false)
  }, [isSetHidden, isRecordsSetHidden])

  useEffect(() => {
    setIsSetHidden(isRecordsSetHidden)
  }, [isRecordsSetHidden])

  useEffect(() => {
    if (!isSetHidden) {
      const isEveryRecordSetHasClient = recordSets.every((set) => !!set.client?.id)
      setWatchLeaving(!isEveryRecordSetHasClient)
    } else {
      setWatchLeaving(false)
    }
  }, [isSetHidden, recordSets])

  const selectedRecordsGroupIds = useMemo(() => {
    return selectedRecords.map((r) => r.group?.id).filter((i) => !!i) as Record['id'][]
  }, [selectedRecords])

  const getQuantitySum = (records: Record[]) => {
    const max = {} as MaxGroupQuantity

    records.forEach((r) => {
      const recordGroupId = r.group?.id
      const recordUnitId = r.unit?.id
      const recordQuantity = r.quantity || 0

      if (recordGroupId && recordUnitId) {
        if (recordGroupId in max) {
          const maxRecordGroup = max[recordGroupId]
          if (recordUnitId in maxRecordGroup) {
            const maxRecordUnit = maxRecordGroup[recordUnitId]
            max[recordGroupId][recordUnitId] = recordQuantity + maxRecordUnit
          } else {
            max[recordGroupId][recordUnitId] = recordQuantity
          }
        } else {
          max[recordGroupId] = {
            [recordUnitId]: recordQuantity
          }
        }
      }
    })

    return max
  }

  const recordsGroupMaxQuantity = useMemo(() => {
    const maxRecordQuantity = getQuantitySum(selectedRecords)
    const maxRecordSetsQuantity = getQuantitySum(recordSets)

    const max = { ...maxRecordQuantity }

    Object.entries(maxRecordSetsQuantity).forEach(([groupId, units]) => {
      Object.entries(units).forEach(([unitId, unitMaxQuantity]) => {
        if (max[groupId] && max[groupId][unitId]) {
          const recordMaxQuantity = max[groupId][unitId]
          const diff = recordMaxQuantity - unitMaxQuantity
          max[groupId][unitId] = diff
        } else {
          max[groupId] = {
            ...(max[groupId] || {}),
            [unitId]: unitMaxQuantity
          }
        }
      })
    })

    return max
  }, [recordSets, selectedRecords])

  const onChangeIsSetHidden = (value: boolean) => {
    setIsSetHidden(value)
    //
    // if (isSetHidden !== isRecordsSetHidden) {
    //   updateDocument(value)
    // }

    updateDocument(value)
  }

  return (
    <Stack spacing={3}>
      <HStack justifyContent="space-between">
        <FormControl display="flex" alignItems="center" gap={2} mt={3}>
          <FormLabel htmlFor="isSetHidden" mb="0">
            Перевыставить
          </FormLabel>
          <Switch
            id="isSetHidden"
            isChecked={!isSetHidden}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeIsSetHidden(!e.target.checked)}
            disabled={isSetHiddenDisabled}
          />
          {isSetHiddenDisabled && (
            <Text color="red" fontSize="sm" fontWeight="600">
              (функция неактивна, т.к. в учет заведено несколько получателей)
            </Text>
          )}
        </FormControl>
        {!isSetHidden && (
          <Button colorScheme="blue" onClick={openToCreate} isDisabled={!supplierId}>
            Добавить
          </Button>
        )}
      </HStack>
      {!isSetHidden && (
        <TableContainer>
          <Table variant="simple" colorScheme="blackAlpha">
            <Thead>
              <Tr>
                <Th textAlign="center">#</Th>
                <Th>Получатель</Th>
                <Th>Категория / Объект</Th>
                <Th>Группа</Th>
                <Th>Ед. изм</Th>
                <Th>Количество</Th>
                <Th>Цена</Th>
                <Th>Стоимость</Th>
                <Th>
                  <FiLink />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {recordSets.map((r, idx) => (
                <Tr key={r.id}>
                  <Td>
                    <Button colorScheme="blue" fontWeight="bold" variant="link" onClick={() => openToUpdate(r)}>
                      {idx + 1}
                    </Button>
                  </Td>
                  <Td>{r.client?.name ? r.client.name : ''}</Td>
                  <Td>{r.category!.name}</Td>
                  <Td>{r.group!.name}</Td>
                  <Td>{r.unit!.name}</Td>
                  <Td>{r.quantity}</Td>
                  <Td>{r.price}</Td>
                  <Td>{getNumberWithSpaces(getSum(r.quantity!, r.price!), true)}</Td>
                  <Td>{r.subclient ? <FiCheckCircle color="green" /> : ''}</Td>
                </Tr>
              ))}
              <Tr>
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
                <Td />
                <Td>Ит.: {getNumberWithSpaces(totalPrice, true)}</Td>
                <Td />
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <RecordModal
        onClose={closeModal}
        isOpen={isOpen}
        onDelete={deleteRecord}
        onUpdate={updateRecord}
        onCreate={createRecord}
        isCreate={isCreate}
        record={record}
        docId={docId}
        supplierId={supplierId}
        isRecordsSet
        selectedRecordsGroupIds={selectedRecordsGroupIds}
        maxQuantityGroups={recordsGroupMaxQuantity}
      />
      <LeavePrompt actionConfirmed={preConfirmNavigation} isOpen={!!showPrompt} onClose={cancelNavigation} />
    </Stack>
  )
}

import { CreateTransferParams, ExecutionDocTransferResult, GetDocsTransferOptionsResponse, GetDocsTransferResponse } from '~/types/transfers'
import { get, post, patch, deleteApi } from './base'

export const getTransfers = (id: number) => get<GetDocsTransferResponse>(`transfers/`, { params: { document_id: id } })
export const createTransfer = (body: CreateTransferParams) => post<{ id: number }>(`transfers/`, body)
export const updateTransfer = (transferId: number, body: CreateTransferParams) => patch<{ id: number }>(`transfers/${transferId}/`, body)

export const getTransferOptions = (transferId: number) => get<GetDocsTransferOptionsResponse>(`transfer-options/`, { params: { transfer_id: transferId } })
export const executeTransferOptions = (transferId: number, params: { quantity: number }) => post<ExecutionDocTransferResult>(`transfer-options/${transferId}/execute/`, params)
export const deleteTransferOptions = (transferId: number) => deleteApi(`transfer-options/${transferId}/`)

// export const getPriceRecord = (id: number) => get<PriceRecord>(`price-records/${id}`)
// export const createPriceRecord = (body: CreatedPriceRecordToSend) => post<CreatedPriceRecordToSend>(`price-records/`, body)
// export const updatePriceRecord = (id: number, body: Partial<PriceRecordToUpdate>) => patch<PriceRecordToUpdate>(`price-records/${id}`, body)
// export const deletePriceRecord = (id: number) => deleteApi(`price-records/${id}`)

import { TableContainer, Table, Thead, Tr, Th, Tbody, Heading, Stack, Box, Td, Input, Link, Button, useToast } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { DocTransferOption, ExecutionDocTransferResult } from '~/types/transfers'
import { deleteTransferOptions, executeTransferOptions, getTransferOptions } from '~/api/transfers'
import { Link as ReactLink } from 'react-router-dom'
import EmptyResults from '~/components/EmptyResults'
import { ShortCategoryAndGroup } from '~/types/common'

interface Props {
  transferId: number | null
  isNeedUpdate: boolean
  isTransferAvailable: boolean
  selectedGroups: ShortCategoryAndGroup[] | null
  onUpdate: () => void
}

type OptionWithTransferQuantity = DocTransferOption & { transfer_quantity?: number }

interface SortedOptions {
  [key: string]: OptionWithTransferQuantity[]
}

export default function TransferOptions({ transferId, isNeedUpdate, onUpdate, selectedGroups, isTransferAvailable }: Props) {
  const [options, setOptions] = useState<OptionWithTransferQuantity[]>([])

  const addFieldForQuantity = (option: OptionWithTransferQuantity, quantity = 0): OptionWithTransferQuantity => {
    return { ...option, transfer_quantity: quantity }
  }

  const getAllSelectedGroups = () => {
    const sorted: SortedOptions = {}

    selectedGroups?.forEach((g) => {
      sorted[g.name] = []
    })

    return sorted
  }

  const sortedOptions = useMemo(() => {
    const sorted: SortedOptions = getAllSelectedGroups()

    options.forEach((option) => {
      if (sorted[option.record_group.name]) {
        sorted[option.record_group.name] = [...sorted[option.record_group.name], addFieldForQuantity(option, option?.transfer_quantity)]
      } else {
        sorted[option.record_group.name] = [addFieldForQuantity(option, option?.transfer_quantity)]
      }
    })

    return sorted
  }, [options])

  const getOptions = async () => {
    try {
      const { results } = await getTransferOptions(transferId!)
      setOptions(results)
    } catch (e) {
      console.error(e)
    }
  }

  const updateOptionQuantity = (optionToUpdate: OptionWithTransferQuantity, newQuantity: number) => {
    const updatedOptions = options.map((option) => {
      if (optionToUpdate.id === option.id) {
        return { ...option, transfer_quantity: newQuantity <= option.quantity ? newQuantity : option.quantity }
      }
      return option
    })

    setOptions(updatedOptions)
  }

  const updateOptionDocuments = (optionToUpdate: OptionWithTransferQuantity, newDocs: ExecutionDocTransferResult) => {
    const updatedOptions = options.map((option) => {
      if (optionToUpdate.id === option.id) {
        return { ...option, ...newDocs }
      }
      return option
    })

    setOptions(updatedOptions)
  }

  const deleteOption = async (option: OptionWithTransferQuantity) => {
    try {
      await deleteTransferOptions(option.id)

      getOptions()
    } catch (e) {
      console.error(e, 'executeOption')
    }
  }

  const toast = useToast()

  const checkAvailability = () => {
    if (!isTransferAvailable) {
      toast({
        title: 'Необходимо выбрать дату документа',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  const executeOption = async (option: OptionWithTransferQuantity) => {
    checkAvailability()

    if (!isTransferAvailable) {
      return
    }

    try {
      const newDocs = await executeTransferOptions(option.id, { quantity: option.transfer_quantity! })

      updateOptionDocuments(option, newDocs)

      getOptions()
    } catch (e) {
      console.error(e, 'executeOption')
    }
  }

  useEffect(() => {
    if (!isNeedUpdate) return
    getOptions()
    onUpdate()
  }, [isNeedUpdate])

  return (
    <Stack spacing={3}>
      {Object.keys(sortedOptions).map((groupName) => (
        <Box mt={3} key={groupName}>
          <Stack spacing={3} justifyContent="space-between">
            <Heading as="h3" size="sm">
              Варианты для перемещения {groupName}
            </Heading>

            {sortedOptions[groupName].length ? (
              <TableContainer>
                <Table variant="simple" colorScheme="blackAlpha">
                  <Thead>
                    <Tr>
                      <Th textAlign="center">#</Th>
                      <Th>Поставщик</Th>
                      <Th>Количество</Th>
                      <Th>Цена</Th>
                      <Th>Ед. изм</Th>
                      <Th>Сколько переместить?</Th>
                      <Th>Плюс</Th>
                      <Th>Минус</Th>
                      <Th />
                    </Tr>
                  </Thead>

                  {sortedOptions[groupName].map((option) => (
                    <Tbody key={option.id}>
                      <Tr>
                        <Td>{option.id}</Td>
                        <Td>{option.supplier.name}</Td>
                        <Td>{option.transfered_quantity ? '' : option.quantity}</Td>
                        <Td>{option.price}</Td>
                        <Td>{option.record_unit.name}</Td>
                        <Td>
                          <Input
                            disabled={!!option.transfered_quantity}
                            value={`${option.transfered_quantity ? option.transfered_quantity : option.transfer_quantity}`}
                            onInput={(e) => updateOptionQuantity(option, +e.currentTarget.value)}
                            placeholder="Введите..."
                            max={option.quantity}
                            type="number"
                          />
                        </Td>
                        <Td>
                          <Link
                            as={ReactLink}
                            color="blue.400"
                            fontWeight="bold"
                            textDecoration="underline"
                            to={`/document/${option.plus_document_id}`}
                            target="_blank"
                            _hover={{ color: 'blue.700' }}
                          >
                            {option.plus_document_id}
                          </Link>
                        </Td>
                        <Td>
                          <Link
                            as={ReactLink}
                            color="blue.400"
                            fontWeight="bold"
                            textDecoration="underline"
                            to={`/document/${option.minus_document_id}`}
                            target="_blank"
                            _hover={{ color: 'blue.700' }}
                          >
                            {option.minus_document_id}
                          </Link>
                        </Td>
                        <Td>
                          {option.transfered_quantity ? (
                            <Button colorScheme="red" fontWeight="bold" onClick={() => deleteOption(option)}>
                              Удалить
                            </Button>
                          ) : (
                            <Button
                              colorScheme="blue"
                              fontWeight="bold"
                              disabled={!option.transfer_quantity && option.transfer_quantity !== 0}
                              onClick={() => executeOption(option)}
                            >
                              Выполнить
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    </Tbody>
                  ))}
                </Table>
              </TableContainer>
            ) : (
              <EmptyResults text="Вариантов для перемещения нет" />
            )}
          </Stack>
        </Box>
      ))}

      {/* <Box> */}
      {/*  <Stack spacing={3} justifyContent="space-between"> */}
      {/*    <Heading as="h3" size="sm"> */}
      {/*      Варианты для перемещения {selectedGroups?.map((g) => g.name).join(', ')} */}
      {/*    </Heading> */}

      {/*    <EmptyResults text="Вариантов для перемещения нет" /> */}
      {/*  </Stack> */}
      {/* </Box> */}
    </Stack>
  )
}

import { Box, Button, Heading, HStack, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { createRecordUnit, deleteRecordUnit, getRecordsUnits, updateRecordUnit } from '~/api/records'
import Loader from '~/components/Loader'
import Pagination from '~/components/Pagination'
import UnitModal from '~/components/references/UnitModal'
import { DefaultRecordUnit, RecordUnit, GetRecordsUnitsParams, ShortRecordUnit } from '~/types/records'

const defaultUnit = { name: '' } as DefaultRecordUnit

export default function Units() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [units, setUnits] = useState<RecordUnit[]>([])
  const [unitData, setUnitData] = useState<RecordUnit | DefaultRecordUnit>(defaultUnit)
  const [totalPages, setTotalPages] = useState(0)

  const getUnits = async (params: GetRecordsUnitsParams = {}) => {
    setIsLoading(true)
    const { results, count } = await getRecordsUnits({ ...params, limit: 20 })

    setUnits(results)
    setTotalPages(Math.ceil(count / 20))
    setIsLoading(false)
  }

  const handlePageClick = (offset: number) => {
    getUnits({ offset })
  }

  const deleteUnit = async (id: number) => {
    await deleteRecordUnit(id)
    await getUnits()
    onClose()
  }

  const updateUnit = async (id: number, newUnit: ShortRecordUnit) => {
    await updateRecordUnit(id, newUnit)
    await getUnits()
    onClose()
  }

  const createUnit = async (newUnit: ShortRecordUnit) => {
    await createRecordUnit(newUnit)
    await getUnits()
    onClose()
  }

  const openToCreate = async () => {
    setIsCreate(true)
    setUnitData(defaultUnit)
    onOpen()
  }

  const openToUpdate = (unit: RecordUnit) => {
    setIsCreate(false)
    setUnitData(unit)
    onOpen()
  }

  useEffect(() => {
    getUnits()
  }, [])

  return (
    <Stack spacing={6}>
      <HStack justifyContent="space-between">
        <Heading as="h2" size="lg">
          Справочники - Единицы измерений
        </Heading>
        <Button colorScheme="green" onClick={openToCreate}>
          Добавить
        </Button>
      </HStack>
      <Box rounded="full" position="relative" minH="70vh">
        {isLoading && <Loader />}
        <TableContainer>
          <Table variant="simple" colorScheme="blackAlpha">
            <Thead>
              <Tr>
                <Th>Наименование</Th>
              </Tr>
            </Thead>
            <Tbody>
              {units.map((unit) => (
                <Tr key={unit.id}>
                  <Td>
                    <Button colorScheme="blue" fontWeight="bold" variant="link" onClick={() => openToUpdate(unit)}>
                      {unit.name}
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <Pagination totalPages={totalPages} handlePageClick={handlePageClick} />
      <UnitModal onClose={onClose} isOpen={isOpen} onDelete={deleteUnit} onUpdate={updateUnit} onCreate={createUnit} isCreate={isCreate} unitData={unitData} />
    </Stack>
  )
}

import { Box, Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, Stack, Switch } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { ChangeEvent, useMemo } from 'react'
import { Category, SelectOption } from '~/types/common'
import { useGetOrganizations } from '~/hooks/useGetOrganizations'
import { deleteRecordCategory, updateRecordCategory } from '~/api/records'
import { AsyncSelect } from 'chakra-react-select'
import { useNavigate } from 'react-router-dom'
import { Path } from '~/types/router'
import ResponsibleUsers from '~/components/inputs/ResponsibleUsers'
import { CategoryInputs } from '~/types/category'

export default function CategoryInfo({ category }: { category: Category }) {
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm<CategoryInputs>({
    defaultValues: useMemo(() => {
      return category
    }, [category])
  })

  const [organizations, loadOrganizations, getOrganizatons] = useGetOrganizations()

  const onSubmit = async () => {
    const orgs = getValues().executors.map((o) => o.id)
    const responsibleUsers = getValues().responsible_users.map((u) => u.id)
    const values = { ...getValues(), executors: orgs, is_main_groups_hidden: category.is_main_groups_hidden, responsible_users: responsibleUsers }

    await updateRecordCategory(category.id!, values)
  }

  const onDelete = async () => {
    if (!category.id) return

    await deleteRecordCategory(category.id)

    return navigate(Path.REF_CATEGORIES)
  }

  return (
    <Box>
      <Stack spacing={3}>
        <FormControl isInvalid={!!errors?.name?.message} isRequired>
          <FormLabel htmlFor="iin">Наименование</FormLabel>
          <Controller
            name="name"
            control={control}
            rules={{
              required: 'Это поле обязательно'
            }}
            render={({ field }) => <Input placeholder="Введите..." id="name" {...field} />}
          />
          <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="executors">Исполнитель</FormLabel>

          <Controller
            name="executors"
            control={control}
            render={({ field }) => (
              <AsyncSelect<SelectOption>
                placeholder="Введите..."
                // @ts-expect-error dunno why erroring
                isMulti
                id="executors"
                ref={field.ref}
                getOptionValue={(option: SelectOption) => `${option.id}`}
                getOptionLabel={(option: SelectOption) => `${option.name}`}
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue)
                }}
                defaultOptions={organizations}
                loadOptions={loadOrganizations}
              />
            )}
          />
          <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="takings">Выручка</FormLabel>
          <Controller
            name="takings"
            control={control}
            render={({ field }) => (
              <Input placeholder="Введите..." id="takings" type="number" value={`${field.value || 0}`} onInput={(e) => field.onChange(+e.currentTarget.value)} />
            )}
          />
        </FormControl>

        <ResponsibleUsers control={control} />

        <FormControl w="auto" display="flex" alignItems="center">
          <FormLabel htmlFor="isClosed" mb="0">
            Завершен
          </FormLabel>
          <Controller
            name="is_closed"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch id="isClosed" isChecked={value} onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)} />
            )}
          />
        </FormControl>

        <HStack justifyContent="space-between" pt="5">
          <Button colorScheme="red" onClick={handleSubmit(() => onDelete())} isLoading={isSubmitting}>
            Удалить
          </Button>
          <Button colorScheme="green" onClick={handleSubmit(() => onSubmit())} isLoading={isSubmitting}>
            Сохранить
          </Button>
        </HStack>
      </Stack>
    </Box>
  )
}

import React, { forwardRef, SyntheticEvent, useRef } from 'react'
import ReactDatePicker, { registerLocale, ReactDatePickerProps } from 'react-datepicker'
import { InputGroup, Input, InputRightElement } from '@chakra-ui/react'
import { FiCalendar } from 'react-icons/fi'
import InputMask, { type Props as InputProps } from 'react-input-mask'
import ru from 'date-fns/locale/ru' // eslint-disable-line import/no-duplicates

import 'react-datepicker/dist/react-datepicker.css'
import '~/assets/chakra-react-datepicker.css'

import { format, isValid } from 'date-fns' // eslint-disable-line import/no-duplicates

registerLocale('ru', ru)

const CustomInput = forwardRef((props: any, ref) => (
  <InputMask mask="99/99/9999" {...props}>
    {(inputProps: InputProps) => <Input ref={ref} {...props} {...inputProps} />}
  </InputMask>
))

CustomInput.displayName = 'DateInput'
const icon = <FiCalendar fontSize="sm" />

interface Props extends ReactDatePickerProps {
  id: string
  selectedDate: Date | null
  onChange: (date: Date, event: SyntheticEvent<any, Event> | undefined) => void
  isClearable?: boolean
  showPopperArrow?: boolean
  showMonthYearPicker?: boolean
  dateFormat?: string
  excludeDates?: Date[]
  defaultDate?: Date
}

function ReactDatePickerComponent({
  id,
  selectedDate,
  onChange,
  isClearable = true,
  showPopperArrow = false,
  showMonthYearPicker = false,
  dateFormat = 'dd/MM/yyyy',
  excludeDates = [],
  defaultDate,
  ...datepickerProps
}: Props) {
  const inputRef = useRef(null)

  return (
    <InputGroup className="datepicker-group">
      <ReactDatePicker
        id={id}
        selected={selectedDate || defaultDate || null}
        onChange={onChange}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        className="react-datapicker__input-text"
        showMonthYearPicker={showMonthYearPicker}
        dateFormat={dateFormat}
        locale="ru"
        excludeDates={excludeDates}
        customInput={<CustomInput inputRef={inputRef} />}
        autoComplete="off"
        {...datepickerProps}
      />
      <InputRightElement color="gray.500" children={icon} />
    </InputGroup>
  )
}

ReactDatePickerComponent.defaultProps = {
  isClearable: true,
  showPopperArrow: false,
  showMonthYearPicker: false,
  dateFormat: 'dd/MM/yyyy',
  excludeDates: [],
  defaultDate: null
}

export default ReactDatePickerComponent

import { Stack, HStack, FormControl, FormLabel, Radio, RadioGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react'
import { ActionMeta, AsyncSelect, Select } from 'chakra-react-select'
import { Category, SelectOption } from '~/types/common'
import { useEffect, useState } from 'react'
import DatePicker from '~/components/DatePicker'
import { User } from '~/types/users'
import { getUsers as getUsersApi } from '~/api/users'
import { ProofOfWorkFilterValues } from '~/types/docs'
import { useGetOrganizations } from '~/hooks/useGetOrganizations'
import { GetRecordsCategoriesParams } from '~/types/records'
import { getRecordsCategories } from '~/api/records'
import { BASE_CATEGORY_NAME } from '~/utils/constants'

interface Props {
  isFilterOpen: boolean
  filterValues: ProofOfWorkFilterValues
  setFilterClose: (status: boolean) => void
  setFilterValues: (filters: ProofOfWorkFilterValues) => void
}

export default function ProofOfWorkFiltersSiedbar({ isFilterOpen, filterValues, setFilterClose, setFilterValues }: Props) {
  const [users, setUsers] = useState<User[]>([])
  const [recordsCategories, setRecordsCategories] = useState<Category[]>([])
  const [organizations, loadOrganizations, getOrganizatons] = useGetOrganizations()

  const getUsers = async () => {
    const { results } = await getUsersApi({ groups: '1' })
    setUsers(results)
    return results
  }

  const getCategories = async (params: GetRecordsCategoriesParams = {}) => {
    const { results } = await getRecordsCategories({ ...params, limit: 1000 })

    setRecordsCategories(results.filter((c) => c.name !== BASE_CATEGORY_NAME))
    return results
  }

  const onAuthorChange = async (selectAction: ActionMeta<User>) => {
    const coauthorAction = selectAction.action

    if (coauthorAction === 'remove-value') {
      const removed = selectAction.removedValue
      const updatedAuthors = filterValues.author!.filter((a) => a.id !== removed.id!)
      setFilterValues({ ...filterValues, author: updatedAuthors })
    }

    if (coauthorAction === 'select-option') {
      const newCoathor = selectAction.option

      if (newCoathor) {
        const updatedAuthors = filterValues.author || []
        updatedAuthors.push(newCoathor)

        setFilterValues({ ...filterValues, author: updatedAuthors })
      }
    }
  }

  // todo: move getOrganizatons to useGetOrg?
  useEffect(() => {
    getOrganizatons()
    getUsers()
    getCategories()
  }, [])

  return (
    <Drawer isOpen={isFilterOpen} placement="right" onClose={() => setFilterClose(true)} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Выберите фильтр</DrawerHeader>

        <DrawerBody>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel htmlFor="record_category">Объект</FormLabel>
              <Select<SelectOption>
                isClearable
                isSearchable
                placeholder="Выберите объект..."
                value={filterValues.record_category}
                getOptionValue={(doc: SelectOption) => `${doc?.id}`}
                getOptionLabel={(doc: SelectOption) => `${doc?.name}`}
                onChange={(newValue) => setFilterValues({ ...filterValues, record_category: newValue ? { id: newValue!.id!, name: newValue!.name! } : null })}
                options={recordsCategories}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="executor">Подрядчик</FormLabel>
              <AsyncSelect<SelectOption>
                id="executor"
                isClearable
                isSearchable
                placeholder="Введите..."
                loadOptions={loadOrganizations}
                defaultOptions={organizations}
                getOptionValue={(org: SelectOption) => `${org?.id}`}
                getOptionLabel={(org: SelectOption) => `${org?.name}`}
                value={filterValues.executor}
                onChange={(newValue) => setFilterValues({ ...filterValues, executor: newValue ? { id: newValue!.id!, name: newValue!.name! } : null })}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="created_at">Дата создания документа</FormLabel>
              <DatePicker
                id="created_at"
                selectedDate={filterValues.created_at ? new Date(filterValues.created_at) : null}
                onChange={(newDate) => setFilterValues({ ...filterValues, created_at: newDate })}
                showPopperArrow
              />
            </FormControl>

            <HStack>
              <FormControl>
                <FormLabel htmlFor="date-gte">С даты</FormLabel>
                <DatePicker
                  id="date-gte"
                  selectedDate={filterValues.date_gte ? new Date(filterValues.date_gte) : null}
                  onChange={(newDate) => setFilterValues({ ...filterValues, date_gte: newDate })}
                  showPopperArrow
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="date">По дату</FormLabel>
                <DatePicker
                  id="date-lte"
                  selectedDate={filterValues.date_lte ? new Date(filterValues.date_lte) : null}
                  onChange={(newDate) => setFilterValues({ ...filterValues, date_lte: newDate })}
                  showPopperArrow
                />
              </FormControl>
            </HStack>

            <FormControl>
              <FormLabel htmlFor="author">Автор</FormLabel>
              <Select<User>
                // @ts-expect-error dunno why erroring
                isMulti
                id="author"
                isSearchable
                placeholder="Введите..."
                options={users}
                getOptionValue={(author: User) => `${author.id}`}
                getOptionLabel={(author: User) => `${author.name} ${author.last_name}`}
                value={filterValues.author}
                onChange={(allValue, selectAction) => onAuthorChange(selectAction)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Обработка завершена</FormLabel>
              <RadioGroup
                onChange={(value: ProofOfWorkFilterValues['is_processed']) => setFilterValues({ ...filterValues, is_processed: value })}
                value={filterValues.is_processed}
              >
                <Stack direction="row">
                  <Radio value="not_defined">Любое значение</Radio>
                  <Radio value="received">Да</Radio>
                  <Radio value="not_received">Нет</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

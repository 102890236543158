import { FormControl, FormLabel, Heading, HStack, Stack, Icon, useToast } from '@chakra-ui/react'
import { Controller, NestedValue, useForm } from 'react-hook-form'
import { Category, SelectOption, ShortCategoryAndGroup } from '~/types/common'
import { useEffect, useMemo, useState } from 'react'
import { useCategoryGroup } from '~/hooks/useCategoryGroup'
import { GetRecordsCategoriesParams } from '~/types/records'
import { getRecordsCategories } from '~/api/records'
import { AsyncSelect } from 'chakra-react-select'
import { BASE_CATEGORY_NAME } from '~/utils/constants'
import { Org } from '~/types/organizations'
import { useGetOrganizations } from '~/hooks/useGetOrganizations'
import { createTransfer, updateTransfer } from '~/api/transfers'
import { DocTransfer } from '~/types/transfers'
import TransferOptions from '~/components/doc/TransferOptions'
import { BsArrowRight } from 'react-icons/bs'

interface Props {
  docId: number
  transfer: DocTransfer | null
  isTransferAvailable: boolean
}

type Inputs = {
  to_record_category: NestedValue<Category> | null
  from_record_category: NestedValue<Category> | null
  to_receiver: Org | NestedValue<ShortCategoryAndGroup> | null
  from_receiver: Org | NestedValue<ShortCategoryAndGroup> | null
  record_groups: NestedValue<ShortCategoryAndGroup>[] | null
}

const isInitialOrg = (org: Org | NestedValue<ShortCategoryAndGroup>): org is Org => {
  return 'own_entity' in org
}

export default function TransferInfo({ docId, transfer, isTransferAvailable }: Props) {
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: useMemo(() => {
      return {
        from_record_category: transfer?.from_record_category || null,
        record_groups: transfer?.record_groups || null,
        to_record_category: transfer?.to_record_category || null,
        to_receiver: transfer?.to_receiver || null,
        from_receiver: transfer?.from_receiver || null
      }
    }, [transfer])
  })

  const [changedFromRecordCategory, changedRecordsGroups, changedToRecordCategory, changedToReceiver, changedFromReceiver] = watch([
    'from_record_category',
    'record_groups',
    'to_record_category',
    'to_receiver',
    'from_receiver'
  ])

  const [isTransferOptionsNeedUpdate, setIsTransferOptionsNeedUpdate] = useState(false)
  const [createdDocTransfer, setCreatedDocTransfer] = useState<number | null>(null)
  const [initialOrganizations, setInitialOrganizations] = useState<Org[]>([])
  const [fromReceivers, setFromReceivers] = useState<Org[]>([])
  const [toReceivers, setToReceivers] = useState<Org[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [groups, getGroups, searchCategories, checkCategories, searchGroups] = useCategoryGroup(changedFromRecordCategory, categories)
  const [fromReceiversOrgs, loadFromReceiversOrganizations, getOrganizatons, setFromReceiversOrganizations] = useGetOrganizations()
  const [toReceiversOrgs, loadToReceiversOrganizations, _, setToReceiversOrganizations] = useGetOrganizations()

  const docTransferId = useMemo(() => {
    if (createdDocTransfer) return createdDocTransfer

    if (transfer) return transfer.id

    return null
  }, [createdDocTransfer, transfer])

  const getCategories = async (params: GetRecordsCategoriesParams = {}) => {
    const { results } = await getRecordsCategories({ ...params, is_closed: false, limit: 1000 })
    setCategories(results)
    return results
  }

  const getInitialOrganizatons = async () => {
    const initial = await getOrganizatons()

    setInitialOrganizations(initial)
  }

  const searchFromReciever = (inputValue: string, callback: (options: SelectOption[]) => void) => {
    if (fromReceivers.length) {
      callback(fromReceivers)
    } else {
      loadFromReceiversOrganizations(inputValue, callback)
    }
  }

  const searchToReceiver = (inputValue: string, callback: (options: SelectOption[]) => void) => {
    if (toReceivers.length) {
      callback(toReceivers)
    } else {
      loadToReceiversOrganizations(inputValue, callback)
    }
  }

  const onCreateTransfer = async () => {
    try {
      const { id } = await createTransfer({
        document: docId,
        from_record_category: changedFromRecordCategory!.id!,
        record_groups: changedRecordsGroups!.map((i) => +i.id),
        to_record_category: changedToRecordCategory!.id!,
        to_receiver: changedToReceiver!.id!,
        from_receiver: changedFromReceiver!.id!
      })

      setCreatedDocTransfer(id)
      setIsTransferOptionsNeedUpdate(true)
    } catch (e) {
      console.error(e)
    }
  }

  const onUpdateTransfer = async () => {
    try {
      const { id } = await updateTransfer(docTransferId!, {
        document: docId,
        from_record_category: changedFromRecordCategory!.id!,
        record_groups: changedRecordsGroups!.map((i) => +i.id),
        to_record_category: changedToRecordCategory!.id!,
        to_receiver: changedToReceiver!.id,
        from_receiver: changedFromReceiver!.id
      })

      setCreatedDocTransfer(id)
      setIsTransferOptionsNeedUpdate(true)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    // if initial value just set
    if (!fromReceivers.length && changedFromReceiver && isInitialOrg(changedFromReceiver)) {
      return
    }

    if (fromReceivers.length > 1) {
      setFromReceiversOrganizations(fromReceivers)
      setValue('from_receiver', null)
    } else if (fromReceivers.length === 1) {
      setValue('from_receiver', fromReceivers[0])
      setFromReceiversOrganizations(initialOrganizations)
    } else if (fromReceivers.length === 0) {
      setFromReceiversOrganizations(initialOrganizations)
      setValue('from_receiver', null)
    }
  }, [fromReceivers, initialOrganizations])

  useEffect(() => {
    // if initial value just set
    if (!toReceivers.length && changedToReceiver && isInitialOrg(changedToReceiver)) return

    if (toReceivers.length > 1) {
      setToReceiversOrganizations(toReceivers)
      setValue('to_receiver', null)
    } else if (toReceivers.length === 1) {
      setValue('to_receiver', toReceivers[0])
      setToReceiversOrganizations(initialOrganizations)
    } else if (toReceivers.length === 0) {
      setToReceiversOrganizations(initialOrganizations)
      setValue('to_receiver', null)
    }
  }, [toReceivers, initialOrganizations])

  useEffect(() => {
    const isAllSelected = changedFromRecordCategory && changedRecordsGroups && changedToRecordCategory && changedToReceiver && changedFromReceiver
    if (!isAllSelected) return

    if (!transfer && !createdDocTransfer) {
      onCreateTransfer()
    } else {
      onUpdateTransfer()
    }
  }, [changedFromRecordCategory, changedRecordsGroups, changedToRecordCategory, changedToReceiver, changedFromReceiver])

  useEffect(() => {
    if (changedFromRecordCategory?.id && !groups.length && categories.length) {
      getGroups()
    }
  }, [changedFromRecordCategory, categories])

  useEffect(() => {
    getInitialOrganizatons()
    getCategories()
  }, [])

  return (
    <Stack spacing={5}>
      <Heading as="h3" size="sm">
        Направления и товар
      </Heading>

      <HStack>
        <Stack flex="1">
          <HStack>
            <FormControl isInvalid={!!errors?.from_record_category} isRequired>
              <FormLabel htmlFor="from_record_category">Объект (откуда)</FormLabel>
              <Controller
                name="from_record_category"
                control={control}
                rules={{ required: 'Это поле обязательно' }}
                render={({ field }) => (
                  <AsyncSelect<SelectOption>
                    placeholder="Введите..."
                    id="from_record_category"
                    ref={field.ref}
                    getOptionValue={(option: SelectOption) => `${option.id}`}
                    getOptionLabel={(option: SelectOption) => `${option.name}`}
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue)
                      setValue('record_groups', null)
                      getGroups()

                      setFromReceivers(newValue?.executors || [])

                      setValue('record_groups', null)
                    }}
                    onInputChange={checkCategories}
                    defaultOptions={categories.filter((c) => c.name !== BASE_CATEGORY_NAME)}
                    loadOptions={searchCategories}
                  />
                )}
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.record_groups} isRequired isDisabled={!(getValues().from_record_category && getValues().from_record_category!.id)}>
              <FormLabel htmlFor="record_groups">Группа</FormLabel>
              <Controller
                name="record_groups"
                control={control}
                rules={{ required: 'Это поле обязательно' }}
                render={({ field }) => (
                  <AsyncSelect<SelectOption>
                    placeholder="Введите..."
                    id="record_groups"
                    ref={field.ref}
                    isSearchable
                    // @ts-expect-error dunno why erroring
                    isMulti
                    getOptionValue={(option: SelectOption) => `${option.id}`}
                    getOptionLabel={(option: SelectOption) => `${option.name}`}
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    defaultOptions={groups}
                    loadOptions={searchGroups}
                  />
                )}
              />
            </FormControl>
          </HStack>

          <FormControl isInvalid={!!errors?.from_receiver} isRequired>
            <FormLabel htmlFor="recordReceiver">Отправитель</FormLabel>
            <Controller
              name="from_receiver"
              control={control}
              rules={{ required: 'Это поле обязательно' }}
              render={({ field }) => (
                <AsyncSelect<SelectOption>
                  placeholder="Введите..."
                  id="from_receiver"
                  ref={field.ref}
                  isSearchable
                  getOptionValue={(option: SelectOption) => `${option.id}`}
                  getOptionLabel={(option: SelectOption) => `${option.name}`}
                  value={field.value}
                  onChange={(newValue) => field.onChange(newValue)}
                  defaultOptions={fromReceiversOrgs}
                  loadOptions={searchFromReciever}
                />
              )}
            />
          </FormControl>
        </Stack>

        <Icon as={BsArrowRight} w={8} h={8} />

        <Stack flex="1">
          <FormControl isInvalid={!!errors?.to_record_category} isRequired>
            <FormLabel htmlFor="to_record_category">Объект (куда)</FormLabel>
            <Controller
              name="to_record_category"
              control={control}
              rules={{ required: 'Это поле обязательно' }}
              render={({ field }) => (
                <AsyncSelect<SelectOption>
                  placeholder="Введите..."
                  id="to_record_category"
                  ref={field.ref}
                  getOptionValue={(option: SelectOption) => `${option.id}`}
                  getOptionLabel={(option: SelectOption) => `${option.name}`}
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue)
                    setToReceivers(newValue?.executors || [])
                  }}
                  onInputChange={checkCategories}
                  defaultOptions={categories.filter((c) => c.name !== BASE_CATEGORY_NAME)}
                  loadOptions={searchCategories}
                />
              )}
            />
          </FormControl>

          <FormControl isInvalid={!!errors?.from_receiver} isRequired>
            <FormLabel htmlFor="to_receiver">Получатель (заказчик)</FormLabel>
            <Controller
              name="to_receiver"
              control={control}
              rules={{ required: 'Это поле обязательно' }}
              render={({ field }) => (
                <AsyncSelect<SelectOption>
                  placeholder="Введите..."
                  id="to_receiver"
                  ref={field.ref}
                  isSearchable
                  getOptionValue={(option: SelectOption) => `${option.id}`}
                  getOptionLabel={(option: SelectOption) => `${option.name}`}
                  value={field.value}
                  onChange={(newValue) => field.onChange(newValue)}
                  defaultOptions={toReceiversOrgs}
                  loadOptions={searchToReceiver}
                />
              )}
            />
          </FormControl>
        </Stack>
      </HStack>

      <TransferOptions
        transferId={docTransferId}
        selectedGroups={changedRecordsGroups}
        isNeedUpdate={isTransferOptionsNeedUpdate}
        isTransferAvailable={isTransferAvailable}
        onUpdate={() => setIsTransferOptionsNeedUpdate(false)}
      />
    </Stack>
  )
}

import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { GetReportsObjectBySupplierItem } from '~/types/reports'
import { getNumberWithSpaces } from '~/utils/helpers'

interface Props {
  object: GetReportsObjectBySupplierItem
  replaceDocFilters: (ids: number[]) => void
}

export default function SupplierObject({ object, replaceDocFilters }: Props) {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton justifyContent="space-between">
          <Stack flex="1" textAlign="left">
            <Text fontSize="md" fontWeight={600}>
              {object.supplier.name}
            </Text>
          </Stack>
          <Text fontSize="sm" marginRight={2} color="black">
            {getNumberWithSpaces(object.total_price, true)}
          </Text>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        {object.results.map((item) => {
          return (
            <Flex key={item.name} gap={2}>
              <Box>{item.name}</Box>
              <Button
                variant="link"
                color="blue.400"
                fontWeight="bold"
                textDecoration="underline"
                _hover={{ color: 'blue.700' }}
                onClick={() => replaceDocFilters(item.documents_ids)}
              >
                {getNumberWithSpaces(item.price, true)}
              </Button>
            </Flex>
          )
        })}
      </AccordionPanel>
    </AccordionItem>
  )
}

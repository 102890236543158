import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Input,
  Switch
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { ShortRecordCategory } from '~/types/records'
import { Category, ModalAction, SelectOption } from '~/types/common'
import { AsyncSelect } from 'chakra-react-select'
import { useGetOrganizations } from '~/hooks/useGetOrganizations'
import ResponsibleUsers from '~/components/inputs/ResponsibleUsers'
import { CategoryInputs } from '~/types/category'

interface Props {
  catData: Category
  isCreate: boolean
  onUpdate: (id: number, newCat: ShortRecordCategory) => void
  onDelete: (id: number) => void
  onCreate: (newCat: ShortRecordCategory) => void
  isOpen: boolean
  onClose: () => void
}

export default function CatModal({ catData, isCreate, onUpdate, onDelete, onCreate, isOpen, onClose }: Props) {
  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm<CategoryInputs>({
    defaultValues: useMemo(() => {
      return catData
    }, [catData])
  })

  const [action, setAction] = useState<ModalAction>()
  const [organizations, loadOrganizations, getOrganizatons] = useGetOrganizations()

  const onSubmit = async (prop?: ModalAction) => {
    setAction(prop)

    const orgs = getValues().executors.map((o) => o.id)
    const values = { ...getValues(), executors: orgs, is_main_groups_hidden: catData.is_main_groups_hidden }

    const responsibleUsers = values.responsible_users.map((u) => u.id)

    if (prop === 'create') {
      return onCreate({ ...values, responsible_users: responsibleUsers })
    }
    if (prop === 'update') {
      return onUpdate(catData.id!, { ...values, responsible_users: responsibleUsers })
    }
    if (prop === 'delete') {
      return onDelete(catData.id!)
    }
  }

  useEffect(() => {
    reset(catData)
  }, [catData, isOpen])

  // todo: add to useGetOrganizations?
  useEffect(() => {
    getOrganizatons()
  }, [])

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
      <ModalOverlay />
      <ModalContent as="form" noValidate>
        <ModalHeader>{isCreate ? 'Добавить' : 'Изменить'} Категорию / Объект</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <FormControl isInvalid={!!errors?.name?.message} isRequired>
              <FormLabel htmlFor="iin">Наименование</FormLabel>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: 'Это поле обязательно'
                }}
                render={({ field }) => <Input placeholder="Введите..." id="iin" {...field} />}
              />
              <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="executors">Исполнитель</FormLabel>

              <Controller
                name="executors"
                control={control}
                render={({ field }) => (
                  <AsyncSelect<SelectOption>
                    placeholder="Введите..."
                    // @ts-expect-error dunno why erroring
                    isMulti
                    id="executors"
                    ref={field.ref}
                    getOptionValue={(option: SelectOption) => `${option.id}`}
                    getOptionLabel={(option: SelectOption) => `${option.name}`}
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue)
                    }}
                    defaultOptions={organizations}
                    loadOptions={loadOrganizations}
                  />
                )}
              />
              <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="takings">Выручка</FormLabel>
              <Controller
                name="takings"
                control={control}
                render={({ field }) => (
                  <Input placeholder="Введите..." id="takings" type="number" value={`${field.value || 0}`} onInput={(e) => field.onChange(+e.currentTarget.value)} />
                )}
              />
            </FormControl>

            <ResponsibleUsers control={control} />

            <FormControl w="auto" display="flex" alignItems="center">
              <FormLabel htmlFor="isClosed" mb="0">
                Завершен
              </FormLabel>
              <Controller
                name="is_closed"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch id="isClosed" isChecked={value} onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)} />
                )}
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          {isCreate ? (
            <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('create'))} isLoading={isSubmitting}>
              Сохранить
            </Button>
          ) : (
            <HStack w="full" justifyContent="space-between">
              <Button colorScheme="red" mr={3} onClick={handleSubmit(() => onSubmit('delete'))} isLoading={action === 'delete' && isSubmitting}>
                Удалить
              </Button>
              <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('update'))} isLoading={action === 'update' && isSubmitting}>
                Сохранить
              </Button>
            </HStack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

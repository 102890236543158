import { Button, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  getPriceRecords as getPriceRecordsApi,
  createPriceRecord as createPriceRecordApi,
  updatePriceRecord as updatePriceRecordApi,
  deletePriceRecord as deletePriceRecordApi
} from '~/api/price-records'
import { CreatePriceRecord, PriceRecord } from '~/types/price-record'
import Loader from '~/components/Loader'
import PriceRecordModal from './PriceRecordModal'

const DEFAULT_PRICE_RECORD = {
  id: 0,
  organization: null,
  category: null,
  group: null,
  unit: null,
  updated_at: '',
  created_at: '',
  price: '',
  is_archived: false,
  price_history: []
}

export default function PriceRecords({ id, activeTab }: { id: number; activeTab: number }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [priceRecords, setPriceRecords] = useState<PriceRecord[]>([])
  const [selectedPriceRecord, setSelectedPriceRecord] = useState<PriceRecord>(DEFAULT_PRICE_RECORD)
  const [isCreate, setIsCreate] = useState(false)
  const [error, setError] = useState('')

  const getPriceRecords = async () => {
    setIsLoading(true)
    const { results } = await getPriceRecordsApi({ organization: id, is_archived: false })
    setPriceRecords(results)
    setIsLoading(false)
  }

  const openToUpdate = async (item: PriceRecord) => {
    setIsCreate(false)
    setError('')
    setSelectedPriceRecord(item)
    onOpen()
  }

  const openToCreate = async () => {
    setIsCreate(true)
    setError('')
    setSelectedPriceRecord(DEFAULT_PRICE_RECORD)
    onOpen()
  }

  const createPriceRecord = async (item: CreatePriceRecord) => {
    try {
      await createPriceRecordApi({ organization: id, ...item })
      await getPriceRecords()
      onClose()
    } catch (e: any) {
      const err = e.response.data.non_field_errors[0]
      setError(err)
    }
  }

  const updatePriceRecord = async (item: PriceRecord) => {
    try {
      await updatePriceRecordApi(item.id, item)
      await getPriceRecords()
      onClose()
    } catch (e: any) {
      const err = e.response.data.iin
      setError(err)
    }
  }

  const archivePriceRecord = async (itemId: number) => {
    try {
      await updatePriceRecordApi(itemId, { is_archived: true })
      await getPriceRecords()
      onClose()
    } catch (e: any) {
      const err = e.response.data.iin
      setError(err)
    }
  }

  useEffect(() => {
    getPriceRecords()
    setError('')
  }, [])

  useEffect(() => {
    if (activeTab === 1) {
      getPriceRecords()
    }
  }, [activeTab])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Stack>
      <Stack alignItems="flex-end">
        <Button colorScheme="green" onClick={openToCreate}>
          Добавить
        </Button>
      </Stack>
      <TableContainer>
        <Table variant="simple" colorScheme="blackAlpha">
          <Thead>
            <Tr>
              <Th>Товар / услуга</Th>
              <Th>Объект</Th>
              <Th>Ед. изм</Th>
              <Th>Цена</Th>
            </Tr>
          </Thead>
          <Tbody>
            {priceRecords.map((item) => (
              <Tr key={item.id}>
                <Td>
                  <Button colorScheme="blue" fontWeight="bold" variant="link" onClick={() => openToUpdate(item)}>
                    {item.group?.name}
                  </Button>
                </Td>
                <Td>{item.category?.name}</Td>
                <Td>{item.unit?.name}</Td>
                <Td>{item.price}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <PriceRecordModal
        onClose={onClose}
        isOpen={isOpen}
        onDelete={archivePriceRecord}
        onUpdate={updatePriceRecord}
        onCreate={createPriceRecord}
        isCreate={isCreate}
        priceRecordData={selectedPriceRecord}
        error={error}
      />
    </Stack>
  )
}

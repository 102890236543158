import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import { createRecordGroup, deleteRecordGroup, getRecordsCategories, getRecordsGroups, updateRecordGroup } from '~/api/records'
import EmptyResults from '~/components/EmptyResults'
import Loader from '~/components/Loader'
import Pagination from '~/components/Pagination'
import GroupModal from '~/components/references/GroupModal'
import { Category, CRUDLoadingState } from '~/types/common'
import { Group } from '~/types/groups'
import { DeleteRecordGroupBody, GetRecordsGroupsParams, ShortRecordGroup } from '~/types/records'
import { BASE_CATEGORY_NAME, DEFAULT_SELECTED_CATEGORY, DEFAULT_SELECTED_GROUP } from '~/utils/constants'
import { FiSearch } from 'react-icons/fi'

export default function Groups() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [loadingState, setLoadingState] = useState<CRUDLoadingState>({
    isDeleting: false,
    isUpdating: false,
    isCreating: false
  })
  const [isCreate, setIsCreate] = useState(false)
  const [groups, setGroups] = useState<Group[]>([])
  const [groupData, setGroupData] = useState<Group>(DEFAULT_SELECTED_GROUP)
  const [categories, setCategories] = useState<Category[]>([])
  const [selectedCategory, setSelectedCategory] = useState<Category>(DEFAULT_SELECTED_CATEGORY)
  const [totalPages, setTotalPages] = useState(0)
  const [baseCategory, setBaseCategory] = useState<Category | null>(null)
  const [pageOffset, setPageOffset] = useState(0)
  const [searchString, setSearchString] = useState<string | undefined>()

  const getGroups = async (params: GetRecordsGroupsParams = {}) => {
    setIsLoading(true)
    const category = [selectedCategory.id!]

    if (baseCategory && selectedCategory.id !== baseCategory.id) {
      category.push(baseCategory!.id!)
    }
    const { results, count } = await getRecordsGroups({ ...params, name: searchString, offset: pageOffset, category: category.join(','), limit: 20 })
    setGroups(results)
    setTotalPages(Math.ceil(count / 20))
    setIsLoading(false)
  }

  const getCategories = async () => {
    const { results } = await getRecordsCategories({ has_groups: true, is_closed: false, limit: 1000 })
    setCategories(results)
  }

  const handlePageClick = (offset: number) => {
    setPageOffset(offset)
  }

  useEffect(() => {
    getGroups()
  }, [pageOffset])

  const deleteGroup = async (body: DeleteRecordGroupBody) => {
    try {
      setLoadingState({ ...loadingState, isDeleting: true })
      await deleteRecordGroup(body)
      await getGroups()
      await getCategories()
      toast({
        title: 'Группа удалена',
        status: 'success'
      })
    } catch (e) {
      toast({
        title: 'Произошла ошибка при удалении группы',
        status: 'error'
      })
    } finally {
      onClose()
      setLoadingState({ ...loadingState, isDeleting: false })
    }
  }

  const updateGroup = async (id: number, newGroup: ShortRecordGroup) => {
    try {
      setLoadingState({ ...loadingState, isUpdating: true })
      await updateRecordGroup(id, newGroup)
      await getGroups()
      toast({
        title: 'Группа обновлена',
        status: 'success'
      })
    } catch (e) {
      toast({
        title: 'Произошла ошибка при обновлении группы',
        status: 'error'
      })
    } finally {
      onClose()
      setLoadingState({ ...loadingState, isUpdating: false })
    }
  }

  const createGroup = async (newGroup: ShortRecordGroup) => {
    try {
      setLoadingState({ ...loadingState, isCreating: true })
      await createRecordGroup(newGroup)
      await getGroups()
      await getCategories()
      toast({
        title: 'Группа создана',
        status: 'success'
      })
    } catch (e) {
      toast({
        title: 'Произошла ошибка при создании группы',
        status: 'error'
      })
    } finally {
      onClose()
      setLoadingState({ ...loadingState, isCreating: false })
    }
  }

  const openToCreate = async () => {
    setIsCreate(true)
    setGroupData(DEFAULT_SELECTED_GROUP)
    onOpen()
  }

  const openToUpdate = (group: Group) => {
    setIsCreate(false)
    setGroupData(group)
    onOpen()
  }

  const getBaseCategory = () => {
    const base = categories.find((cat) => cat.name === BASE_CATEGORY_NAME)!
    setBaseCategory(base)
  }

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    if (baseCategory) {
      setSelectedCategory(baseCategory)
    }
  }, [baseCategory])

  useEffect(() => {
    getBaseCategory()
  }, [categories])

  useEffect(() => {
    if (selectedCategory && selectedCategory.id) {
      getGroups()
    }
  }, [selectedCategory, searchString])

  return (
    <Stack spacing={6}>
      <Heading as="h2" size="lg">
        Справочники - Группы
      </Heading>
      <HStack justifyContent="space-between">
        <InputGroup>
          <Input bg="white" placeholder="Введите наименование группы..." value={searchString} onInput={(e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)} />
          <InputRightElement pointerEvents="none" children={<FiSearch color="gray.300" />} />
        </InputGroup>
        <Button colorScheme="green" onClick={openToCreate}>
          Добавить
        </Button>
      </HStack>
      <Box rounded="full" position="relative" minH="70vh">
        {isLoading && <Loader />}
        {groups.length > 0 ? (
          <TableContainer>
            <Table variant="simple" colorScheme="blackAlpha">
              <Thead>
                <Tr>
                  <Th>Наименование</Th>
                </Tr>
              </Thead>
              <Tbody>
                {groups.map((group) => (
                  <Tr key={group.id}>
                    <Td>
                      <Button colorScheme="blue" fontWeight="bold" variant="link" onClick={() => openToUpdate(group)}>
                        {group.name}
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <EmptyResults />
        )}
      </Box>
      <Pagination totalPages={totalPages} handlePageClick={handlePageClick} />
      <GroupModal
        onClose={onClose}
        isOpen={isOpen}
        onDelete={deleteGroup}
        onUpdate={updateGroup}
        onCreate={createGroup}
        isCreate={isCreate}
        groupData={groupData}
        categories={categories}
        selectedCategory={selectedCategory}
        loadingState={loadingState}
      />
    </Stack>
  )
}

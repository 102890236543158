import { Stack, FormControl, FormLabel, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Switch, HStack } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { Group } from '~/types/groups'
import { ReportsMechanicsFilters } from '~/types/reports-mechanics'
import { User } from '~/types/users'

interface Props {
  isOpen: boolean
  onClose: () => void
  values: ReportsMechanicsFilters
  updateValues: (values: Partial<ReportsMechanicsFilters>) => void
  mechanics: ReportsMechanicsFilters['author'][]
  technicsOptions: ReportsMechanicsFilters['technics'][]
}

export default function MechanicsFilters({ isOpen, values, onClose, mechanics, technicsOptions, updateValues }: Props) {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Выберите фильтр</DrawerHeader>

        <DrawerBody>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel htmlFor="author">Автор (соавтор)</FormLabel>
              <Select<User>
                placeholder="Введите"
                id="author"
                value={values.author}
                getOptionValue={(option: User) => `${option?.id}`}
                getOptionLabel={(option: User) => `${option.name} ${option.last_name}`}
                onChange={(newValue) => updateValues({ author: newValue! })}
                options={mechanics as User[]}
                isClearable
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="technics">Единица техники</FormLabel>
              <Select<Group>
                placeholder="Выберите объект"
                id="technics"
                value={values.technics}
                getOptionValue={(option: Group) => `${option?.id}`}
                getOptionLabel={(option: Group) => `${option.name}`}
                onChange={(newValue) => updateValues({ technics: newValue! })}
                options={technicsOptions as Group[]}
                isClearable
              />
            </FormControl>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Input
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { DefaultRecordUnit, RecordUnit, ShortRecordUnit } from '~/types/records'
import { ModalAction } from '~/types/common'

interface Props {
  unitData: RecordUnit | DefaultRecordUnit
  isCreate: boolean
  onUpdate: (id: number, newUnit: ShortRecordUnit) => void
  onDelete: (id: number) => void
  onCreate: (newUnit: ShortRecordUnit) => void
  isOpen: boolean
  onClose: () => void
}

type Inputs = {
  name: string
}

export default function UnitModal({ unitData, isCreate, onUpdate, onDelete, onCreate, isOpen, onClose }: Props) {
  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm<Inputs>({
    defaultValues: useMemo(() => {
      return unitData
    }, [unitData])
  })

  const [action, setAction] = useState<ModalAction>()

  const onSubmit = async (prop?: ModalAction) => {
    setAction(prop)

    if (prop === 'create') {
      return onCreate(getValues())
    }
    if (prop === 'update') {
      return onUpdate(unitData.id!, getValues())
    }
    if (prop === 'delete') {
      return onDelete(unitData.id!)
    }
  }

  useEffect(() => {
    reset(unitData)
  }, [unitData, isOpen])

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="xl">
      <ModalOverlay />
      <ModalContent as="form" noValidate>
        <ModalHeader>{isCreate ? 'Добавить' : 'Изменить'} Единицы измерения</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <FormControl isInvalid={!!errors?.name?.message} isRequired>
              <FormLabel htmlFor="iin">Наименование</FormLabel>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: 'Это поле обязательно'
                }}
                render={({ field }) => <Input placeholder="Введите..." id="name" {...field} />}
              />
              <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          {isCreate ? (
            <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('create'))} isLoading={isSubmitting}>
              Сохранить
            </Button>
          ) : (
            <HStack w="full" justifyContent="space-between">
              <Button colorScheme="red" mr={3} onClick={handleSubmit(() => onSubmit('delete'))} isLoading={action === 'delete' && isSubmitting}>
                Удалить
              </Button>
              <Button colorScheme="green" onClick={handleSubmit(() => onSubmit('update'))} isLoading={action === 'update' && isSubmitting}>
                Сохранить
              </Button>
            </HStack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

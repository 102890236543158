import React, { useEffect, useState } from 'react'
import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { getNumberWithSpaces } from '~/utils/helpers'
import Loader from '~/components/Loader'
import { Record } from '~/types/records'
import { getRecords as getRecordsApi, deleteRecord as deleteRecordApi } from '~/api/records'
import { mergeDocs, mergeDocsCancel, mergeDocsConfirm } from '~/api/docs'
import { FaTrash } from 'react-icons/fa'
import { Doc } from '~/types/docs'

interface Props {
  docs: Doc[]
  docIds: number[]
  isOpen: boolean
  onClose: () => void
}

export default function MergeDocsModal({ docIds, docs, isOpen, onClose }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const [records, setRecords] = useState<Record[]>([])
  const [mergedDocId, setMergedDocId] = useState<number | null>(null)

  const selectedDocs = docs.filter((doc) => docIds.includes(doc.id))
  const isWithoutSuppliers = selectedDocs.map((d) => d.supplier?.id || null).every((id) => id === null)

  const onMerge = async () => {
    setIsLoading(true)
    try {
      const { id } = await mergeDocs(docIds)
      setMergedDocId(id)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const getRecords = async (docId: number) => {
    const { results } = await getRecordsApi({ document: docId })
    setRecords(results)

    setIsLoading(false)
  }

  useEffect(() => {
    if (mergedDocId) {
      getRecords(mergedDocId)
    }
  }, [mergedDocId])

  useEffect(() => {
    if (isOpen && mergedDocId === null) {
      onMerge()
      return
    }
    if (!isOpen) {
      setMergedDocId(null)
      setRecords([])
      setIsLoading(false)
    }
  }, [isOpen, mergedDocId])

  const onRecordDelete = async (recordId: number) => {
    setIsLoading(true)
    try {
      await deleteRecordApi(recordId)
      await getRecords(mergedDocId!)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const onCancel = async () => {
    try {
      await mergeDocsCancel(mergedDocId!)
      onClose()
    } catch (e) {
      console.error(e)
    }
  }
  const onSubmit = async () => {
    try {
      await mergeDocsConfirm(mergedDocId!)
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  return isWithoutSuppliers ? (
    <Modal isOpen={isOpen} onClose={onCancel} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Выполнить объединение документов?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Документы с id {docIds.join(', ')} будут объединены</ModalBody>
        <ModalFooter>
          <HStack gap={2} justifyContent="flex-end" width="100%">
            <Button colorScheme="red" onClick={onCancel}>
              Отмена
            </Button>
            <Button colorScheme="green" onClick={onSubmit}>
              Выполнить
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onCancel} closeOnOverlayClick={false} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent minWidth="fit-content">
        <ModalHeader>
          Проверьте позиции учета объединенного документа. <br />
          Добавить новые позиции учета можно после завершения объединения при редактировании документа.
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Loader />
          ) : records.length ? (
            <TableContainer>
              <Table variant="simple" colorScheme="blackAlpha">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Получатель</Th>
                    <Th>Категория / Объект</Th>
                    <Th>Группа</Th>
                    <Th>Ед. изм</Th>
                    <Th>Количество</Th>
                    <Th>Цена</Th>
                    <Th>Стоимость</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {records.map((record) => (
                    <Tr key={record.id}>
                      <Td>{record.id}</Td>
                      <Td>{record.client?.name}</Td>
                      <Td>{record.category?.name}</Td>
                      <Td>{record.group?.name}</Td>
                      <Td>{record.unit?.name}</Td>
                      <Td>{record.quantity}</Td>
                      <Td>{record.price}</Td>
                      <Td>{getNumberWithSpaces(+(record.price || 0) * (record.quantity || 1), true)}</Td>
                      <Td>
                        <IconButton colorScheme="red" aria-label="Delete" icon={<FaTrash />} onClick={() => onRecordDelete(record.id)} />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <HStack gap={2} justifyContent="flex-end" width="100%">
            <Button colorScheme="red" onClick={onCancel}>
              Отмена
            </Button>
            <Button colorScheme="green" onClick={onSubmit}>
              Выполнить
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

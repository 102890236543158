import { GetUsersResponse, GetUsersParams, NewUser, FullUser, UpdateUser, UsersFilters } from '~/types/users'
import { get, post, patch } from './base'

export const getUsers = (params?: GetUsersParams) => get<GetUsersResponse>(`users/`, { params })
export const getUser = (id: number) => get<FullUser>(`users/${id}/`)
export const createUser = (newUser: NewUser) => post<{ id: number }>(`users/`, newUser)
export const updateUser = (updatedUser: UpdateUser) => patch<{ id: number }>(`users/${updatedUser.id}/`, updatedUser)
export const getMe = () => get<FullUser>(`users/me/`)

export const getUsersFilters = () => get<UsersFilters>(`users/filters/`)
export const updateUsersFilters = (body: UsersFilters) => patch<UsersFilters>(`users/filters/`, body)

import { ColorModeScript } from '@chakra-ui/react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import './app/axios'

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

root.render(
  <div>
    <ColorModeScript />

    <BrowserRouter>
      <App />
    </BrowserRouter>
  </div>
)

serviceWorker.unregister()
reportWebVitals()

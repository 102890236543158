import { Stack, Heading, Box, useColorModeValue, FormControl, FormLabel, Input, Button, Checkbox, HStack } from '@chakra-ui/react'
import { useEffect, useState, SyntheticEvent } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '~/components/Loader'
import { FullUser } from '~/types/users'
import { getUser as getUserApi, updateUser } from '~/api/users'
import NumberFormat from 'react-number-format'
import { format } from 'date-fns'
import { updateDateForBackend } from '~/utils/helpers'
import { PasswordField } from '~/components/auth/PasswordField'
import { DEFAULT_FULLUSER } from '~/utils/constants'

export default function UserProfile() {
  const [isLoading, setLoading] = useState(false)
  const id = useParams().id!

  const [userData, setUserData] = useState<FullUser>(DEFAULT_FULLUSER)

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault()
    setLoading(true)
    const updatedDate = updateDateForBackend(userData.birth_date)
    await updateUser({ ...userData, birth_date: updatedDate })
    setLoading(false)
  }

  const getUser = async () => {
    setLoading(true)
    const res = await getUserApi(+id)
    const updatedDate = format(new Date(res.birth_date), 'dd.MM.yyyy')

    setUserData({ ...res, birth_date: updatedDate })
    setLoading(false)
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <Stack spacing={6}>
      <Heading as="h2" size="lg">
        Пользователь #{id}
      </Heading>

      <Box as="form" position="relative" rounded="lg" bg={useColorModeValue('white', 'gray.700')} border="1px" borderColor="gray.200" p={8} onSubmit={submit}>
        {isLoading && <Loader />}
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel htmlFor="name">Имя</FormLabel>
            <Input id="name" type="text" value={userData.name ?? ''} onChange={(e) => setUserData({ ...userData, name: e.target.value })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="surname">Фамилия</FormLabel>
            <Input id="surname" type="text" value={userData.last_name ?? ''} onChange={(e) => setUserData({ ...userData, last_name: e.target.value })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="birthday">Дата рождения</FormLabel>
            <Input
              as={NumberFormat}
              id="birthday"
              format="##.##.####"
              placeholder="DD/MM/YYYY"
              type="text"
              value={userData.birth_date ?? '-'}
              onChange={(e) => setUserData({ ...userData, birth_date: e.target.value })}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="secretWord">Секретное слово</FormLabel>
            <Input id="secretWord" type="text" value={userData.secret_word ?? '-'} onChange={(e) => setUserData({ ...userData, secret_word: e.target.value })} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="tgId">Telegram ID</FormLabel>
            <Input id="tgId" type="text" value={userData.tg_id ?? '-'} isDisabled bg="gray.200" />
          </FormControl>
          <FormControl>
            <PasswordField
              initialValue={userData.public_password ?? ''}
              setInputPassword={(p) => setUserData({ ...userData, public_password: p })}
              required={userData.is_web_allowed}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="tgUsername">Telegram Username</FormLabel>
            <Input id="tgUsername" type="text" value={userData.tg_username ?? '-'} isDisabled bg="gray.200" />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="tgFullname">Telegram Full Name</FormLabel>
            <Input id="tgFullname" type="text" value={userData.tg_username ?? '-'} isDisabled bg="gray.200" />
          </FormControl>
          <HStack spacing="10" shouldWrapChildren>
            <FormControl w="auto" display="flex" alignItems="center">
              <Checkbox isChecked={userData.is_banned} onChange={(event) => setUserData({ ...userData, is_banned: event.target.checked })}>
                Доступ заблокирован
              </Checkbox>
            </FormControl>
            <FormControl w="auto" display="flex" alignItems="center">
              <Checkbox isChecked={userData.is_mechanic} onChange={(event) => setUserData({ ...userData, is_mechanic: event.target.checked })}>
                Механизатор
              </Checkbox>
            </FormControl>
            <FormControl w="auto" display="flex" alignItems="center">
              <Checkbox isChecked={userData.is_web_allowed} onChange={(event) => setUserData({ ...userData, is_web_allowed: event.target.checked })}>
                Разрешить доступ к веб-части
              </Checkbox>
            </FormControl>
          </HStack>
        </Stack>
        <Stack alignItems="flex-end" mt="7">
          <Button colorScheme="green" type="submit">
            Сохранить
          </Button>
        </Stack>
      </Box>
    </Stack>
  )
}

import { SelectOption } from '~/types/common'
import { useState } from 'react'
import { GetOrgsParams, Org } from '~/types/organizations'
import { getOrgs } from '~/api/organizations'

export function useGetOrganizations() {
  const [organizations, setOrganizations] = useState<Org[]>([])

  const getOrganizatons = async (params = {}) => {
    const { results } = await getOrgs(params)
    setOrganizations(results)
    return results
  }

  const loadOrganizations = (inputValue: string, callback: (options: SelectOption[]) => void) => {
    const isNumber = !Number.isNaN(+inputValue)
    const params = {} as GetOrgsParams
    if (isNumber) {
      params.iin = +inputValue
    } else {
      params.name = inputValue
    }

    getOrganizatons(params).then((updated) => {
      callback(updated)
    })
  }

  return [organizations, loadOrganizations, getOrganizatons, setOrganizations] as const
}

import {
  GetReportsMechanicParams,
  GetReportsObjectParams,
  GetReportsMechanicResponse,
  GetReportsObjectResponse,
  GetReportsRecordSetParams,
  GetReportsRecordSetResponse,
  GetReportsObjectByParams,
  GetReportsObjectByClientResponse,
  GetReportsObjectByQuantityResponse,
  GetReportsObjectBySupplierResponse,
  GetReportsObjectByGroupAndClientResponse,
  GetReportsObjectByLimitsResponse
} from '~/types/reports'
import { get } from './base'

export const getReportsMechanic = (params: GetReportsMechanicParams) => get<GetReportsMechanicResponse>(`reports/mechanic/`, { params })
export const getReportsMechanicByAuthor = (params: GetReportsMechanicParams) => get<GetReportsMechanicResponse>(`reports/mechanic/by-author`, { params })
export const getReportsMechanicByCategory = (params: GetReportsMechanicParams) => get<GetReportsMechanicResponse>(`reports/mechanic/by-category`, { params })
export const getReportsObject = (params: GetReportsObjectParams) => get<GetReportsObjectResponse>(`reports/object/`, { params })
export const getReportsRecordSet = (params: GetReportsRecordSetParams) => get<GetReportsRecordSetResponse>(`reports/recordset/`, { params })

// no group?
export const getReportsObjectByClient = (params: GetReportsObjectByParams) => get<GetReportsObjectByClientResponse>(`reports/object/by-client/`, { params })
export const getReportsObjectBySupplier = (params: GetReportsObjectByParams) => get<GetReportsObjectBySupplierResponse>(`reports/object/by-supplier/`, { params })

export const getReportsObjectByQuantity = (params: GetReportsObjectByParams) => get<GetReportsObjectByQuantityResponse>(`reports/object/by-quantity/`, { params })
export const getReportsObjectByGroupAndClient = (params: GetReportsObjectByParams) => get<GetReportsObjectByGroupAndClientResponse>(`reports/object/group-and-client/`, { params })
export const getReportsObjectByLimits = (params: GetReportsObjectByParams) => get<GetReportsObjectByLimitsResponse>(`reports/object/by-limit/`, { params })

import { Stack, HStack, FormControl, FormLabel, Radio, RadioGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react'
import { ActionMeta, AsyncSelect, Select } from 'chakra-react-select'
import { SelectOption } from '~/types/common'
import { useEffect, useState } from 'react'
import DatePicker from '~/components/DatePicker'
import { User } from '~/types/users'
import { getUsers as getUsersApi } from '~/api/users'
import { DocsAdditionalFiltersType, FilterValues } from '~/types/docs'
import { useGetOrganizations } from '~/hooks/useGetOrganizations'
import AdditionalFilters from '~/components/AdditionalFilters'

interface Props {
  isFilterOpen: boolean
  filterValues: FilterValues
  setFilterClose: (status: boolean) => void
  setFilterValues: (filters: FilterValues) => void
}

export default function FiltersSidebar({ isFilterOpen, filterValues, setFilterClose, setFilterValues }: Props) {
  const [users, setUsers] = useState<User[]>([])

  const [organizations, loadOrganizations, getOrganizatons] = useGetOrganizations()

  const getUsers = async () => {
    const { results } = await getUsersApi({ groups: '1' })
    setUsers(results)
    return results
  }

  const onAuthorChange = async (selectAction: ActionMeta<User>) => {
    const coauthorAction = selectAction.action

    if (coauthorAction === 'remove-value') {
      const removed = selectAction.removedValue
      const updatedAuthors = filterValues.author!.filter((a) => a.id !== removed.id!)
      setFilterValues({ ...filterValues, author: updatedAuthors })
    }

    if (coauthorAction === 'select-option') {
      const newCoathor = selectAction.option

      if (newCoathor) {
        const updatedAuthors = filterValues.author || []
        updatedAuthors.push(newCoathor)

        setFilterValues({ ...filterValues, author: updatedAuthors })
      }
    }
  }

  // todo: move getOrganizatons to useGetOrg?
  useEffect(() => {
    getOrganizatons()
    getUsers()
  }, [])

  return (
    <Drawer isOpen={isFilterOpen} placement="right" onClose={() => setFilterClose(true)} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Выберите фильтр</DrawerHeader>

        <DrawerBody>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel htmlFor="supplier">Поставщик</FormLabel>
              <AsyncSelect<SelectOption>
                isClearable
                isSearchable
                placeholder="Введите..."
                loadOptions={loadOrganizations}
                defaultOptions={organizations}
                getOptionValue={(org: SelectOption) => `${org?.id}`}
                getOptionLabel={(org: SelectOption) => `${org?.name}`}
                value={filterValues.supplier}
                onChange={(newValue) => setFilterValues({ ...filterValues, supplier: newValue ? { id: newValue!.id!, name: newValue!.name! } : null })}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="recordClient">Получатель (заказчик)</FormLabel>
              <AsyncSelect<SelectOption>
                isClearable
                placeholder="Введите..."
                isSearchable
                getOptionValue={(option: SelectOption) => `${option.id}`}
                getOptionLabel={(option: SelectOption) => `${option.name}`}
                value={filterValues.client}
                onChange={(newValue) => setFilterValues({ ...filterValues, client: newValue ? { id: newValue!.id!, name: newValue!.name! } : null })}
                defaultOptions={organizations}
                loadOptions={loadOrganizations}
              />
            </FormControl>

            <HStack>
              <FormControl>
                <FormLabel htmlFor="date-gte">С даты</FormLabel>
                <DatePicker
                  id="date-gte"
                  selectedDate={filterValues.date_gte ? new Date(filterValues.date_gte) : null}
                  onChange={(newDate) => setFilterValues({ ...filterValues, date_gte: newDate })}
                  showPopperArrow
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="date">По дату</FormLabel>
                <DatePicker
                  id="date-lte"
                  selectedDate={filterValues.date_lte ? new Date(filterValues.date_lte) : null}
                  onChange={(newDate) => setFilterValues({ ...filterValues, date_lte: newDate })}
                  showPopperArrow
                />
              </FormControl>
            </HStack>

            <FormControl>
              <FormLabel htmlFor="author">Автор</FormLabel>
              <Select<User>
                // @ts-expect-error dunno why erroring
                isMulti
                id="author"
                isSearchable
                placeholder="Введите..."
                options={users}
                getOptionValue={(author: User) => `${author.id}`}
                getOptionLabel={(author: User) => `${author.name} ${author.last_name}`}
                value={filterValues.author}
                onChange={(allValue, selectAction) => onAuthorChange(selectAction)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Оригинал</FormLabel>
              <RadioGroup onChange={(value: FilterValues['is_original']) => setFilterValues({ ...filterValues, is_original: value })} value={filterValues.is_original}>
                <Stack direction="row">
                  <Radio value="not_defined">Любое значение</Radio>
                  <Radio value="received">Получен</Radio>
                  <Radio value="not_received">Не получен</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Закрывающий документ</FormLabel>
              <RadioGroup onChange={(value: FilterValues['is_closing']) => setFilterValues({ ...filterValues, is_closing: value })} value={filterValues.is_closing}>
                <Stack direction="row">
                  <Radio value="not_defined">Любое значение</Radio>
                  <Radio value="received">Получен</Radio>
                  <Radio value="not_received">Не получен</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Обработка завершена</FormLabel>
              <RadioGroup onChange={(value: FilterValues['is_processed']) => setFilterValues({ ...filterValues, is_processed: value })} value={filterValues.is_processed}>
                <Stack direction="row">
                  <Radio value="not_defined">Любое значение</Radio>
                  <Radio value="received">Да</Radio>
                  <Radio value="not_received">Нет</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            <AdditionalFilters
              filterValues={filterValues}
              setFilterValues={(additionalFilters: DocsAdditionalFiltersType) => setFilterValues({ ...filterValues, ...additionalFilters })}
            />
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

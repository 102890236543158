import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Switch,
  Textarea
} from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { FiEdit, FiPlay } from 'react-icons/fi'
import { ChangeEvent, useEffect, useState } from 'react'
import { Org } from '~/types/organizations'
import { updateOrg as updateOrgApi } from '~/api/organizations'
import { useSearchByIIN } from '~/hooks/useSearchByIIN'

export default function OrgEdit({ orgData }: { orgData: Org }) {
  const [iinResults, getValues, reset, errors, control, isSubmitting, handleSubmit, disabledInputs, setDisabledInputs, searchByIIN] = useSearchByIIN(orgData)

  const [error, setError] = useState('')

  const onSubmit = async () => {
    try {
      await updateOrgApi(orgData.id, { ...getValues(), iin: +getValues().iin, id: getValues().id! })
    } catch (e: any) {
      const err = e.response.data.iin
      setError(err)
    }
  }

  const isIinNotExist = () => getValues().iin && iinResults.length === 0

  // todo: do i need this?
  useEffect(() => {
    reset(orgData)
    setDisabledInputs({ name: true, address: true })
  }, [orgData])

  return (
    <Box>
      <Stack spacing={3}>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
        <FormControl isInvalid={!!errors?.iin?.message} isRequired>
          <FormLabel htmlFor="iin">ИНН</FormLabel>
          <Controller
            name="iin"
            control={control}
            rules={{
              required: 'Это поле обязательно',
              pattern: {
                value: /^(\d{10}|\d{12})$/,
                message: 'Ошибка в ИНН: введите 10 или 12-значный цифровой номер'
              }
            }}
            render={({ field }) => (
              <InputGroup>
                <InputRightElement zIndex={2}>
                  <IconButton isDisabled={!/^(\d{10}|\d{12})$/.test(`${field.value}`)} colorScheme="blue" aria-label="Поиск по ИНН" icon={<FiPlay />} onClick={searchByIIN} />
                </InputRightElement>
                <Input type="number" placeholder="Введите..." id="iin" {...field} />
              </InputGroup>
            )}
          />
          {isIinNotExist() ? <FormHelperText>Нет организаций с таким ИНН</FormHelperText> : ''}
          <FormErrorMessage>{errors.iin && errors.iin.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.name?.message} isRequired>
          <FormLabel htmlFor="name">Наименование</FormLabel>
          <Controller
            name="name"
            control={control}
            rules={{
              required: 'Это поле обязательно'
            }}
            render={({ field }) => (
              <InputGroup>
                <InputRightElement>
                  <IconButton colorScheme="yellow" aria-label="Изменить наименование" icon={<FiEdit />} onClick={() => setDisabledInputs({ ...disabledInputs, name: false })} />
                </InputRightElement>
                <Input isDisabled={disabledInputs.name} placeholder="Введите..." id="name" {...field} />
              </InputGroup>
            )}
          />
          <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.address?.message} isRequired>
          <FormLabel htmlFor="address">Юр. адрес</FormLabel>
          <Controller
            name="address"
            control={control}
            rules={{
              required: 'Это поле обязательно'
            }}
            render={({ field }) => (
              <InputGroup>
                <InputRightElement>
                  <IconButton colorScheme="yellow" aria-label="Изменить юр. адрес" icon={<FiEdit />} onClick={() => setDisabledInputs({ ...disabledInputs, address: false })} />
                </InputRightElement>
                <Input isDisabled={disabledInputs.address} placeholder="Введите..." id="address" {...field} />
              </InputGroup>
            )}
          />
          <FormErrorMessage>{errors.address && errors.address.message}</FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="own-entity">Собственное юр.лицо</FormLabel>
          <Controller
            name="own_entity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch id="own-entity" isChecked={value} onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)} />
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="comment">Комментарий</FormLabel>
          <Controller name="comment" control={control} render={({ field }) => <Textarea placeholder="Введите..." id="comment" {...field} />} />
        </FormControl>

        <HStack justifyContent="flex-end" pt="5">
          <Button colorScheme="green" onClick={handleSubmit(() => onSubmit())} isLoading={isSubmitting}>
            Сохранить
          </Button>
        </HStack>
      </Stack>
    </Box>
  )
}

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, FormControl, FormLabel, HStack, Input, Stack, Switch } from '@chakra-ui/react'

import DatePicker from '~/components/DatePicker'
import { AdditionalFilters as AdditionalFiltersType } from '~/types/common'
import { ChangeEvent, useEffect, useState } from 'react'
import { ActionMeta, Select } from 'chakra-react-select'
import { User } from '~/types/users'
import { getUsers as getUsersApi } from '~/api/users'

interface Props {
  filterValues: AdditionalFiltersType
  setFilterValues: (filters: AdditionalFiltersType) => void
  isSplitByCategoriesVisible?: boolean
  isAuthorFilterVisible?: boolean
}

const DEBOUNCE_TIMEOUT = 1000

export default function AdditionalFilters({ filterValues, setFilterValues, isSplitByCategoriesVisible = false, isAuthorFilterVisible = false }: Props) {
  const [dateValue, setDateValue] = useState<AdditionalFiltersType['date_diff_min']>(filterValues.date_diff_min)
  const [users, setUsers] = useState<User[]>([])

  const getUsers = async () => {
    const { results } = await getUsersApi({ groups: '1' })
    setUsers(results)
    return results
  }

  useEffect(() => {
    if (isAuthorFilterVisible) {
      getUsers()
    }
  }, [isAuthorFilterVisible])

  useEffect(() => {
    if (filterValues.date_diff_min === dateValue) return
    const timeoutId = setTimeout(() => {
      setFilterValues({ ...filterValues, date_diff_min: dateValue })
    }, DEBOUNCE_TIMEOUT)

    return () => clearTimeout(timeoutId)
  }, [dateValue])

  const onChangeInput = (value: string) => {
    setDateValue(parseInt(value, 10))
  }

  // todo: create UserInput logic (fetch and change in there)
  const onAuthorChange = async (selectAction: ActionMeta<User>) => {
    const authorAction = selectAction.action

    if (authorAction === 'remove-value') {
      const removed = selectAction.removedValue
      const updatedAuthors = filterValues.author!.filter((a) => a.id !== removed.id!)
      setFilterValues({ ...filterValues, author: updatedAuthors })
    }

    if (authorAction === 'select-option') {
      const newAuthor = selectAction.option

      if (newAuthor) {
        const updatedAuthors = filterValues.author || []
        updatedAuthors.push(newAuthor)

        setFilterValues({ ...filterValues, author: updatedAuthors })
      }
    }
  }

  return (
    <>
      <div id="accordion-outside" />
      <Accordion allowToggle overflow="none">
        <AccordionItem>
          <AccordionButton px={0} py={3}>
            <Box as="span" flex="1" textAlign="left" fontWeight={500}>
              Дополнительно
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel px={0} py={3}>
            <Stack gap={2}>
              {isAuthorFilterVisible && (
                <FormControl>
                  <FormLabel htmlFor="author">Автор (соавтор)</FormLabel>
                  <Select<User>
                    // @ts-expect-error dunno why erroring
                    isMulti
                    id="author"
                    isSearchable
                    placeholder="Введите..."
                    options={users}
                    getOptionValue={(author: User) => `${author.id}`}
                    getOptionLabel={(author: User) => `${author.name} ${author.last_name}`}
                    value={filterValues.author}
                    onChange={(allValue, selectAction) => onAuthorChange(selectAction)}
                  />
                </FormControl>
              )}

              <Box>
                <FormLabel htmlFor="created_at_gte">По дате поступления документа в систему</FormLabel>
                <HStack>
                  <FormControl>
                    <DatePicker
                      id="created_at_gte"
                      selectedDate={filterValues.created_at_gte ? new Date(filterValues.created_at_gte) : null}
                      onChange={(newDate) => setFilterValues({ ...filterValues, created_at_gte: newDate })}
                      showPopperArrow
                      popperPlacement="top"
                      portalId="accordion-outside"
                    />
                  </FormControl>
                  <FormControl>
                    <DatePicker
                      id="created_at_lte"
                      selectedDate={filterValues.created_at_lte ? new Date(filterValues.created_at_lte) : null}
                      onChange={(newDate) => setFilterValues({ ...filterValues, created_at_lte: newDate })}
                      showPopperArrow
                      popperPlacement="top"
                      portalId="accordion-outside"
                    />
                  </FormControl>
                </HStack>
              </Box>

              <FormControl>
                <FormLabel htmlFor="recordClient">Дней между датой документа и датой поступления</FormLabel>
                <Flex alignItems="center" gap={2}>
                  <Input width="200px" value={dateValue || ''} onChange={(e) => onChangeInput(e.target.value)} placeholder="Введите..." type="number" />
                  <Box>Дн. и более</Box>
                </Flex>
              </FormControl>

              {isSplitByCategoriesVisible && 'split_by_group_type' in filterValues && (
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="split-by-group-type" mb="0">
                    Разбить группы по категориям
                  </FormLabel>
                  <Switch
                    id="split-by-group-type"
                    isChecked={!!filterValues.split_by_group_type}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setFilterValues({ ...filterValues, split_by_group_type: event.target.checked })}
                  />
                </FormControl>
              )}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}

AdditionalFilters.defaultProps = {
  isSplitByCategoriesVisible: false,
  isAuthorFilterVisible: false
}
